import React, { useState } from 'react';
import { Tab, Tabs } from '@material-ui/core';

// Local imports
import PreviousJobs from 'components/staff/jobs/Previous';
import UpcomingJobs from 'components/staff/jobs/Upcoming/index';
import TabPanel from 'components/common/TabPanel';

const StaffJobs = ({ staff, reload, setReload }) => {
  const [value, setValue] = useState('one');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const a11yProps = (index) => ({
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`
  });
  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped label tabs example">
        <Tab
          value="one"
          label="Upcoming Appointments"
          wrapped
          {...a11yProps('one')}
        />
        <Tab value="two" label="Previous Appointments" {...a11yProps('two')} />
      </Tabs>
      <TabPanel value={value} index="one">
        <UpcomingJobs staff={staff} reload={reload} setReload={setReload} />
      </TabPanel>
      <TabPanel value={value} index="two">
        <PreviousJobs staff={staff} />
      </TabPanel>
    </div>
  );
};

export default StaffJobs;
