import React, { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';

// Local Import
import TabPanel from 'components/common/TabPanel';
import PreviousJobs from './Previous';
import UpcomingJobs from './Upcoming';

const Jobs = ({ user, reload, setReload }) => {
  const [value, setValue] = useState('one');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const a11yProps = (index) => ({
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`
  });
  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="wrapped table example">
        <Tab
          value="one"
          label="Upcoming Appointments"
          wrapped
          {...a11yProps('one')}
        />
        <Tab value="two" label="Previous Appointments" {...a11yProps('two')} />
      </Tabs>
      <TabPanel value={value} index="one">
        <UpcomingJobs user={user} reload={reload} setReload={setReload} />
      </TabPanel>
      <TabPanel value={value} index="two">
        <PreviousJobs user={user} reload={reload} setReload={setReload} />
      </TabPanel>
    </div>
  );
};

export default Jobs;
