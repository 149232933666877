import React, {useState} from 'react';
import {TableCell, TableRow} from '@material-ui/core';


const InvoiceListItem = ({request}) => {
    const [openDetails, setOpenDetails] = useState(false);

    return (
        <TableRow key={request.id}>
            <TableCell component="th" scope="row">
                {request.id}
            </TableCell>
            <TableCell align="left" style={{ whiteSpace: 'pre-wrap'}}>
                {request.name}
            </TableCell>
            <TableCell align="left" style={{ whiteSpace: 'pre-wrap'}}>
                {request.description}
            </TableCell>
            <TableCell align="right">
                {request.quantity}
            </TableCell>
            <TableCell align="right">
                ${parseFloat(request.unitPrice).toFixed(2)}
            </TableCell>
            <TableCell align="right">
                ${parseFloat(request.totalAmount).toFixed(2)}
            </TableCell>
            <TableCell align="left">
                {request.taxCode}
            </TableCell>
        </TableRow>
    );
};
export default InvoiceListItem;
