import React, { Fragment, useEffect, useState } from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Tooltip
} from '@material-ui/core';
import { AddCircle, Close } from '@material-ui/icons';

// Local Imports
import Button from 'components/common/Button';
import JobService from 'services/job.service';
import ImageService from 'services/image.service';
import globalUseStyles from '../../../../utils/theme/styleHooks';
import DeleteImageModal from '../DeleteImageModal';
import AddImageModal from '../AddImageModal';
import Loading from '../../../common/Loading';

const Details = ({ jobRequest, user, open, setOpen, reload, setReload }) => {
  const globalClasses = globalUseStyles();
  const [loading, setLoading] = useState(true);
  // modal control
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  // image obj and reload watcher
  const [images, setImages] = useState();
  const [reloadImages, setReloadImages] = useState(false);
  // hooks to hold and update values needed for job edit
  const [customerNote, setCustomerNote] = useState(
    jobRequest.customerMessage || ''
  );
  const [addressUpdate, setAddressUpdate] = useState(jobRequest.addressId);
  const [imageToDelete, setImageToDelete] = useState(null);

  const api = process.env.REACT_APP_SITE_API;

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (imageData) => {
    setImageToDelete(imageData);
    setOpenDelete(true);
  };

  const handleEdit = () => {
    const jobInfo = {
      id: jobRequest.jobId,
      userId: jobRequest.userId,
      addressId: addressUpdate,
      // currently only resets status if it is not already assigned
      status: jobRequest.status < 2 ? 0 : jobRequest.status,
      serviceType: jobRequest.serviceType,
      customerMessage: customerNote,
      staffMessage: jobRequest.staffMessage,
      staffId: jobRequest.staffId,
      adminMessage: jobRequest.adminMessage
    };
    JobService.updateJob(jobInfo)
      .then(() => {
        setOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const loadImages = () => {
    ImageService.getAllUserImages(jobRequest.jobId)
      .then(({ data }) => {
        setImages([...data]);
        setLoading(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    loadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadImages]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={`${jobRequest.id}-dialog-title`}
      open={open}>
      <DialogTitle id={`${jobRequest.id}-dialog-title`}>
        Job Details
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Fragment key="1">
            <Grid item xs={6}>
              <Typography gutterBottom>Address:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl>
                <Select
                  fullWidth
                  className={globalClasses.addressDropdown}
                  defaultValue={jobRequest.addressId}
                  onChange={(e) => setAddressUpdate(e.target.value)}>
                  {user.addresses.map((address) => (
                    <MenuItem value={address.id} key={address.id}>
                      {address.street}, {address.city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Fragment>
          <Fragment key="3">
            <Grid item xs={12}>
              <Typography gutterBottom>My Notes:</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Edit Note Here"
                rows={4}
                multiline
                variant="outlined"
                value={customerNote}
                defaultValue={jobRequest.customerMessage}
                onChange={(e) => setCustomerNote(e.target.value)}
                fullWidth
              />
            </Grid>
          </Fragment>
          <hr style={{ width: '100%', color: '#eee' }} />
          <Fragment key="4">
            <Grid container justify="space-between">
              <Grid item xs={11}>
                <Typography gutterBottom>My Uploads:</Typography>
              </Grid>
              <Grid item xs={1}>
                <Button
                  color="primary"
                  className={globalClasses.imageAdd}
                  onClick={() => setOpenAdd(true)}>
                  <Tooltip title="Add Image">
                    <AddCircle />
                  </Tooltip>
                </Button>
                <AddImageModal
                  openModal={openAdd}
                  setOpenModal={setOpenAdd}
                  jobId={jobRequest.jobId}
                  reload={reloadImages}
                  setReload={setReloadImages}
                />
              </Grid>
            </Grid>
            {!loading ? (
              <Grid container>
                {images.map((image) => (
                  <Grid item xs={12} md={3}>
                    <a
                      href={`${api}${image.originalPath}`}
                      target="_blank"
                      rel="noreferrer">
                      <img
                        src={`${api}${image.originalPath}`}
                        alt={`${api}${image.originalPath}`}
                        className={globalClasses.imageHeight}
                      />
                    </a>
                    <Button
                      className={`${globalClasses.imageDelete}`}
                      onClick={() => handleDelete(image)}>
                      <Tooltip title="Delete Image">
                        <Close />
                      </Tooltip>
                    </Button>
                    {imageToDelete && (
                      <DeleteImageModal
                        openModal={openDelete}
                        setOpenModal={setOpenDelete}
                        image={imageToDelete}
                        reload={reloadImages}
                        setReload={setReloadImages}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid container justify="center">
                <Loading />
              </Grid>
            )}
          </Fragment>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between">
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button onClick={handleEdit} className={globalClasses.selectButton}>
            Confirm Edit
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default Details;
