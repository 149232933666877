import React from 'react';
import {Grid, Typography, TextField, FormControl, MenuItem, makeStyles} from '@material-ui/core';

// local imports
import UploadComponent from '../../utils/UploadComponent';
import Button from "../common/Button";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(4, 1, 1, 1),
    minWidth: '300px'
  },
  serviceTitle: {
    margin: theme.spacing(4, 1, 0, 1)
  },
  uploadComponent: {
    color: "red",
    fontSize: "15px"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  }
}))

const AdditionalBookingDetails = ({
                                    formField: { customerMessage, serviceType },
                                    formProps,
                                    setFieldValue
                                  }) => {
  const classes = useStyles();

  const appendImages = (newImages) => {
    setFieldValue('images',  [...formProps.values.images, ...newImages])
  }

  const clearImages = () => {
    setFieldValue('images',  [])
  };

  return(
      <div>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Job Information
            </Typography>

            <Grid container justify="center">
              <FormControl className={classes.formControl}>
                <TextField
                    select
                    label=" Please select a type of service:"
                    name={serviceType.name}
                    value={formProps.values.serviceType}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    error={
                        formProps.touched.serviceType &&
                        Boolean(formProps.errors.serviceType)
                    }
                    helperText={
                        formProps.touched.serviceType && formProps.errors.serviceType
                    }>
                  <MenuItem value={0}>Plumbing</MenuItem>
                  <MenuItem value={1}>Gas</MenuItem>
                </TextField>
              </FormControl>
            </Grid>
            <br />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                    name={customerMessage.name}
                    multiline
                    fullWidth
                    variant="outlined"
                    rows={4}
                    onChange={formProps.handleChange}
                    onBlur={formProps.handleBlur}
                    value={formProps.values.customerMessage}
                    error={
                        formProps.touched.customerMessage &&
                        Boolean(formProps.errors.customerMessage)
                    }
                    helperText={
                        formProps.touched.customerMessage &&
                        formProps.errors.customerMessage
                    }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Images
                </Typography>
                <UploadComponent setFieldValue={appendImages} form/>
                {(formProps.touched.images && Boolean(formProps.errors.images)) &&
                    <p className={classes.uploadComponent}>{formProps.errors.images}</p>
                }
                {formProps.values.images &&
                    formProps.values.images.map((file) => (
                        <Grid key={file.name}>
                          <Typography key={file.name}>{file.name}</Typography>
                        </Grid>
                    ))}
                <Button
                    disabled={!formProps.values.images || formProps.values.images.length === 0 }
                    variant="contained"
                    color="accent"
                    className={classes.button}
                    onClick={clearImages}
                >
                  Clear Images
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
  );
}

export default AdditionalBookingDetails;
