import React, { useState } from 'react';
import { Modal, Grid, Card, TextField, DialogActions } from '@material-ui/core';

// Local Imports
import globalUseStyles from 'utils/theme/styleHooks';
import Button from 'components/common/Button';
import Alert from 'components/common/Alert';
import AddressService from '../../../../services/address.service';
import AuthService from '../../../../services/auth.service';

const AddAddress = ({ open, setOpen, reload, setReload }) => {
  const globalClasses = globalUseStyles();
  const [openAlert, setOpenAlert] = useState(false);
  const [address, setAddress] = useState({
    street: '',
    city: '',
    postalCode: '',
    unitNumber: ''
  });

  const handleChange = (event) => {
    setAddress({ ...address, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {
    const createAddress = {
      userId: AuthService.getCurrentId(),
      street: address.street,
      city: address.city,
      unitNumber: address.unitNumber,
      postalCode: address.postalCode,
      province: 'BC',
      country: 'Canada'
    };
    AddressService.createAddress(createAddress)
      .then(() => {
        setOpenAlert(true);
        setAddress({
          street: '',
          city: '',
          postalCode: '',
          unitNumber: ''
        });
        setReload(!reload);
        setOpen(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <>
      <Alert
        description="Address added successfully"
        severity="success"
        type="snackbar"
        snackBar={{
          open: openAlert,
          onClose: () => setOpenAlert(!openAlert)
        }}
      />
      <Modal
        className={globalClasses.modalRoot}
        open={open}
        onClose={() => setOpen(false)}>
        <Card className={globalClasses.modal}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Address"
                name="street"
                onChange={handleChange}
                value={address.street}
                fullWidth
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="City"
                name="city"
                onChange={handleChange}
                value={address.city}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Unit (if applicable)"
                name="unitNumber"
                onChange={handleChange}
                value={address.unitNumber}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Postal Code"
                name="postalCode"
                onChange={handleChange}
                value={address.postalCode}
                fullWidth
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" onClick={handleSubmit}>
              + Add Address
            </Button>
          </DialogActions>
        </Card>
      </Modal>
    </>
  );
};

export default AddAddress;
