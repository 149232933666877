const lineItem = {
    SIXTY_GALL_HWT: {
        name: "60gall Gas Power Vent HWT",
        type: "Non-inventory",
        description: "Tasks\n" +
            ". Pick up new tank/materials.\n" +
            ". Re & Re gas hwt.\n" +
            ". Re plumb hot/cold supplies, plumb in new expansion tank.\n" +
            ". Re Plumb section of exhaust.\n" +
            ". Fill tank and test ok.\n" +
            ". Fire up tank and set temperature to hot A. \n" +
            ". Load truck.\n" +
            ". Dispose of old tank.",
        price: ""
    },
    PLUMBING_FIXTURE: {
        name: "Add Plumbing Fixture",
        type: "Non-inventory",
        description: "Includes Labour & Materials \n" +
            ". DWV/Waterlines Rough In.\n" +
            ". Install Fixtures.\n" +
            ". Test ok.\n" +
            ". Load truck.",
        price: ""
    },
    LAST_INVOICE: {
        name: "Amount owing from last invoice",
        type: "Service",
        description: "",
        price: ""
    },
    CREDIT: {
        name: "Credit",
        type: "Service",
        description: "sent me cheque for work done by another contractor",
        price: ""
    },
    ELECTRIC_HWT: {
        name: "Electric Hwt Re & Re",
        type: "Non-inventory",
        description: "Tasks \n" +
            ". Pick up materials/hot water tank.\n" +
            ". Drain down old tank disconnect and remove.\n" +
            ". Install new tank and plumb up.\n" +
            ". Fill tanks and test ok.\n" +
            ". Turn power back on to tanks.\n" +
            ". Load tools and tanks in truck.\n" +
            ". Dispose of old tanks.",
        price: ""
    },
    EXTRA_MATERIALS: {
        name: "Extra Materials",
        type: "Non-inventory",
        description: "",
        price: ""
    },
    EXTRA_WORK: {
        name: "Extra work",
        type: "Service",
        description: "",
        price: ""
    },
    FIXTURE_INSTALL: {
        name: "Fixture install/hook ups",
        type: "Non-inventory",
        description: "Materials/labour included\n" +
            "\n" +
            "Tasks\n" +
            ". Pick up materials.\n" +
            ". Install fixtures and plumb up.\n" +
            ". Install hwt and plumb.\n" +
            ". Install Prv and valves.\n" +
            ". Test ok.\n" +
            ". Load truck.",
        price: "1670.00"
    },
    FORTY_GALL_HWT: {
        name: "Gas HWT 40gall",
        type: "Service",
        description: "Tasks\n" +
            ". Pick up new tank/materials.\n" +
            ". Drain down old tank and remove.\n" +
            ". Re & Re gas hwt, Plumb up.\n" +
            ". Fill tank and test ok.\n" +
            ". Fire up tank and set temperature to hot A. \n" +
            ". Load truck.\n" +
            ". Dispose of old tank.",
        price: "2515.00"
    },
    FIFTY_GALL_HWT: {
        name: "Gas HWT 50gall fixed cost.",
        type: "Service",
        description: "Tasks\n" +
            ". Pick up new tank/materials.\n" +
            ". Re & Re gas hwt, Plumb up.\n" +
            ". Fill tank and test ok.\n" +
            ". Fire up tank and set temperature to hot A. \n" +
            ". Load truck.\n" +
            ". Dispose of old tank.",
        price: "2815.00"
    },
    GAS_PERMIT: {
        name: "Gas permit",
        type: "Service",
        description: "Rough in, Install, etc.",
        price: ""
    },
    MATERIALS: {
        name: "Materials",
        type: "Service",
        description: "",
        price: ""
    },
    NITRO_PIPE_FREEZE: {
        name: "Nitro Pipe freeze",
        type: "Service",
        description: "Freeze Pipe",
        price: ""
    },
    PERMIT_APPLICATION_FEE: {
        name: "Permit application fee",
        type: "Service",
        description: "Apply for permit & book inspection.",
        price: ""
    },
    PLUMBING_EMERGENCY: {
        name: "Plumbing Emergency Service Call .",
        type: "Non-inventory",
        description: "Call out fee $220 + gst\n" +
            "$35 + gst per 15mins increment",
        price: ""
    },
    PLUMBING_FINISHING: {
        name: "Plumbing Finishing",
        type: "Service",
        description: "Install fixtures",
        price: ""
    },
    PLUMBING_PERMIT: {
        name: "Plumbing Permit",
        type: "Service",
        description: "Rough in.\n" +
            "Fixtures, Valves Etc.\n" +
            "Finally.",
        price: ""
    },
    PLUMBING_ROUGH_IN: {
        name: "Plumbing Rough in",
        type: "Service",
        description: "DWV/Waterlines",
        price: ""
    },
    SALES: {
        name: "Sales",
        type: "Service",
        description: "",
        price: ""
    },
    SERVICE_CALL: {
        name: "Service Call",
        type: "Service",
        description: "Rates Per Technician.\n" +
            "Call out fee $150 + gst \n" +
            "$35 + gst per 15mins increment \n" +
            "Payment on completion of work.",
        price: ""
    },
    SHIPPING: {
        name: "Shipping",
        type: "Non-inventory",
        description: "",
        price: ""
    },
    TERMS_AND_CONDITIONS: {
        name: "Terms & Conditions",
        type: "Service",
        description: "All labour and materials is guaranteed to be as specified. All work to be completed in a " +
            "professional manner according to standard practices. Should Lonsdale Plumbers run into any unforeseen " +
            "circumstances while work takes place there will be an additional charge to this estimate for extra labour " +
            "hours and materials. This agreement contains the entire contract between the parties hereto and no other " +
            "understanding whether virial or otherwise shall be valid unless recorded in writing and signed by both " +
            "parties. please note any additions to this estimate which are not explicitly outlined are extra to the " +
            "listed price; this includes but not limited to: drywall/insulation/concrete/asphalt repairs, valves not " +
            "holding, pin hole leaks on existing system when draining down our re pressurizing system, leaks on any " +
            "existing drainage system if new branches need to be plumbed in due to movement, corrosion etc, Leaks on " +
            "any existing fixtures etc, pressure issues etc,  work outside regular business hours, engineering, " +
            "permits, additional balancing/purging following installation, additional work or changes required as per " +
            "city inspection. Lonsdale Plumbers requires a 50% deposit prior to work commencing. Lonsdale Plumbers Ltd " +
            "then reserves the right to take an additional 40% as the job progresses and the remaining 10% once the " +
            "job is completed. Lonsdale Plumbers is not responsible for any damage or delays due to causes beyond our " +
            "control. the estimate is valid for date stated on estimate and does not include tax unless otherwise " +
            "indicated. By signing this estimate you are approving and accepting all terms and conditions mentioned " +
            "herein.",
        price: ""
    },
    WATERLINES_REPIPE: {
        name: "Waterlines Re-Pipe",
        type: "Service",
        description: "Labour & Materials Included\n" +
            " \n" +
            "Tasks (when unit is empty 1 bathroom unit).\n" +
            ". Pick up materials.\n" +
            ". Demo old poly b lines.\n" +
            ". Rough in new pex lines and size to plumbing code for fixtures.\n" +
            ". Install new Laundry box..\n" +
            ". Install new frost free hose bib.\n" +
            ". Fill and test ok.\n" +
            ". Clean and load truck.",
        price: ""
    }
}

const timeSlots = [
    {
        id: 0,
        label: '9:00am - 11:00am'
    },
    {
        id: 1,
        label: '11:00am - 1:00pm'
    },
    {
        id: 2,
        label: '1:00pm - 3:00pm'
    },
    {
        id: 3,
        label: '3:00pm - 5:00pm'
    }
];

export const getTimeSlot = (timeSlotId) => {
    // eslint-disable-next-line no-restricted-syntax
    for(const timeSlot of timeSlots) {
        if (timeSlot.id === timeSlotId) {
            return timeSlot.label
        }
    }
    return ""
}

export default {
    timeSlots,
    lineItem,
    lineItems: [
        lineItem.SIXTY_GALL_HWT,
        lineItem.PLUMBING_FIXTURE,
        lineItem.LAST_INVOICE,
        lineItem.CREDIT,
        lineItem.ELECTRIC_HWT,
        lineItem.EXTRA_MATERIALS,
        lineItem.EXTRA_WORK,
        lineItem.FIXTURE_INSTALL,
        lineItem.FORTY_GALL_HWT,
        lineItem.FIFTY_GALL_HWT,
        lineItem.GAS_PERMIT,
        lineItem.MATERIALS,
        lineItem.NITRO_PIPE_FREEZE,
        lineItem.PERMIT_APPLICATION_FEE,
        lineItem.PLUMBING_EMERGENCY,
        lineItem.PLUMBING_FINISHING,
        lineItem.PLUMBING_PERMIT,
        lineItem.PLUMBING_ROUGH_IN,
        lineItem.SALES,
        lineItem.SERVICE_CALL,
        lineItem.SHIPPING,
        lineItem.TERMS_AND_CONDITIONS,
        lineItem.WATERLINES_REPIPE
    ]
};
