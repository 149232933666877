import React, { useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';

// local imports
import Labels from '../../../common/Labels';
// eslint-disable-next-line import/no-cycle
import Details from "../../modals/DetailsModal";



const RequestListItem = ({ request, reload, setReload }) => {
  const { serviceLabels } = Labels;
  const [openDetails, setOpenDetails] = useState(false);
  const nextStatus = 2;

  const displayMessage = (message) => {
    if (message.length > 75) {
      return `${message.substring(0, 75)}...`;
    }
    return message;
  };

  return (
      <TableRow key={request.jobId}>
          <TableCell component="th" scope="row">
              #{request.jobId}
          </TableCell>
          <TableCell align="right">
              {serviceLabels[request.serviceType].value}
          </TableCell>
          <TableCell align="right">
              {displayMessage(request.customerMessage)}
          </TableCell>
          <TableCell align="right">
              {request.firstName} {request.lastName}
          </TableCell>
          <TableCell align="right">
              {request.street}, {request.city}
          </TableCell>
          <TableCell align="right">
              <Details
                  jobRequest={request}
                  statusUpdate={nextStatus}
                  open={openDetails}
                  setOpen={setOpenDetails}
                  reload={reload}
                  setReload={setReload}
              />
          </TableCell>
      </TableRow>
  );
};
export default RequestListItem;
