import React from 'react';
import { Card, Grid, Modal, Typography } from '@material-ui/core';

// local imports
import Button from 'components/common/Button';
import globalUseStyles from 'utils/theme/styleHooks';
import ImageService from 'services/image.service';

const DeleteImageModal = ({
  openModal,
  setOpenModal,
  image,
  reload,
  setReload
}) => {
  const globalClasses = globalUseStyles();

  const handleDelete = () => {
    ImageService.deleteUserImage(image.id)
      .then(() => {
        setOpenModal(false);
        setReload(!reload);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <Modal
      className={globalClasses.modalRoot}
      open={openModal}
      onClose={() => setOpenModal(false)}>
      <Card className={globalClasses.modal}>
        <Grid container>
          <Grid container justify="space-around">
            <Typography>
              Please confirm that you would like to delete this image?
            </Typography>
            <Typography>{image.originalPath}</Typography>
          </Grid>
          <Grid container justify="space-evenly">
            <Button onClick={() => setOpenModal(false)}>Cancel</Button>
            <Button onClick={handleDelete}>Delete</Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
};
export default DeleteImageModal;
