import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#da291c',
      light: '#fc4b3c'
    },
    secondary: {
      main: '#ff6247',
      light: '#ff8469'
    },
    tertiary: {
      main: '#f5f5f5',
      text: '#fff',
      mid: '#e5e5e5',
      dark: '#7a7a7a'
    },
    variant: 'outlined'
  },
  typography: {
    fontFamily: 'GOOD TIMES - BOOK',
    fontSize: 15,
    h3: {
      fontSize: '28pt',
      display: 'inline'
    }
  }
});

export default theme;
