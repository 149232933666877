import React, { useState } from 'react';
import {
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import AddressService from 'services/address.service';

// Local Imports
import globalUseStyles from 'utils/theme/styleHooks';
import Alert from 'components/common/Alert';

const ArchivedAddressList = ({ user, reload, setReload }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const [alertDesc, setAlertDesc] = useState('Address restored');

  const globalClasses = globalUseStyles();

  const handleRestoreClick = (row) => {
    AddressService.restoreAddress(row.id)
      .then(() => {
        setAlertDesc('Address restored');
        setOpenAlert(!openAlert);
        setReload(!reload);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setAlertDesc('Something went wrong trying to restore address');
        setOpenAlert(!openAlert);
      });
  };

  return (
    <div>
      <Alert
        description={alertDesc}
        type="snackbar"
        snackBar={{
          open: openAlert,
          onClose: () => setOpenAlert(!openAlert)
        }}
        severity="info"
      />
      <TableContainer component={Paper}>
        <Table aria-label="archive address table">
          <TableHead>
            <TableRow>
              <TableCell>Street</TableCell>
              <TableCell align="right">City</TableCell>
              <TableCell align="right">Postal Code</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user.archivedAddresses.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.unitNumber} {row.street}
                </TableCell>
                <TableCell align="right">{row.city}</TableCell>
                <TableCell align="right">{row.postalCode}</TableCell>
                <TableCell align="right">
                  <Tooltip title="Restore">
                    <Fab
                      color="primary"
                      className={globalClasses.fab}
                      onClick={() => handleRestoreClick(row)}>
                      <UnarchiveIcon />
                    </Fab>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ArchivedAddressList;
