import React, {useState} from 'react';
import {Card, Grid, Modal, TextField, Typography} from '@material-ui/core';

// local imports
import Button from 'components/common/Button';
import globalUseStyles from 'utils/theme/styleHooks';
import UserService from '../../../../services/user.service';
import Alert from "../../../common/Alert";


const SendEmailModal = ({
                            open,
                            setOpen,
                            data,
                            user,
                            reload,
                            setReload,
                            setOpenSuccessSendAlert,
                            responseMessage,
                            setResponseMessage
                        }) => {

    const globalClasses = globalUseStyles();
    const [emailSubject, setEmailSubject] = useState(null);
    const [emailBody, setEmailBody] = useState(null);
    const [openSubjectBodyAlert, setOpenSubjectBodyAlert] = useState(false);
    const [openFailedSendAlert, setOpenFailedSendAlert] = useState(false);


    const handleSend = () => {
        if (!emailSubject||!emailBody) {
            setOpenSubjectBodyAlert(true);
            }
        else {
            UserService.sendEmail(data.id,emailSubject,emailBody)
                .then((res) => {
                    setResponseMessage(res.data)
                    setOpenSuccessSendAlert(true)
                    setEmailBody(null)
                    setEmailSubject(null)
                    setOpen(false);
                })
                .catch((error) => {
                    setResponseMessage('Failed to send email.')
                    setOpenFailedSendAlert(true)
                });
        }

    };

    return (
        <Modal
            className={globalClasses.modalRoot}
            open={open}
            onClose={() => setOpen(!open)}>
            <Card className={globalClasses.modal}>
                <Alert
                    type="snackbar"
                    snackBar={{
                        open: openSubjectBodyAlert,
                        onClose: () => setOpenSubjectBodyAlert(!openSubjectBodyAlert)
                    }}
                    description="Your Email needs a Subject and a Body"
                    title="Error"
                    severity="warning"
                />
                <Alert
                    type="snackbar"
                    snackBar={{
                        open: openFailedSendAlert,
                        onClose: () => setOpenFailedSendAlert(!openFailedSendAlert)
                    }}
                    description={responseMessage}
                    title="Error"
                    severity="error"
                />
                <Grid container>
                    <Grid container justify="space-around">
                        <Typography className={globalClasses.modalHeader}>
                            Composing email to {' '}
                            {user.firstName} {' '} {user.lastName}
                            {' at '}
                            {user.email}
                        </Typography>

                    </Grid>
                    <Grid container justify="space-around">
                        <TextField
                            label="Subject"
                            required
                            multiline
                            // placeholder="This is a note from the Admin to Staff needed to start the service"
                            variant="outlined"
                            className={globalClasses.textField}
                            value={emailSubject}
                            // defaultValue={jobRequest.adminMessage}
                            onChange={(e) => setEmailSubject(e.target.value)}
                        />
                        <TextField
                            label="Email Body"
                            rows={7}
                            required
                            multiline
                            // placeholder="This is a note from the Admin to Staff needed to start the service"
                            variant="outlined"
                            className={globalClasses.textField}
                            value={emailBody}
                            // defaultValue={jobRequest.adminMessage}
                            onChange={(e) => setEmailBody(e.target.value)}
                        />

                    </Grid>
                    <Grid container justify="space-evenly">
                        <Button
                            onClick={() => setOpen(false)}
                            className={globalClasses.clearButton}>
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSend}
                            className={globalClasses.clearButton}>
                            Send
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </Modal>
    );
};
export default SendEmailModal;
