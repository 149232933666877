import React, {useState} from 'react';
import {TableCell, TableRow} from '@material-ui/core';

// local imports
// eslint-disable-next-line import/no-cycle
import Details from '../../modals/DetailsModal';
import Labels from '../../../common/Labels';

const ReviewListItem = ({request, reload, setReload}) => {
    const [openDetails, setOpenDetails] = useState(false);
    const {serviceLabels} = Labels;
    const nextStatus = 2;

    const displayMessage = (message) => {
        if (message.length > 75) {
            return `${message.substring(0, 75)}...`;
        }
        return message;
    };

    return (
        <TableRow key={request.id}>
            <TableCell component="th" scope="row">
                #{request.jobId}
            </TableCell>
            <TableCell align="right">
                {serviceLabels[request.serviceType].value}
            </TableCell>
            <TableCell align="right">
                {displayMessage(request.customerMessage)}
            </TableCell>
            <TableCell align="right">
                {request.firstName} {request.lastName}
            </TableCell>
            <TableCell align="right">
                <Details
                    jobRequest={request}
                    statusUpdate={nextStatus}
                    open={openDetails}
                    setOpen={setOpenDetails}
                    reload={reload}
                    setReload={setReload}
                />
            </TableCell>
        </TableRow>
    );
};
export default ReviewListItem;
