import React, { useState } from 'react';
import {
  Grid,
  Card,
  makeStyles,
  Tooltip,
  Avatar,
  CardHeader,
  CardMedia,
  IconButton,
  CardActions,
  Collapse,
  CardContent,
  Typography
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ArchiveIcon from '@material-ui/icons/Archive';
import Restore from '@material-ui/icons/Restore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import StaffService from 'services/user.service';

// local imports
import globalUseStyles from '../../../utils/theme/styleHooks';
import EditStaff from '../modals/EditStaffModal/index';
import LonsdaleLogo from '../../../assets/Lonsdale-Logo.png';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    margin: theme.spacing(4),
    minHeight: '300px'
  },
  media: {
    height: 0,
    paddingTop: '56.25%'
  }
}));

const StaffListItem = ({ member }) => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const [openEdit, setOpenEdit] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleArchiveClick = (id) => {
    StaffService.archiveStaff(id)
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log(res);
        window.location.reload();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handleRestoreClick = (id) => {
    StaffService.restoreStaff(id)
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log(res);
        window.location.reload();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <Grid item sm={12} md={6} lg={3} key={member.id}>
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              {`${member.firstName.substring(0, 1)} ${member.lastName.substring(
                0,
                1
              )}`}
            </Avatar>
          }
          title={`${member.firstName} ${member.lastName}`}
          subheader={member.staff.title}
        />
        <CardMedia
          className={classes.media}
          image={LonsdaleLogo}
          title="Profile Image"
        />
        <CardActions disableSpacing>
          <Grid container justify="flex-end">
            <Tooltip title="Show Details">
              <IconButton
                onClick={() => setExpanded(!expanded)}
                aria-expanded={expanded}
                aria-label="show more">
                <ExpandMoreIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Email: {`${member.email}`}</Typography>
            <Typography paragraph>Phone: {`${member.phoneNumber}`}</Typography>
            <Typography paragraph>
              Start Date:{' '}
              {`${moment(member.startDate).toString().substring(0, 15)}`}
            </Typography>
            <Grid container justify="space-between">
              <Grid item xs>
                <Tooltip title="Edit">
                  <IconButton
                    color="secondary"
                    className={globalClasses.fab}
                    onClick={() => setOpenEdit(!openEdit)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                {member.staff.disabled === false ? (
                  <Tooltip title="Archive">
                    <IconButton
                      color="primary"
                      className={globalClasses.fab}
                      onClick={() => handleArchiveClick(member.staff.id)}>
                      <ArchiveIcon />
                    </IconButton>
                </Tooltip>
                ) : (
                  <Tooltip title="Restore">
                  <IconButton
                    color="primary"
                    className={globalClasses.fab}
                    onClick={() => handleRestoreClick(member.staff.id)}>
                    <Restore />
                  </IconButton>
                </Tooltip>
                )}
                <EditStaff
                  setOpen={setOpenEdit}
                  open={openEdit}
                  member={member}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  );
};
export default StaffListItem;
