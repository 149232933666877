import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputBase, IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 7px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
    background: theme.palette.tertiary.text,
    margin: '4pt'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

const SearchInput = ({ dataList, filter, setFilter, setFilterResults }) => {
  const classes = useStyles();

  useEffect(() => {
    const results = dataList.filter(
        (data) =>
            (data.firstName ? data.firstName.toLowerCase().includes(filter.toLowerCase()) : false) ||
            (data.lastName ? data.lastName.toLowerCase().includes(filter.toLowerCase()) : false) ||
            (data.email ? data.email.toLowerCase().includes(filter.toLowerCase()) : false) ||
            (data.phoneNumber ? data.phoneNumber.toLowerCase().includes(filter.toLowerCase()) : false)
    );
    setFilterResults(results);
  }, [dataList, filter, setFilterResults]);

  return (
      <div className={classes.root}>
      <InputBase
        className={classes.input}
        type="text"
        placeholder="Search"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        inputProps={{ 'aria-label': 'Search' }}
      />
      <IconButton className={classes.iconButton}>
        <Search />
      </IconButton>
    </div>
  );
};

export default SearchInput;
