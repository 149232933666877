import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';

// local imports
import Alert from 'components/common/Alert';
import Loading from 'components/common/Loading';
import JobService from 'services/job.service';
import DataTable from '../../../common/DataTable';

const headers = [
    'Id',
    'Service',
    'Assigned To',
    'Customer',
    'Actions'
];

const AssignedJobs = () => {
  const [bookings, setBookings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [reload, setReload] = useState(false);

  const loadAssignedJobs = () => {
    JobService.loadAssigned()
      .then(({ data }) => {
        setBookings([...data]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        // if (error.response.status !== 404) {
        //   setOpenAlert(true);
        // }
      });
  };

  useEffect(() => {
    loadAssignedJobs();
  }, [reload]);

  return (
    <Grid container>
      {loading && (
        <Grid container justify="center">
          <Loading />
        </Grid>
      )}
      <Alert
        type="snackbar"
        snackBar={{
          open: openAlert,
          onClose: () => setOpenAlert(!openAlert)
        }}
        description="Something went wrong"
        title="Error"
        severity="error"
      />
      {!loading && (
        <DataTable
          dataList={bookings}
          headers={headers}
          listItem="assigned"
          reload={reload}
          setReload={setReload}
        />
      )}
    </Grid>
  );
};
export default AssignedJobs;
