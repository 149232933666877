import React, { useState } from 'react';
import {
  Fab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import ArchiveIcon from '@material-ui/icons/Archive';
import AddressService from 'services/address.service';

// Local Imports
import Paper from '@material-ui/core/Paper';
import globalUseStyles from 'utils/theme/styleHooks';
import Alert from 'components/common/Alert';
import EditAddressModal from '../modals/EditAddressModal';

const useStyles = makeStyles({
  actions: {
    display: 'flex',
    justifyContent: 'space-around'
  }
});

const CurrentAddresses = ({ user, reload, setReload }) => {
  const [open, setOpen] = useState(false);
  const [rowData, setRowData] = useState();
  const [openAlert, setOpenAlert] = useState(false);
  const [alertDesc, setAlertDesc] = useState('Address archived');

  const globalClasses = globalUseStyles();
  const classes = useStyles();

  const handleEditAddress = (row) => {
    setRowData(row);
    setOpen(!open);
  };

  const handleArchiveClick = (row) => {
    AddressService.archiveAddress(row.id)
      .then(() => {
        setAlertDesc('Address archived');
        setOpenAlert(!openAlert);
        setReload(!reload);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
        setAlertDesc('Something went wrong trying to archive address');
        setOpenAlert(!openAlert);
      });
  };

  return (
    <div>
      <Alert
        description={alertDesc}
        severity="info"
        type="snackbar"
        snackBar={{
          open: openAlert,
          onClose: () => setOpenAlert(!openAlert)
        }}
      />
      <TableContainer component={Paper}>
        <Table aria-label="current address table">
          <TableHead>
            <TableRow>
              <TableCell>Street</TableCell>
              <TableCell align="right">City</TableCell>
              <TableCell align="right">Postal Code</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user.addresses.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.unitNumber} {row.street}
                </TableCell>
                <TableCell align="right">{row.city}</TableCell>
                <TableCell align="right">{row.postalCode}</TableCell>
                <TableCell align="right" className={classes.actions}>
                  <Tooltip title="Edit">
                    <Fab
                      color="primary"
                      className={globalClasses.fab}
                      onClick={() => handleEditAddress(row)}>
                      <EditIcon />
                    </Fab>
                  </Tooltip>
                  <Tooltip title="Archive">
                    <Fab
                      color="primary"
                      className={globalClasses.fab}
                      onClick={() => handleArchiveClick(row)}>
                      <ArchiveIcon />
                    </Fab>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            {open && (
              <EditAddressModal
                setOpen={setOpen}
                open={open}
                address={rowData}
                reload={reload}
                setReload={setReload}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CurrentAddresses;
