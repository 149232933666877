import React, { useState } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core';

// Local Imports
import Button from 'components/common/Button';
import EditDetails from '../modals/EditDetailsModal';
import Labels from '../../common/Labels';

const UpcomingJobs = ({ user, reload, setReload }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [reqData, setReqData] = useState({});
  const { serviceLabels } = Labels;

  const handleEditModal = (req) => {
    setReqData(req);
    setOpenEdit(!openEdit);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="upcoming jobs table">
        <TableHead>
          <TableRow>
            <TableCell>Job #</TableCell>
            <TableCell align="right">Service</TableCell>
            <TableCell align="right">Date Scheduled</TableCell>
            <TableCell align="right">Address</TableCell>
            <TableCell align="right">My Note</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {user.jobs.map((request) =>
            request.status !== 4 ? (
              <>
                <TableRow key={request.id}>
                  <TableCell component="th" scope="row">
                    {request.jobId}
                  </TableCell>
                  <TableCell align="right">
                    {serviceLabels[request.serviceType].value}
                  </TableCell>
                  <TableCell align="right">
                    {request.street}, {request.city}
                  </TableCell>
                  <TableCell align="right">
                    {request.customerMessage || '---'}
                  </TableCell>
                  <TableCell align="right">
                    <Button onClick={() => handleEditModal(request)}>
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              </>
            ) : null
          )}
          {openEdit && (
            <EditDetails
              open={openEdit}
              setOpen={setOpenEdit}
              jobRequest={reqData}
              user={user}
              reload={reload}
              setReload={setReload}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default UpcomingJobs;
