import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Grid, Card, DialogActions, TextField } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

// Local Imports
import { APP_URLS } from 'urls';
import globalUseStyles from 'utils/theme/styleHooks';
import Button from 'components/common/Button';
import Alert from 'components/common/Alert';
import Calendar from '../../../scheduling/Calendar';

const AddStaff = ({ open, setOpen }) => {
  const globalClasses = globalUseStyles();
  const [openSnack, setOpenSnack] = useState(false);
  const [staffMember, setStaffMember] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    title: '',
    startDate: new Date(),
    jobsAssigned: 0,
    activeJobs: 0,
    jobsCompleted: 0
  });

  const handleChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    setStaffMember((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const schema = yup.object({
    firstName: yup
      .string()
      .min(3, 'Can not be less than 3 characters')
      .required('is required'),
    lastName: yup.string().required('is required'),
    email: yup.string().email('Must be valid email').required('is required'),
    password: yup
      .string()
      .min(8, 'Must be at least 8 characters')
      .required('is required'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    phoneNumber: yup.string().required('is required'),
    title: yup
      .string()
      .min(3, 'Can not be less than 3 characters')
      .required('is required'),
    startDate: yup.date().required('is required'),
    jobsAssigned: yup
      .number()
      .typeError('Must be a number')
      .moreThan(-1, 'Must be positive')
      .integer('Must be integer'),
    jobsCompleted: yup
      .number()
      .typeError('Must be a number')
      .moreThan(-1, 'Must be positive')
      .integer('Must be integer'),
    activeJobs: yup
      .number()
      .typeError('Must be a number')
      .moreThan(-1, 'Must be positive')
      .integer('Must be integer')
  });

  const handleSubmitForm = () => {
    const {
      email,
      firstName,
      lastName,
      phoneNumber,
      password,
      title,
      startDate,
      jobsAssigned,
      activeJobs,
      jobsCompleted
    } = staffMember;
    const newStaff = {
      user: {
        email,
        firstName,
        lastName,
        password,
        phoneNumber,
        role: 2
      },
      staff: {
        title,
        startDate,
        jobsAssigned,
        activeJobs,
        jobsCompleted,
        monday9Am: true,
        monday11Am: true,
        monday1Pm: true,
        monday3Pm: true,
        tuesday9Am: true,
        tuesday11Am: true,
        tuesday1Pm: true,
        tuesday3Pm: true,
        wednesday9Am: true,
        wednesday11Am: true,
        wednesday1Pm: true,
        wednesday3Pm: true,
        thursday9Am: true,
        thursday11Am: true,
        thursday1Pm: true,
        thursday3Pm: true,
        friday9Am: true,
        friday11Am: true,
        friday1Pm: true,
        friday3Pm: true,
        disabled: false
      }
    };
    const url = APP_URLS.staff;
    axios
      .post(url, {
        user: {
          ...newStaff.user
        },
        staff: {
          ...newStaff.staff
        }
      })
      .then(() => {
        setOpen(false);
        setOpenSnack(!openSnack);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <>
      <Alert
        description="Successfully added staff"
        severity="success"
        type="snackbar"
        snackBar={{ open: openSnack, onClose: () => setOpenSnack(!openSnack) }}
      />
      <Modal
        className={globalClasses.modalRoot}
        open={open}
        onClose={() => setOpen(false)}>
        <Grid container xs={6}>
          <Card className={globalClasses.modal}>
            <Formik
                enableReinitialize
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  phoneNumber: '',
                  password: '',
                  title: '',
                  startDate: new Date(),
                  jobsAssigned: 0,
                  activeJobs: 0,
                  jobsCompleted: 0
                }}
                validationSchema={schema}
                onSubmit={handleSubmitForm}>
              {({ handleBlur, touched, errors }) => (
                  <Form>
                    <Button onClick={() => setOpen(false)} style={{float: 'right'}}><Close/></Button>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            value={staffMember.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.firstName && Boolean(errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                            label="Last Name"
                            name="lastName"
                            value={staffMember.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.lastName && Boolean(errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                            label="Phone Number"
                            name="phoneNumber"
                            value={staffMember.phoneNumber}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                            helperText={touched.phoneNumber && errors.phoneNumber}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                            label="Email"
                            name="email"
                            value={staffMember.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                            label="Password"
                            name="password"
                            value={staffMember.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.password && Boolean(errors.password)}
                            helperText={touched.password && errors.password}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                            label="Confirm Password"
                            name="passwordConfirmation"
                            value={staffMember.passwordConfirmation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                                touched.passwordConfirmation &&
                                Boolean(errors.passwordConfirmation)
                            }
                            helperText={
                                touched.passwordConfirmation &&
                                errors.passwordConfirmation
                            }
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                            label="Title"
                            name="title"
                            value={staffMember.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.title && Boolean(errors.title)}
                            helperText={touched.title && errors.title}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Calendar
                            name="startDate"
                            label="Start Date"
                            dueDate={staffMember.startDate}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                            label="Jobs Assigned"
                            name="jobsAssigned"
                            value={staffMember.jobsAssigned}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                                touched.jobsAssigned && Boolean(errors.jobsAssigned)
                            }
                            helperText={touched.jobsAssigned && errors.jobsAssigned}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                            label="Active Jobs"
                            name="activeJobs"
                            value={staffMember.activeJobs}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.activeJobs && Boolean(errors.activeJobs)}
                            helperText={touched.activeJobs && errors.activeJobs}
                            fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                            label="Jobs Completed"
                            name="jobsCompleted"
                            value={staffMember.jobsCompleted}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                                touched.jobsCompleted && Boolean(errors.jobsCompleted)
                            }
                            helperText={touched.jobsCompleted && errors.jobsCompleted}
                            fullWidth
                        />
                      </Grid>
                    </Grid>
                    <DialogActions>
                      <Grid container justify="flex-end">
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        <Button onClick={handleSubmitForm}>Add Staff</Button>
                      </Grid>
                    </DialogActions>
                  </Form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Modal>
    </>
  );
};

export default AddStaff;
