import React from 'react';
import { Grid } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useField, useFormikContext } from 'formik';

const Calendar = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const variant = field.name === 'dueDate' ? 'static' : 'inline';

  return (
    <Grid container>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container justify="center">
          <KeyboardDatePicker
            {...field}
            {...props}
            variant={variant}
            format="MMMM dd, yyyy"
            orientation="landscape"
            showTodayButton
            onChange={(val) => {
              setFieldValue(field.name, val);
            }}
            selected={(field.value && new Date(field.value)) || null}
            fullWidth
          />
        </Grid>
      </MuiPickersUtilsProvider>
    </Grid>
  );
};
export default Calendar;
