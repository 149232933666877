import React, {useState} from 'react';
import {
  Button,
  Grid,
  makeStyles, MenuItem, Select, TextField
} from '@material-ui/core';

// local imports
import globalUseStyles from 'utils/theme/styleHooks';
import SendEmailModal from "../SendEmailModal/SendEmailModal";
import Alert from "../../../common/Alert";
// eslint-disable-next-line import/no-cycle
import DataTable from "../../../common/DataTable";
import EditableDataTable from "../../../common/EditableDataTable";
import enums from "../../../../utils/enums";
import JobService from "../../../../services/job.service";
import quickbooksService from "../../../../services/quickbooks.service";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: theme.spacing(4)
  },
  addFAB: {
    margin: theme.spacing(-2, -2, 0, 0),
    height: 25,
    width: 35
  },
  card: {
    width: '60%',
    padding: theme.spacing(4)
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  image: {
    margin: 'auto'
  },
  images: {
    height: '100%',
    width: '150px',
    margin: theme.spacing(2),
    padding: theme.spacing(4)
  },
  info: {
    fontSize: '12pt',
    textAlign: 'center'
  },
  tableRow: {
    align: 'right'
  },
  textField: {
    width: '100%',
    margin: theme.spacing(1, 0, 0, 0)
  },
  text: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  middleGrid: {
    marginBottom:'2%'
  },
  bottomGrid: {
    marginBottom:'2%'
  },
  marginRight: {
    marginRight: "15px"
  },
  paddingRight: {
    paddingRight: "30px"
  },
  marginBottom: {
    marginBottom: '80px'
  },
  marginTop: {
    marginTop: '20px'
  },
  emailButton: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.tertiary.text,
    borderColor: theme.palette.tertiary.text,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.tertiary.text,
      borderColor: theme.palette.tertiary.text,
      borderWidth: '1px'
    }
  },
  viewEmailButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.tertiary.text,
    borderColor: theme.palette.tertiary.text,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.tertiary.text,
      borderColor: theme.palette.tertiary.text,
      borderWidth: '1px'
    }
  }
}));

const headers = [
  '#',
  'PRODUCT/SERVICE',
  'DESCRIPTION',
  'QTY',
  'RATE (CAD)',
  'AMOUNT (CAD)',
  'SALES TAX'
];

const termsOptions = [
  { id: 1, name: 'Due Date'},
  { id: 2, name: 'Due on Receipt'},
  { id: 3, name: 'Net'},
  { id: 4, name: 'Net 15'},
  { id: 5, name: 'Net 30'},
  { id: 6, name: 'Net 60'}
]

SendEmailModal.openSuccessSendAlert = false;
const InvoiceDetails = ({
  invoice,
  setInvoice,
  setOpen,
  reload,
  setReload,
  job,
  user
}) => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const [responseMessage, setResponseMessage] = useState(null);
  const [openInvoiceModal, setInvoiceModal] = useState(false);
  const [openSuccessSendAlert, setOpenSuccessSendAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(invoice.emailSent);
  const [sendButtonText, setSendButtonText] = useState(invoice.emailSent ? 'Invoice Sent' : 'Send Invoice Email')
  const [sendButtonClass, setSendButtonClass] = useState(sendDisabled ? null : classes.emailButton);
  const [editable, setEditable] = useState(false);
  const [jobInfo] = useState({
    id: job.jobId,
    userId: job.userId,
    addressId: job.addressId,
    status: job.status,
    serviceType: job.serviceType,
    customerMessage: job.customerMessage,
    staffMessage: job.staffMessage,
    staffId: job.staffId > 0 ? job.staffId : null,
    adminMessage: job.adminMessage,
    paymentAmount: job.paymentAmount
  });
  const [userInfo] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email
  });

  const handleChange = (event) => {
    let newDueDate = invoice.dueDate;
    if (event.target.name === "terms") {
      newDueDate = new Date(Date.parse(invoice.invoiceDate));
      if (event.target.value === "Due Date") {
        newDueDate.setDate(newDueDate.getUTCDate()+7).toString();
        newDueDate = `${newDueDate.getFullYear()}-${newDueDate.getMonth()+1}-${newDueDate.getDate()}`
      }
      else if (event.target.value === "Due on Receipt") {
        newDueDate = invoice.invoiceDate;
      }
      else if (event.target.value === "Net") {
        newDueDate.setDate(newDueDate.getUTCDate()+7).toString();
        newDueDate = `${newDueDate.getFullYear()}-${newDueDate.getMonth()+1}-${newDueDate.getDate()}`
      }
      else if (event.target.value === "Net 15") {
        newDueDate.setDate(newDueDate.getUTCDate()+15).toString();
        newDueDate = `${newDueDate.getFullYear()}-${newDueDate.getMonth()+1}-${newDueDate.getDate()}`
      }
      else if (event.target.value === "Net 30") {
        newDueDate.setDate(newDueDate.getUTCDate()+30).toString();
        newDueDate = `${newDueDate.getFullYear()}-${newDueDate.getMonth()+1}-${newDueDate.getDate()}`
      }
      else if (event.target.value === "Net 60") {
        newDueDate.setDate(newDueDate.getUTCDate()+60).toString();
        newDueDate = `${newDueDate.getFullYear()}-${newDueDate.getMonth()+1}-${newDueDate.getDate()}`
      }
      else {
        newDueDate = invoice.dueDate;
      }
    }
    else if (event.target.name === "invoiceDate") {
      newDueDate = new Date(Date.parse(event.target.value));
      if (invoice.terms === "Due Date") {
        newDueDate.setDate(newDueDate.getUTCDate()+7).toString();
        newDueDate = `${newDueDate.getFullYear()}-${newDueDate.getMonth()+1}-${newDueDate.getDate()}`
      }
      else if (invoice.terms === "Due on Receipt") {
        newDueDate = event.target.value;
      }
      else if (invoice.terms === "Net") {
        newDueDate.setDate(newDueDate.getUTCDate()+7).toString();
        newDueDate = `${newDueDate.getFullYear()}-${newDueDate.getMonth()+1}-${newDueDate.getDate()}`
      }
      else if (invoice.terms === "Net 15") {
        newDueDate.setDate(newDueDate.getUTCDate()+15).toString();
        newDueDate = `${newDueDate.getFullYear()}-${newDueDate.getMonth()+1}-${newDueDate.getDate()}`
      }
      else if (invoice.terms === "Net 30") {
        newDueDate.setDate(newDueDate.getUTCDate()+30).toString();
        newDueDate = `${newDueDate.getFullYear()}-${newDueDate.getMonth()+1}-${newDueDate.getDate()}`
      }
      else if (invoice.terms === "Net 60") {
        newDueDate.setDate(newDueDate.getUTCDate()+60).toString();
        newDueDate = `${newDueDate.getFullYear()}-${newDueDate.getMonth()+1}-${newDueDate.getDate()}`
      }
      else {
        newDueDate = invoice.dueDate;
      }
    }
    setInvoice((prevState) => (
        {
          ...prevState,
          [event.target.name]: event.target.value,
          dueDate: newDueDate
        }
    ))
  }

  const handleChangeLineItem = (inputli) => {
    let newAmount = 0;
    let taxPercent = 0;
    let totalTax = 0;
    const inputLineItem = inputli;
    inputLineItem.totalAmount = inputLineItem.unitPrice*inputLineItem.quantity;
    for (const line of invoice.lines.filter(li => li.id !== inputLineItem.id)) {
      if (line.taxCode === 'GST') {
        taxPercent = 0.05;
      }
      else if (line.taxCode === 'PST BC') {
        taxPercent = 0.07;
      }
      else if (line.taxCode === 'GST/PST BC' || line.taxCode === 'HST BC') {
        taxPercent = 0.12;
      }
      if (!Number.isNaN(line.totalAmount)) {
        newAmount += parseFloat(line.totalAmount);
        totalTax += parseFloat(line.totalAmount)*taxPercent;
      }
    }
    if (inputLineItem.taxCode === 'GST') {
      taxPercent = 0.05;
    }
    else if (inputLineItem.taxCode === 'PST BC') {
      taxPercent = 0.07;
    }
    else if (inputLineItem.taxCode === 'GST/PST BC' || inputLineItem.taxCode === 'HST BC') {
      taxPercent = 0.12;
    }
    if (!Number.isNaN(inputLineItem.totalAmount)) {
      newAmount += parseFloat(inputLineItem.totalAmount);
      totalTax += parseFloat(inputLineItem.totalAmount)*taxPercent;
    }
    newAmount = newAmount.toFixed(2);
    totalTax = totalTax.toFixed(2);
    setInvoice((prevState) => ({
      ...prevState,
      lines: prevState.lines.map((element) => {
        if (element.id === inputLineItem.id) return inputLineItem;
        return element;
      }),
      subtotal: newAmount,
      totalTax,
      totalAmount: (parseFloat(newAmount) + parseFloat(totalTax)).toFixed(2),
      balanceDue: (parseFloat(newAmount) + parseFloat(totalTax)).toFixed(2)
    }))
  };

  const addLineItem = () => {
    const { name, description } = enums.lineItem.SERVICE_CALL;
    setInvoice((prevState) => ({
      ...prevState,
      lines: prevState.lines.concat({
        id: prevState.lines.length + 1,
        name,
        description,
        quantity: 1,
        unitPrice: 0.00,
        totalAmount: 0.00,
        taxCode: 'GST'
      })
    }))
  };

  const removeLineItem = async (id) => {
    await setInvoice((prevState) => (
      {
        ...prevState,
        lines: prevState.lines.filter((lineItem) => lineItem.id !== id)
      }
    ));
  };

  const fixLineIds = async (id) => {
    for (const line of invoice.lines) {
      if (line.id > id) {
        setInvoice((prevState) => (
            {
              ...prevState,
              lines:
                  prevState.lines.map(
                      element => element.id === line.id ? {...element, id: line.id-1} : element
                  )
            }
        ))
      }
    }
  };

  const handleRemoveLine = async (id) => {
    await removeLineItem(id);
    await fixLineIds(id);
  };

  const handleUpdate = () => {
    const updateInfo = {
      ...invoice
    };
    quickbooksService.updateInvoice(updateInfo)
        .then(() => {
          setEditable(!editable);
          setResponseMessage('Invoice updated.');
          setOpenSuccessSendAlert(true);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('Error: ', error);
          setResponseMessage('Failed to update invoice.')
          setOpenErrorAlert(true);
        });
  };

  const sendInvoiceEmail = () => {
    quickbooksService.sendInvoiceEmail({invoiceNumber: invoice.id, jobId: invoice.jobId})
        .then((data) => {
          if (data === "Quickbooks connection expired. Please reconnect to quickbooks."
              || data === "Failed to send invoice email"
              || data === "QBO API call Failed!") {
            setResponseMessage(data);
            setOpenErrorAlert(true);
          }
          else {
            setResponseMessage('Invoice email sent.');
            setOpenSuccessSendAlert(true);
            setSendDisabled(true);
            setSendButtonClass(null);
            setSendButtonText('Invoice Sent');
          }
        })
        .catch((error) => {
          setResponseMessage('Failed to send invoice email.');
          setOpenErrorAlert(true);
        });
  };

  return (
      <div>
        <Alert
            type="snackbar"
            snackBar={{
              open: openSuccessSendAlert,
              onClose: () => setOpenSuccessSendAlert(!openSuccessSendAlert)
            }}
            description={responseMessage}
            title="Success!"
            severity="success"
        />
        <Alert
            type="snackbar"
            snackBar={{
              open: openErrorAlert,
              onClose: () => setOpenErrorAlert(!openErrorAlert)
            }}
            description={responseMessage}
            title="Error"
            severity="error"
        />
        {!editable && (
        <Grid container>
          <Grid item xs={3} className={classes.middleGrid}>
            <div className={globalClasses.detailsTitle}>Customer:</div>
            <div className={globalClasses.detailsInfo}>
              {invoice.customerName}
            </div>
          </Grid>
          <Grid item xs={3} className={classes.middleGrid}>
            <div className={globalClasses.detailsTitle}>Email:</div>
            <div className={globalClasses.detailsInfo}>
              {invoice.customerEmail}
            </div>
          </Grid>
          <Grid  item xs={5} className={classes.middleGrid}>
            <div className={globalClasses.detailsTitle} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>Balance due:</div>
            <div className={globalClasses.detailsInfo} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingRight: "2.2rem" }}>
              ${invoice.balanceDue}
            </div>
          </Grid>
          <Grid container xs={12} className={classes.middleGrid} style={{ paddingTop: "5rem" }}>
            <Grid item xs={4} className={classes.middleGrid}>
              <div className={globalClasses.detailsTitle}>Billing address:</div>
              <div className={globalClasses.detailsInfo} style={{ whiteSpace: 'pre-wrap' }}>
                {invoice.customerBillingAddress}
              </div>
            </Grid>
            <Grid  item xs={2} className={classes.middleGrid}>
              <div className={globalClasses.detailsTitle}>Terms:</div>
              <div className={globalClasses.detailsInfo}>
                {invoice.terms}
              </div>
            </Grid>
            <Grid  item xs={3} className={classes.middleGrid} style={{ paddingLeft: "3.5rem" }}>
              <div className={globalClasses.detailsTitle}>Invoice date:</div>
              <div className={globalClasses.detailsInfo}>
                {invoice.invoiceDate}
              </div>
            </Grid>
            <Grid  item xs={3} className={classes.middleGrid} style={{ paddingLeft: "3.5rem" }}>
              <div className={globalClasses.detailsTitle}>Due date:</div>
              <div className={globalClasses.detailsInfo}>
                {invoice.dueDate}
              </div>
            </Grid>
          </Grid>
          <Grid container xs={12} className={classes.middleGrid} style={{ paddingTop: "2rem" }}>
            <DataTable
                dataList={invoice.lines}
                headers={headers}
                listItem="invoice"
                reload={reload}
                setReload={setReload}
            />
          </Grid>

          <Grid container xs={12} className={classes.middleGrid} style={{ paddingTop: "2rem" }}>
            <Grid  item xs={5} className={classes.middleGrid}>
              <div className={globalClasses.detailsTitle}>Message on invoice:</div>
              <div className={globalClasses.detailsInfo} style={{ whiteSpace: 'pre-wrap' }}>
                {invoice.invoiceMessage}
              </div>
            </Grid>
            <Grid container xs={7} className={classes.middleGrid}>
              <Grid  item xs={10} className={classes.middleGrid}>
                <div className={globalClasses.detailsTitle} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>Subtotal:</div>
                <div className={globalClasses.detailsInfo} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  ${invoice.subtotal}
                </div>
              </Grid>
              <Grid  item xs={10} className={classes.middleGrid}>
                <div className={globalClasses.detailsTitle} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>Total tax:</div>
                <div className={globalClasses.detailsInfo} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  ${invoice.totalTax}
                </div>
              </Grid>
              <Grid  item xs={10} className={classes.middleGrid}>
                <div className={globalClasses.detailsTitle} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>Total:</div>
                <div className={globalClasses.detailsInfo} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  ${invoice.totalAmount}
                </div>
              </Grid>
              <Grid  item xs={10} className={classes.middleGrid}>
                <div className={globalClasses.detailsTitle} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>Balance due:</div>
                <div className={globalClasses.detailsInfo} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                  ${invoice.balanceDue}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
              style={{
                position: 'sticky',
                bottom: '-2.5rem',
                backgroundColor: 'white',
                width: '58.1vw',
                padding: '1rem 0.5rem 0 0.5rem',
                borderTop: '1px solid lightGray'
              }}
          >
            <Button
                onClick={() => setOpen(false)}
            >
              Close
            </Button>
            <Button
                onClick={() => sendInvoiceEmail()}
                className={sendButtonClass}
                style={{ float: 'right', border: '1px solid lightGray', marginLeft: '1rem'  }}
                disabled={sendDisabled}
            >
              {sendButtonText}
            </Button>
            <Button
                onClick={() => setEditable(!editable)}
                className={classes.emailButton}
                style={{ float: 'right' }}
            >
              Edit Invoice
            </Button>
          </Grid>
        </Grid>
        )}
        {editable && (
            <Grid container>
              <Grid item xs={3} className={classes.middleGrid}>
                <div className={globalClasses.detailsTitle}>Customer:</div>
                <TextField
                    className={globalClasses.detailsInfo}
                    value={invoice.customerName}
                    name='customerName'
                    onChange={(event) => handleChange(event)}
                />
              </Grid>
              <Grid item xs={3} className={classes.middleGrid}>
                <div className={globalClasses.detailsTitle}>Email:</div>
                <TextField
                    className={globalClasses.detailsInfo}
                    value={invoice.customerEmail}
                    name='customerEmail'
                    onChange={(event) => handleChange(event)}
                />
              </Grid>
              <Grid  item xs={5} className={classes.middleGrid}>
                <div className={globalClasses.detailsTitle} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>Balance due:</div>
                <TextField
                    className={globalClasses.detailsInfo}
                    style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "7rem", marginLeft: 'auto', marginRight: '-0.5rem' }}
                    value={invoice.balanceDue}
                    name='balanceDue'
                    onChange={(event) => handleChange(event)}
                    InputProps={{readOnly: true}}
                    disabled
                />
              </Grid>
              <Grid container xs={12} className={classes.middleGrid} style={{ paddingTop: "5rem" }}>
                <Grid item xs={4} className={classes.middleGrid}>
                  <div className={globalClasses.detailsTitle}>Billing address:</div>
                  <TextField
                      className={globalClasses.detailsInfo}
                      style={{ whiteSpace: 'pre-wrap', width: '17rem' }}
                      value={invoice.customerBillingAddress}
                      multiline
                      name='customerBillingAddress'
                      onChange={(event) => handleChange(event)}
                  />
                </Grid>
                <Grid  item xs={2} className={classes.middleGrid}>
                  <div className={globalClasses.detailsTitle}>Terms:</div>
                  <Select
                      className={globalClasses.detailsInfo}
                      defaultValue={invoice.terms}
                      name='terms'
                      onChange={(event) => handleChange(event)}
                  >
                    {termsOptions.map((terms) => (
                        <MenuItem value={terms.name} key={terms.id}>
                          {terms.name}
                        </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid  item xs={3} className={classes.middleGrid} style={{ paddingLeft: "3.5rem" }}>
                  <div className={globalClasses.detailsTitle}>Invoice date:</div>
                  <TextField
                      className={globalClasses.detailsInfo}
                      value={invoice.invoiceDate}
                      name='invoiceDate'
                      onChange={(event) => handleChange(event)}
                  />
                </Grid>
                <Grid  item xs={3} className={classes.middleGrid} style={{ paddingLeft: "3.5rem" }}>
                  <div className={globalClasses.detailsTitle}>Due date:</div>
                  <TextField
                      className={globalClasses.detailsInfo}
                      value={invoice.dueDate}
                      name='dueDate'
                      onChange={(event) => handleChange(event)}
                      InputProps={{readOnly: true}}
                      disabled
                  />
                </Grid>
              </Grid>
              <Button className={globalClasses.clearButton} onClick={addLineItem}>
                Add Line
              </Button>
              <Grid container xs={12} className={classes.middleGrid}>
                <EditableDataTable
                    dataList={invoice.lines}
                    setDataList={(inputLineItem => handleChangeLineItem(inputLineItem))}
                    removeLine={(id => handleRemoveLine(id))}
                    headers={headers}
                    listItem="invoice"
                    reload={reload}
                    setReload={setReload}
                />
              </Grid>

              <Grid container xs={12} className={classes.middleGrid} style={{ paddingTop: "2rem" }}>
                <Grid  item xs={5} className={classes.middleGrid}>
                  <div className={globalClasses.detailsTitle}>Message on invoice:</div>
                  <TextField
                      className={globalClasses.detailsInfo}
                      style={{ whiteSpace: 'pre-wrap' }}
                      value={invoice.invoiceMessage}
                      multiline
                      fullWidth
                      name='invoiceMessage'
                      onChange={(event) => handleChange(event)}
                  />
                </Grid>
                <Grid container xs={7} className={classes.middleGrid}>
                  <Grid  item xs={10} className={classes.middleGrid}>
                    <div className={globalClasses.detailsTitle} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>Subtotal:</div>
                    <TextField
                        className={globalClasses.detailsInfo}
                        style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "8rem", marginLeft: 'auto' }}
                        value={invoice.subtotal}
                        name='subtotal'
                        onChange={(event) => handleChange(event)}
                        InputProps={{readOnly: true}}
                        disabled
                    />
                  </Grid>
                  <Grid  item xs={10} className={classes.middleGrid}>
                    <div className={globalClasses.detailsTitle} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>Total tax:</div>
                    <TextField
                        className={globalClasses.detailsInfo}
                        style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "8rem", marginLeft: 'auto' }}
                        value={invoice.totalTax}
                        name='totalTax'
                        onChange={(event) => handleChange(event)}
                        InputProps={{readOnly: true}}
                        disabled
                    />
                  </Grid>
                  <Grid  item xs={10} className={classes.middleGrid}>
                    <div className={globalClasses.detailsTitle} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>Total:</div>
                    <TextField
                        className={globalClasses.detailsInfo}
                        style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "8rem", marginLeft: 'auto' }}
                        value={invoice.totalAmount}
                        name='totalAmount'
                        onChange={(event) => handleChange(event)}
                        InputProps={{readOnly: true}}
                        disabled
                    />
                  </Grid>
                  <Grid  item xs={10} className={classes.middleGrid}>
                    <div className={globalClasses.detailsTitle} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>Balance due:</div>
                    <TextField
                        className={globalClasses.detailsInfo}
                        style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "8rem", marginLeft: 'auto' }}
                        value={invoice.balanceDue}
                        name='balanceDue'
                        onChange={(event) => handleChange(event)}
                        InputProps={{readOnly: true}}
                        disabled
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                  style={{
                    position: 'sticky',
                    bottom: '-2.5rem',
                    backgroundColor: 'white',
                    width: '58.1vw',
                    padding: '1rem 0.5rem 0 0.5rem',
                    borderTop: '1px solid lightGray'
                  }}
              >
                <Button
                    onClick={() => setOpen(false)}
                >
                  Close
                </Button>
                <Button
                    onClick={() => sendInvoiceEmail()}
                    style={{ float: 'right', border: '1px solid lightGray', marginLeft: '1rem' }}
                    disabled
                >
                  {sendButtonText}
                </Button>
                <Button
                    onClick={() => handleUpdate()}
                    className={classes.emailButton}
                    style={{ float: 'right' }}
                >
                  Save Invoice
                </Button>
              </Grid>
            </Grid>
        )}
      </div>
  );
};
export default InvoiceDetails;
