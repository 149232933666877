import React, {useEffect, useRef} from 'react';
import {
    Grid,
    makeStyles,
    TextField,
    Select,
    MenuItem,
    FormControl
} from '@material-ui/core';
import enums from "../../../utils/enums";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'start',
        width: '70vw',
        [theme.breakpoints.down('sm')]: {
            padding: '20px'
        }
    },
    button: {
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    subtitle: {
        fontSize: '14pt',
        marginTop: '30px',
        marginBottom: '20px'
    },
    card: {
        padding: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(6)
        }
    },
    input: {
        marginRight: '1rem'}
}));

const LineItem = ({value, onChange}) => {
    const classes = useStyles();
    const lineItem = value;
    const descriptionRef = useRef(null);

    useEffect(() => {
        if(!descriptionRef) return
        const ref = descriptionRef.current
        ref.style.height = "0px";
        const {scrollHeight} = ref;

        ref.style.height = `${scrollHeight}px`;
    })

    const handleChangeName = (event) => {
        onChange({
            ...lineItem,
            name: event.target.value.name,
            type: event.target.value.type,
            description: event.target.value.description,
            price: parseFloat(event.target.value.price),
            displayPrice: parseFloat(event.target.value.displayPrice)
        });
    };

    const handleChangeType = (event) => {
        onChange({...lineItem, type: event.target.value});
    };

    const handleChangeDescription = (event) => {
        onChange({...lineItem, description: event.target.value});
    };

    const numbersOnly = (event) => {
        const charCode = event.which ? event.which : event.keyCode;

        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && (charCode !== 46 || event.target.value.indexOf(".") !== -1)) {
            event.preventDefault();
            return false;
        } 
        return true;
    };

    const handleChangePrice = (event) => {
        const isNumber = numbersOnly(event)
        if (isNumber) {
            onChange({...lineItem, price: parseFloat(event.target.value)});
        }
    };

    const handleChangeDisplayPrice = (event) => {
        const isNumber = numbersOnly(event)
        if (isNumber) {
            onChange({...lineItem, displayPrice: parseFloat(event.target.value)});
        }
    };


    return (
        <>
            <FormControl>
                    <Select
                        style={{ width: "15rem" }}
                        label="Name"
                        name="name"
                        onChange={handleChangeName}
                        className={classes.input}
                        value={lineItem}
                        renderValue={(displayLineItem) => displayLineItem.name}
                        type="text"
                    >
                        {enums.lineItems.map((item) => (
                            <MenuItem value={item} >{item.name}</MenuItem>
                        ))}
                    </Select>
            </FormControl>
            <FormControl>
                    <Select
                        style={{ width: "9rem" }}
                        label="Type"
                        name="type"
                        onChange={handleChangeType}
                        className={classes.input}
                        value={lineItem ? lineItem.type : ""}
                        type="text"
                    >
                        <MenuItem value="Non-inventory">Non-Inventory</MenuItem>
                        <MenuItem value="Service">Service</MenuItem>
                    </Select>
            </FormControl>
            <FormControl>
                <TextField
                    style={{ width: "6rem", marginTop: "-1rem" }}
                    label="Price"
                    name="price"
                    onChange={handleChangeDisplayPrice}
                    className={classes.input}
                    value={lineItem ? lineItem.displayPrice : 0}
                    type="number"
                />
            </FormControl>
            <FormControl>
                    <textarea
                        style={{ width: "30rem" }}
                        ref={descriptionRef}
                        placeholder="Description"
                        name="description"
                        onChange={handleChangeDescription}
                        className={classes.input}
                        value={lineItem ? lineItem.description : ""}
                        wrap="hard"
                    />
            </FormControl>
        </>
    );
};
export default LineItem;
