import axios from 'axios';
import authHeader from './auth-header';
import { APP_URLS } from '../urls';

const createAddress = (address) =>
  axios.post(APP_URLS.address, address, { headers: authHeader() });

const updateAddress = (address) =>
  axios.put(`${APP_URLS.address}/update`, address, { headers: authHeader() });

const archiveAddress = (id) =>
  axios.put(`${APP_URLS.address}/archive/${id}`, { headers: authHeader() });

const restoreAddress = (id) =>
  axios.put(`${APP_URLS.address}/restore/${id}`, { headers: authHeader() });

export default {
  createAddress,
  updateAddress,
  archiveAddress,
  restoreAddress
};
