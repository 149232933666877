import React from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';

import { usePagination, DOTS } from './usePagination';
import globalUseStyles from '../theme/styleHooks';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}));

const Pagination = (props) => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className={classes.root}>
      <Grid container justify="center">
        <Button
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
          className={globalClasses.clearButton}>
          <ArrowLeft />
        </Button>
        {paginationRange.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <Grid item>
                <div className={classes.listItem}>&#8230;</div>
              </Grid>
            );
          }
          return (
            <Grid item>
              <Button
                onClick={() => onPageChange(pageNumber)}
                className={
                  currentPage === pageNumber
                    ? globalClasses.selectButton
                    : globalClasses.clearButton
                }>
                {pageNumber}
              </Button>
            </Grid>
          );
        })}
        <Button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === lastPage}
          className={globalClasses.clearButton}>
          <ArrowRight />
        </Button>
      </Grid>
    </div>
  );
};

export default Pagination;
