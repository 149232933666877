import React from 'react';
import '../css/App.css';
import DateFnsUtils from '@date-io/date-fns';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Local Imports
import theme from 'utils/theme/Theme';
import Main from './Main';

const App = () => (
  <Router>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiThemeProvider theme={theme}>
        <Main />
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  </Router>
);

export default App;
