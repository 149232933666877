import React, { Fragment, useState } from 'react';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Grid,
  makeStyles,
  Typography,
  FormLabel
} from '@material-ui/core';
import cx from 'classnames';

// Local Imports
import UserService from 'services/user.service';
import { Edit, ExpandMore } from '@material-ui/icons';
import Alert from 'components/common/Alert';
import EditUser from './modals/EditUser';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  underCard: {
    margin: theme.spacing(4)
  },
  overCard: {
    padding: theme.spacing(2)
  },
  label: {
    marginTop: theme.spacing(1)
  },
  saveButton: {
    marginTop: theme.spacing(3)
  },
  availability: {
    width: '450px',
    margin: theme.spacing(1, 0, 0, 0)
  },
  formLabel: {
    margin: theme.spacing(1.5, 2, 0, 0)
  },
  fab: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20
  },
  details: {
    alignItems: 'center'
  },
  column: {
    flexBasis: '30%'
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
    marginLeft: '18px'
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const StaffAccount = ({ staff, reload, setReload }) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const [openAccordian, setOpenAccordian] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [staffMember, setStaffMember] = useState({ ...staff.staff } || {});

  const handleChange = (event) => {
    setStaffMember({
      ...staffMember,
      [event.target.name]: event.target.checked
    });
  };

  const handleUpdate = () => {
    UserService.updateStaff(staffMember)
      .then(() => {
        setReload(!reload);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <Grid container className={classes.root}>
      <EditUser
        open={isEdit}
        setOpen={setIsEdit}
        staff={staff}
        reload={reload}
        setReload={setReload}
      />
      <Alert
        description="Updated!"
        severity="success"
        type="snackbar"
        snackBar={{ open: openAlert, onClose: () => setOpenAlert(!openAlert) }}
      />
      <Grid item xs={12}>
        <Card className={classes.underCard}>
          <Card className={classes.overCard}>
            <div className={classes.fab}>
              <Typography variant="h6" gutterBottom>
                User details
              </Typography>
              <Fab
                variant="extended"
                aria-label="Edit Address"
                onClick={() => setIsEdit(true)}
                color="primary">
                <Edit style={{ marginRight: '5px' }} />
                Edit
              </Fab>
            </div>
            <Grid item container direction="column" xs={12} sm={6} md={6}>
              <Grid container>
                <Fragment key={staff.firstName}>
                  <Grid item xs={6}>
                    <Typography gutterBottom>First Name:</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="button">
                      {staff.firstName}
                    </Typography>
                  </Grid>
                </Fragment>
                <Fragment key={staff.lastName}>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Last Name:</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="button">
                      {staff.lastName}
                    </Typography>
                  </Grid>
                </Fragment>
                <Fragment key={staff.phoneNumber}>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Phone Number:</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="button">
                      {staff.phoneNumber}
                    </Typography>
                  </Grid>
                </Fragment>
                <Fragment key={staff.email}>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Email:</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="button">
                      {staff.email}
                    </Typography>
                  </Grid>
                </Fragment>
              </Grid>
            </Grid>
          </Card>
          <Accordion expanded={openAccordian}>
            <AccordionSummary
              expandIcon={
                <ExpandMore onClick={() => setOpenAccordian(!openAccordian)} />
              }
              aria-controls="panel1c-content"
              id="panel1c-header">
              <div className={classes.column}>
                <Typography className={classes.heading}>
                  Availability
                </Typography>
              </div>
              <div className={classes.column}>
                <Typography className={classes.secondaryHeading}>
                  Monday - Friday
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div className={classes.availability}>
                <FormGroup>
                  <FormControl>
                    <Grid container>
                      <FormLabel className={classes.formLabel}>
                        Monday:{' '}
                      </FormLabel>
                    </Grid>
                    <Grid container justify="flex-start">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.monday9Am}
                            onChange={handleChange}
                            name="monday9Am"
                            color="primary"
                          />
                        }
                        label="9am"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.monday11Am}
                            onChange={handleChange}
                            name="monday11Am"
                            color="primary"
                          />
                        }
                        label="11am"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.monday1Pm}
                            onChange={handleChange}
                            name="monday1Pm"
                            color="primary"
                          />
                        }
                        label="1pm"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.monday3Pm}
                            onChange={handleChange}
                            name="monday3Pm"
                            color="primary"
                          />
                        }
                        label="3pm"
                      />
                    </Grid>
                    <Grid container>
                      <FormLabel className={classes.formLabel}>
                        Tuesday:{' '}
                      </FormLabel>
                    </Grid>
                    <Grid container justify="flex-start">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.tuesday9Am}
                            onChange={handleChange}
                            name="tuesday9Am"
                            color="primary"
                          />
                        }
                        label="9am"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.tuesday11Am}
                            onChange={handleChange}
                            name="tuesday11Am"
                            color="primary"
                          />
                        }
                        label="11am"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.tuesday1Pm}
                            onChange={handleChange}
                            name="tuesday1Pm"
                            color="primary"
                          />
                        }
                        label="1pm"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.tuesday3Pm}
                            onChange={handleChange}
                            name="tuesday3Pm"
                            color="primary"
                          />
                        }
                        label="3pm"
                      />
                    </Grid>
                    <Grid container>
                      <FormLabel className={classes.formLabel}>
                        Wednesday:{' '}
                      </FormLabel>
                    </Grid>
                    <Grid container justify="flex-start">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.wednesday9Am}
                            onChange={handleChange}
                            name="wednesday9Am"
                            color="primary"
                          />
                        }
                        label="9am"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.wednesday11Am}
                            onChange={handleChange}
                            name="wednesday11Am"
                            color="primary"
                          />
                        }
                        label="11am"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.wednesday1Pm}
                            onChange={handleChange}
                            name="wednesday1Pm"
                            color="primary"
                          />
                        }
                        label="1pm"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.wednesday3Pm}
                            onChange={handleChange}
                            name="wednesday3Pm"
                            color="primary"
                          />
                        }
                        label="3pm"
                      />
                    </Grid>
                    <Grid container>
                      <FormLabel className={classes.formLabel}>
                        Thursday:{' '}
                      </FormLabel>
                    </Grid>
                    <Grid container justify="flex-start">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.thursday9Am}
                            onChange={handleChange}
                            name="thursday9Am"
                            color="primary"
                          />
                        }
                        label="9am"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.thursday11Am}
                            onChange={handleChange}
                            name="thursday11Am"
                            color="primary"
                          />
                        }
                        label="11am"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.thursday1Pm}
                            onChange={handleChange}
                            name="thursday1Pm"
                            color="primary"
                          />
                        }
                        label="1pm"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.thursday3Pm}
                            onChange={handleChange}
                            name="thursday3Pm"
                            color="primary"
                          />
                        }
                        label="3pm"
                      />
                    </Grid>
                    <Grid container>
                      <FormLabel className={classes.formLabel}>
                        Friday:{' '}
                      </FormLabel>
                    </Grid>
                    <Grid container justify="flex-start">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.friday9Am}
                            onChange={handleChange}
                            name="friday9Am"
                            color="primary"
                          />
                        }
                        label="9am"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.friday11Am}
                            onChange={handleChange}
                            name="friday11Am"
                            color="primary"
                          />
                        }
                        label="11am"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.friday1Pm}
                            onChange={handleChange}
                            name="friday1Pm"
                            color="primary"
                          />
                        }
                        label="1pm"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={staffMember.friday3Pm}
                            onChange={handleChange}
                            name="friday3Pm"
                            color="primary"
                          />
                        }
                        label="3pm"
                      />
                    </Grid>
                  </FormControl>
                </FormGroup>
              </div>
              <div className={cx(classes.column, classes.helper)}>
                <Typography variant="caption">
                  Change your availability
                  <br />
                </Typography>
              </div>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button size="small">Cancel</Button>
              <Button size="small" color="primary" onClick={handleUpdate}>
                Save
              </Button>
            </AccordionActions>
          </Accordion>
        </Card>
      </Grid>
    </Grid>
  );
};
export default StaffAccount;
