export default {
  serviceLabels: [
    {
      value: 'Plumbing, Gas Fitting, Hydronic Heating'
    },
    {
      value: 'Service, Repairs & Maintenance'
    },
    {
      value: 'Appliances Services'
    }
  ]
};
