import React, { useMemo, useState } from 'react';
import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import Pagination from '../../../utils/pagination/Pagination';
import SearchInput from '../SearchInput';
import globalUseStyles from '../../../utils/theme/styleHooks';
// eslint-disable-next-line import/no-cycle
import RequestListItem from '../../admin/jobs/requests/ListItem';
// eslint-disable-next-line import/no-cycle
import ReviewListItem from '../../admin/jobs/review/ListItem';
// eslint-disable-next-line import/no-cycle
import AssignedListItem from '../../admin/jobs/assigned/ListItem';
// eslint-disable-next-line import/no-cycle
import CompletedListItem from '../../admin/jobs/completed/ListItem';
import CustomerListItem from '../../admin/customers/ListItem';
import ArchivedCustomerListItem from '../../admin/customers/Archived';
import EmailListItem from "../../admin/modals/ViewEmailsModal/ListItem";
// eslint-disable-next-line import/no-cycle
import InvoiceListItem from "../../admin/modals/InvoiceDetailsModal/ListItem";

const PAGE_SIZE = 5;

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const DataTable = ({ dataList, headers, listItem, reload, setReload, job, user }) => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();

  const [filterResults, setFilterResults] = useState([]);
  const [filter, setFilter] = useState('');

  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    if (filter) {
      const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
      const lastPageIndex = firstPageIndex + PAGE_SIZE;
      return filterResults.slice(firstPageIndex, lastPageIndex);
    }
    if (dataList) {
      const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
      const lastPageIndex = firstPageIndex + PAGE_SIZE;
      return dataList.slice(firstPageIndex, lastPageIndex);
    }
    return null;
  }, [currentPage, dataList, filter, filterResults]);

  const setComponent = (req) => {
    switch (listItem) {
      case 'request':
        return (
          <RequestListItem
            request={req}
            reload={reload}
            setReload={setReload}
          />
        );
      case 'review':
        return (
          <ReviewListItem request={req} reload={reload} setReload={setReload} />
        );
      case 'assigned':
        return (
          <AssignedListItem
            request={req}
            reload={reload}
            setReload={setReload}
          />
        );
      case 'completed':
        return (
          <CompletedListItem
            request={req}
            reload={reload}
            setReload={setReload}
          />
        );
      case 'customer':
        return (
          <CustomerListItem
            request={req}
            reload={reload}
            setReload={setReload}
          />
        );
      case 'archivedCustomer':
        return (
          <ArchivedCustomerListItem
            request={req}
            reload={reload}
            setReload={setReload}
          />
        );
      case 'email':
        return (
            <EmailListItem
                request={req}
                job={job}
                user={user}
            />
        );
      case 'invoice':
        return (
            <InvoiceListItem
              request={req}
              reload={reload}
              setReload={setReload}
            />
        );
      default:
        return null;
    }
  };

  return (
    <Grid container className={globalClasses.adminRoot}>
      {listItem !== 'invoice' && (
      <Grid container justify="flex-end">
        <Grid item xs={8} sm={10} md={6} lg={4}>
          {dataList && (
            <SearchInput
              dataList={dataList}
              filter={filter}
              setFilter={setFilter}
              setFilterResults={setFilterResults}
            />
          )}
        </Grid>
      </Grid>
      )}
      <Grid container>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((item, index) => (
                  <TableCell align={index === 0 || (listItem === 'invoice' && index === 2) ? 'left' : 'right'}>
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList &&
                currentTableData.map((request) => setComponent(request))}
              {!dataList && (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    style={{
                      textAlign: 'center'
                    }}>
                    No Matching Records found!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {dataList && (
            <Pagination
              currentPage={currentPage}
              totalCount={dataList.length}
              pageSize={PAGE_SIZE}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </TableContainer>
      </Grid>
    </Grid>
  );
};
export default DataTable;
