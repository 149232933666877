import React, { useState } from 'react';
import {
  Modal,
  Grid,
  Card,
  FormControl,
  Input,
  FormGroup,
  InputLabel,
  DialogActions
} from '@material-ui/core';

// Local Imports
import UserService from 'services/user.service';
import globalUseStyles from 'utils/theme/styleHooks';
import Button from '../../../common/Button';
import AuthService from '../../../../services/auth.service';

const EditUser = ({ open, setOpen, staff, reload, setReload }) => {
  const globalClasses = globalUseStyles();
  const [staffMember, setStaffMember] = useState({
    id: AuthService.getCurrentId(),
    firstName: staff.firstName,
    lastName: staff.lastName,
    email: staff.email,
    phoneNumber: staff.phoneNumber
  });

  const handleChange = (event) => {
    setStaffMember({ ...staffMember, [event.target.name]: event.target.value });
  };

  const handleUpdate = () => {
    UserService.updateUser(staffMember)
      .then(() => {
        setReload(!reload);
        setOpen(!open);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <Grid item lg>
      <Modal
        className={globalClasses.modalRoot}
        open={open}
        onClose={() => setOpen(false)}>
        <Card className={globalClasses.modal}>
          <Grid item xs={12}>
            <FormGroup>
              <FormControl>
                <InputLabel>First Name</InputLabel>
                <Input
                  name="firstName"
                  value={staffMember.firstName}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <FormControl>
                <InputLabel>Last Name</InputLabel>
                <Input
                  name="lastName"
                  value={staffMember.lastName}
                  onChange={handleChange}
                  required
                />
              </FormControl>
              <FormControl>
                <InputLabel>Number</InputLabel>
                <Input
                  name="phoneNumber"
                  value={staffMember.phoneNumber}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <InputLabel>Email</InputLabel>
                <Input
                  name="email"
                  required
                  value={staffMember.email}
                  onChange={handleChange}
                />
              </FormControl>
            </FormGroup>
          </Grid>
          <DialogActions>
            <Grid container justify="space-between">
              <Button onClick={() => setOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleUpdate} color="primary" autoFocus>
                Done
              </Button>
            </Grid>
          </DialogActions>
        </Card>
      </Modal>
    </Grid>
  );
};
export default EditUser;
