import React from 'react';
import { Button, Modal, Grid, Box } from '@material-ui/core';
import {Close} from "@material-ui/icons";

// local imports
import globalUseStyles from 'utils/theme/styleHooks';
// eslint-disable-next-line import/no-cycle
import InvoiceDetails from "./InvoiceModal";


const Details = ({
  invoice,
  setInvoice,
  open,
  setOpen,
  reload,
  setReload,
  job,
  user
}) => {
  const globalClasses = globalUseStyles();

  return (
    <Grid item xs>
      <Modal
        className={`scrollableModalContainer ${globalClasses.modalRoot}`}
        open={open}
        onClose={() => setOpen(!open)}
      >
          <Box className={`scrollableModal ${globalClasses.modalMax}`} style={{ width: '60vw' }}>
            <Grid style={{ borderBottom: '1px solid lightGray', marginBottom: '1rem' }}>
                <h1>Invoice {invoice.id}</h1>
                <Button onClick={() => setOpen(false)} style={{ float: 'right', width: '10px', marginTop: '-4rem' }}><Close/></Button>
            </Grid>
            <InvoiceDetails
              invoice={invoice}
              setInvoice={setInvoice}
              reload={reload}
              setReload={setReload}
              setOpen={setOpen}
              job={job}
              user={user}
            />
          </Box>
      </Modal>
    </Grid>
  );
};
export default Details;
