import React, { useState } from 'react';
import { Card, Grid, Modal, Typography } from '@material-ui/core';

// local imports
import Button from 'components/common/Button';
import globalUseStyles from 'utils/theme/styleHooks';
// import ImageService from 'services/image.service';
import UploadComponent from '../../../../utils/UploadComponent';
import { APP_URLS } from '../../../../urls';

const AddImageModal = ({
  openModal,
  setOpenModal,
  jobId,
  reload,
  setReload
}) => {
  const globalClasses = globalUseStyles();
  const [images, setImages] = useState(null);

  const handleAdd = () => {
    // eslint-disable-next-line array-callback-return
    images.map((file, index) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('sortOrder', index);
      fetch(`${APP_URLS.staffImages}/${jobId}`, {
        method: 'post',
        body: formData
      }).then((res) => res.json());
    });
    setReload(!reload);
    setOpenModal(false);
  };

  return (
    <Modal
      className={globalClasses.modalRoot}
      open={openModal}
      onClose={() => setOpenModal(false)}>
      <Card className={globalClasses.modal}>
        <Grid container>
          <Grid container justify="space-around">
            <UploadComponent setFieldValue={setImages} form={false} />
          </Grid>
          {images &&
            images.map((image) => (
              <Grid container>
                <Typography>{image.name}</Typography>
              </Grid>
            ))}
          <Grid container>
            <Button onClick={() => setOpenModal(false)}>Cancel</Button>
            <Button disabled={!images} onClick={() => handleAdd()}>
              Add
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
};
export default AddImageModal;
