import axios from 'axios';
import authHeader from './auth-header';
import { APP_URLS } from '../urls';

const addUserImage = (id) =>
  axios.post(`${APP_URLS.userImages}/${id}`, { headers: authHeader() });

const deleteUserImage = (id) =>
  axios.delete(`${APP_URLS.userImages}/delete/${id}`, {
    headers: authHeader()
  });

const addStaffImage = (id) =>
  axios.post(`${APP_URLS.userImages}/${id}`, { headers: authHeader() });

const deleteStaffImage = (id) =>
  axios.delete(`${APP_URLS.staffImages}/delete/${id}`, {
    headers: authHeader()
  });

const getAllUserImages = (id) =>
  axios.get(`${APP_URLS.userImages}/${id}`, { headers: authHeader() });

const getAllStaffImages = (id) =>
  axios.get(`${APP_URLS.staffImages}/${id}`, { headers: authHeader() });

export default {
  addUserImage,
  deleteUserImage,
  addStaffImage,
  deleteStaffImage,
  getAllUserImages,
  getAllStaffImages
};
