import React from 'react';

// Local Imports
import Footer from 'components/common/Footer';
import AboutUs from './AboutUs';

const Contact = () => (
  <div>
    <AboutUs />
    <Footer />
  </div>
);
export default Contact;
