import React from 'react';
import { bool, func, node, oneOf, shape, string } from 'prop-types';
import { AlertTitle, Alert as MUIAlert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

const Alert = ({
  icon,
  description,
  severity,
  title,
  variant,
  action,
  snackBar,
  type
}) => {
  const { onClose, open } = snackBar;
  return (
    <>
      {type === 'snackbar' ? (
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={onClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <MUIAlert onClose={onClose} severity={severity} variant={variant}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {description}
          </MUIAlert>
        </Snackbar>
      ) : (
        <MUIAlert
          icon={icon}
          severity={severity}
          variant={variant}
          action={action}>
          {title && <AlertTitle>{title}</AlertTitle>}
          {description}
        </MUIAlert>
      )}
    </>
  );
};
Alert.defaultProps = {
  severity: 'info', // success | error
  icon: null,
  variant: 'filled', // outlined
  action: null,
  title: null,
  snackBar: {},
  type: 'alert' // snackbar
};

Alert.propTypes = {
  description: string.isRequired,
  severity: string,
  title: oneOf([node, string]),
  icon: oneOf([node, bool]),
  variant: string,
  action: node,
  snackBar: shape({
    onClose: func,
    open: bool
  }),
  type: string
};

export default Alert;
