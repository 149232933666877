import React, { useEffect, useState } from 'react';
import {
  Card,
  FormControl,
  Grid,
  makeStyles,
  InputLabel,
  MenuItem,
  Modal,
  Select
} from '@material-ui/core';
import axios from 'axios';

// local imports
import Button from 'components/common/Button';
import globalUseStyles from 'utils/theme/styleHooks';
import { APP_URLS } from '../../../../urls';
import Loading from '../../../common/Loading';

const useStyles = makeStyles((theme) => ({
  text: {
    width: '100%',
    marginBottom: theme.spacing(2)
  }
}));

const ChangeAssigneeModal = ({ openModal, setOpenModal, request }) => {
  const globalClasses = globalUseStyles();
  const classes = useStyles();
  const [assignedStaff, setAssignedStaff] = useState(null);
  const [staff, setStaff] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadStaff = () => {
    const url = APP_URLS.staff;
    axios
      .get(url)
      .then(({ data }) => {
        setStaff([...data]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handleUpdate = (changeRequest) => {
    const url = `${APP_URLS.job}/update`;
    axios
      .put(url, {
        id: changeRequest.jobId,
        userId: changeRequest.userId,
        addressId: changeRequest.addressId,
        serviceType: changeRequest.serviceType,
        customerMessage: changeRequest.customerMessage,
        status: changeRequest.status,
        staffId: assignedStaff
      })
      .then(() => setOpenModal(false))
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handleStaffChange = (event) => {
    setAssignedStaff(event.staffId);
  };

  useEffect(() => {
    if (!staff) {
      loadStaff();
    }
  }, [staff]);

  return (
    <Modal
      className={globalClasses.modalRoot}
      open={openModal}
      onClose={() => setOpenModal(false)}>
      <Card className={globalClasses.modal}>
        {loading && (
          <Grid container justify="center">
            <Loading />
          </Grid>
        )}
        {!loading && (
          <Grid container>
            <Grid container justify="space-around">
              <FormControl className={classes.text}>
                <InputLabel>Assignees</InputLabel>
                <Select className={classes.text}>
                  {staff.map((member) => (
                    <MenuItem
                      value={member}
                      key={member.id}
                      onClick={() => handleStaffChange(member)}>
                      {member.firstName} {member.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid container justify="space-evenly">
              <Button onClick={() => setOpenModal(false)}>Cancel</Button>
              <Button onClick={() => handleUpdate(request)}>Change</Button>
            </Grid>
          </Grid>
        )}
      </Card>
    </Modal>
  );
};
export default ChangeAssigneeModal;
