import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';

// local imports
import Loading from 'components/common/Loading';
import Alert from 'components/common/Alert';
import JobService from 'services/job.service';
import DataTable from '../../../common/DataTable';

const headers = [
    'Id',
    'Service',
    'Assigned To',
    'Customer',
    'Actions'
];

const Completed = () => {
    const [bookings, setBookings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openAlert, setOpenAlert] = useState(false);

    const loadCompletedJobs = () => {
        JobService.loadCompleted()
            .then(({data}) => {
                setBookings([...data]);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                // if (error.response.status !== 404) {
                //   setOpenAlert(true);
                // }
            });
    };

    useEffect(() => {
        if (!bookings) {
            loadCompletedJobs();
        }
    }, [bookings]);

    return (
        <Grid container>
            {loading && (
                <Grid container justify="center">
                    <Loading/>
                </Grid>
            )}
            <Alert
                type="snackbar"
                snackBar={{
                    open: openAlert,
                    onClose: () => setOpenAlert(!openAlert)
                }}
                description="Something went wrong"
                title="Error"
                severity="error"
            />
            {!loading && (
                <DataTable dataList={bookings} headers={headers} listItem="completed"/>
            )}
        </Grid>
    );
};
export default Completed;
