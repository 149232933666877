import React, { useState } from 'react';
import {
  makeStyles,
  AppBar,
  Tabs,
  Tab,
  ButtonGroup,
  Grid,
  withStyles
} from '@material-ui/core';

// Local Imports
import TabPanel from 'components/common/TabPanel';
import Button from 'components/common/Button';
import globalUseStyles from 'utils/theme/styleHooks';
import Requests from './jobs/requests/Requests';
import AssignedJobs from './jobs/assigned/Assigned';
import Completed from './jobs/completed/Completed';
import StaffList from './staff/List';
import CustomerList from './customers/List';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 270
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  buttonGroup: {
    height: theme.spacing(6),
    padding: theme.spacing(2, 0, 2, 4),
    marginTop: theme.spacing(2),
    width: '50%',
    minWidth: theme.spacing(50),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 0, 2, 2)
    }
  },
  subGroup: {
    height: theme.spacing(6),
    padding: theme.spacing(2),
    marginLeft: theme.spacing(5),
    width: '64.25%',
    minWidth: theme.spacing(49),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0)
    }
  },
  button: {
    width: '33%'
  }
}));

const StyleTabs = withStyles({
  indicator: {
    backgroundColor: '#fff'
  }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const AdminDashboard = () => {
  const globalClasses = globalUseStyles();
  const classes = useStyles();
  const [jobChoice, setJobChoice] = useState(0);
  const [value, setValue] = useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const jobTypes = [
    {
      id: 0,
      name: 'Requests',
      component: <Requests key="0" />
    },
    {
      id: 1,
      name: 'Assigned',
      component: <AssignedJobs key="2" />
    },
    {
      id: 2,
      name: 'Completed',
      component: <Completed key="3" />
    }
  ];

  const a11yProps = (index) => ({
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`
  });

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <StyleTabs
          value={value}
          onChange={handleChange}
          aria-label="jobs"
          variant="fullWidth">
          <Tab value="one" label="Jobs" {...a11yProps('one')} />
          <Tab value="two" label="Customers" {...a11yProps('two')} />
          <Tab value="three" label="Staffs" {...a11yProps('three')} />
        </StyleTabs>
      </AppBar>
      <TabPanel value={value} index="one">
        <Grid container>
          <Grid item xs={12}>
            <ButtonGroup className={classes.subGroup}>
              {jobTypes.map((type) => (
                <Button
                  key={type.id}
                  className={
                    jobChoice === jobTypes[type.id].id
                      ? `${globalClasses.selectButton} ${classes.button}`
                      : `${globalClasses.clearButton} ${classes.button}`
                  }
                  onClick={() => setJobChoice(type.id)}>
                  {type.name}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
          {jobTypes.map(
            (choice) => jobChoice === choice.id && choice.component
          )}
        </Grid>
      </TabPanel>
      <TabPanel value={value} index="two">
        <CustomerList />
      </TabPanel>
      <TabPanel value={value} index="three">
        <StaffList />
      </TabPanel>
    </div>
  );
};
export default AdminDashboard;
