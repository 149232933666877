import React from 'react';
import axios from 'axios';
import {
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText
} from '@material-ui/core';

// local imports
import globalUseStyles from 'utils/theme/styleHooks';
import { APP_URLS } from 'urls';
import Button from 'components/common/Button';

const CancelModal = ({ open, setOpen, data, reload, setReload }) => {
  const globalClasses = globalUseStyles();
  const handleUpdate = (requestUpdate) => {
    const url = `${APP_URLS.job}/update`;
    axios
      .put(url, {
        id: requestUpdate.id,
        userId: requestUpdate.userId,
        addressId: requestUpdate.addressId,
        serviceType: requestUpdate.serviceType,
        customerMessage: requestUpdate.customerMessage,
        status: 5
      })
      .then(() => {
        setOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      className={globalClasses.modalRoot}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You want to cancel job request{' '}
          <span className={globalClasses.titleColor}>
            #{data && data.id}
          </span>{' '}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpen(false)}>No</Button>
        <Button onClick={() => handleUpdate(data)} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default CancelModal;
