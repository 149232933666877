import React from 'react';
import { Card, Grid, Modal, Typography } from '@material-ui/core';

// local imports
import Button from 'components/common/Button';
import globalUseStyles from 'utils/theme/styleHooks';
import UserService from '../../../../services/user.service';

const ArchiveCustomerModal = ({
  openModal,
  setOpenModal,
  user,
  reload,
  setReload
}) => {
  const globalClasses = globalUseStyles();

  const handleArchive = () => {
    UserService.archiveUser(user.userId)
      .then(() => {
        setReload(!reload);
        setOpenModal(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <Modal
      className={globalClasses.modalRoot}
      open={openModal}
      onClose={() => setOpenModal(false)}>
      <Card className={globalClasses.modalSmall}>
        <Grid container>
          <Grid container justify="space-around">
            <Typography className={globalClasses.modalHeader}>
              Please confirm that you would like to archive the customer{' '}
              {user.firstName} {user.lastName}?
            </Typography>
          </Grid>
          <Grid container justify="space-evenly">
            <Button
                onClick={() => setOpenModal(false)}
                className={globalClasses.clearButton}>
              Cancel
            </Button>
            <Button
                onClick={handleArchive}
                className={globalClasses.clearButton}>
              Archive
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
};
export default ArchiveCustomerModal;
