import React, {Fragment, useState} from 'react';
import {
  Grid,
  DialogTitle,
  Dialog,
  DialogContent,
  Typography,
  DialogActions
} from '@material-ui/core';

// Local Imports
import Button from 'components/common/Button';
import globalUseStyles from 'utils/theme/styleHooks';
import Labels from '../../../common/Labels';
import JobService from "../../../../services/job.service";

const JobsDetails = ({ job, open, setOpen, id, reload, setReload }) => {
  const globalClasses = globalUseStyles();
  const api = process.env.REACT_APP_SITE_API;
  const { serviceLabels } = Labels;
  const [staffNote] = useState(job.adminMessage);
  const [jobInfo] = useState({
    id: job.jobId,
    userId: job.userId,
    addressId: job.addressId,
    status: job.status,
    serviceType: job.serviceType,
    customerMessage: job.customerMessage,
    staffMessage: job.staffMessage,
    staffId: job.staffId > 0 ? job.staffId : null,
    adminMessage: staffNote
  });

  const handleClose = () => {
    setOpen(!open);
  };

  const completeJob = () => {
    const updateInfo = {
      ...jobInfo,
      status: 4,
      adminMessage: staffNote
    };
    JobService.updateJob(updateInfo)
        .then(() => {
          setOpen(false);
          setReload(!reload);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('Error: ', error);
        });
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={`${id}-dialog-title`}
      open={open}>
      <DialogTitle id={`${id}-dialog-title`}>Job Details</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Fragment key="1">
            <Grid item xs={6}>
              <Typography>Address:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="paragraph">
                {job.street}, {job.city}
              </Typography>
            </Grid>
          </Fragment>
          <Fragment key="3">
            <Grid item xs={12} md={6}>
              <Typography>Service Type:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="paragraph">
                {serviceLabels[job.serviceType].value}
              </Typography>
            </Grid>
          </Fragment>
          <Fragment key="4">
            <Grid item xs={12} md={6}>
              <Typography>Notes From Customer:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="paragraph">
                {job.customerMessage}
              </Typography>
            </Grid>
          </Fragment>
          <Fragment key="5">
            <Grid item xs={12} md={6}>
              Uploads From Customer:
            </Grid>
            {job.userImages && (
              <Grid item xs={12}>
                {job.userImages.map((image) => (
                  <a
                    href={`${api}${image.originalPath}`}
                    target="_blank"
                    rel="noreferrer">
                    <img
                      src={`${api}${image.originalPath}`}
                      alt={`${api}${image.originalPath}`}
                      className={globalClasses.imageHeight}
                    />
                  </a>
                ))}
              </Grid>
            )}
            {!job.userImages && (
              <Grid item xs={12}>
                <Typography gutterBottom variant="paragraph">
                  ---
                </Typography>
              </Grid>
            )}
          </Fragment>
          <Fragment key="4">
            <Grid item xs={12} md={6}>
              <Typography>Notes From Admin:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="paragraph">
                {job.adminMessage}
              </Typography>
            </Grid>
          </Fragment>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <div style={{flex: '1 0 0'}} />
          <Button
              className={`${globalClasses.selectButton} ${globalClasses.modalButtonSize}`}
              onClick={() => completeJob()}>
            Complete Job
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default JobsDetails;
