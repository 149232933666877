import React from 'react';
import {
  Box,
  Container,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core';

// Local import
import LonsdaleLogoRed from 'assets/Lonsdale-Logo.png';

const useStyles = makeStyles((theme) => ({
  footer: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    },
    backgroundColor: '#fff',
    color: '#da291c'
  },
  footerLogo: {
    height: 90
  }
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div>
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container justify="center">
          <Grid item>
            <Typography variant="h6" component="h1" className={classes.logo}>
              <img
                alt="Logo"
                src={LonsdaleLogoRed}
                className={classes.footerLogo}
              />
            </Typography>
          </Grid>
        </Grid>
        <Typography variant="h6" align="center" gutterBottom>
          Lonsdale Plumbers
        </Typography>
        <Typography variant="subtitle1" align="center" component="p">
          Your Local Specialist!
        </Typography>
        <Box mt={5}>
          <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://lonsdaleplumbers.com/">
              Lonsdale Plumbers
            </Link>{' '}
            {new Date().getFullYear()}.
          </Typography>
        </Box>
      </Container>
    </div>
  );
};
export default Footer;
