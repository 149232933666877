import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Fade,
  Grid,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  useTheme,
  ListItem
} from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  MoreVertOutlined,
  AccountCircle,
  Phone,
  Today,
  ExitToApp
} from '@material-ui/icons';

// Local Imports
import LonsdaleLogoRed from 'assets/Lonsdale-Logo.png';
import Button from 'components/common/Button';
import AuthService from 'services/auth.service';
import QuickbooksService from "services/quickbooks.service";
import { HashLink } from 'react-router-hash-link';
import QuickbooksSvg from 'assets/C2QB_green_btn_med_default.svg'
import QuickbooksSvgHover from 'assets/C2QB_green_btn_med_hover.svg'
import Alert from "../Alert";
// import Alert from "../Alert";

const  useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: '#fff'
  },
  logo: {
    fontFamily: 'Work Sans, sans-serif',
    fontWeight: 600,
    height: 95
  },
  image: {
    fontSize: '72pt',
    height: 75,
    marginTop: '60px'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottomStyle: 'solid',
    borderBottomWidth: '10px',
    borderBottomColor: theme.palette.primary.main
  },
  menuItem: {
    marginRight: theme.spacing(1)
  },
  footer: {
    paddingTop: theme.spacing(4, 10, 4, 10),
    backgroundColor: theme.palette.primary.main
  },
  footerLogo: {
    fontWeight: 600,
    height: 90
  },
  footerAlert: {
    margin: 0,
    position: 'fixed',
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#2096F4"
  },
  menuLinks: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  phoneNumber: {
    color: theme.palette.primary.light
  },
  phoneIcon: {
    marginRight: '8px'
  },
  quickbooksButton: {
    border: 'none',
    '&:focus': {
      boxShadow: 'none'
    }
  }
}));

const MenuBar = ({ children, isPublic, isAdmin, isStaff, isUser }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [companyName, setCompanyName] = useState(null);
  const [over, setOver] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const handleLogout = () => {
    setAnchorEl(null);
    AuthService.logout();
    history.push('/login');
  };

  const initiateQuickbooksAuth = async () => {
    const response = await QuickbooksService.initiatePaymentAuthorization();
    window.location = response.data
  };

  const revokeQuickbooksAuth = async () => {
    const response = await QuickbooksService.revokeAuth()
        .then(({ data }) => {
          if (data === "Authorization revoked.") {
            setCompanyName(null);
          }
          else
          {
            setAlertMessage(data)
            setOpenAlert(true);
          }
        })
        .catch(() => {
          setAlertMessage('Failed to revoke authorization.')
          setOpenAlert(true);
        });
  };

  const loadCompanyName = () => {
    const response = QuickbooksService.loadCompanyName()
        .then(({ data }) => {
          if (data === 'Quickbooks connection expired. Please reconnect to quickbooks.') {
            setCompanyName(null);
          }
          else if (data === "Failed to retrieve company name.") {
            setCompanyName(null);
          }
          else
          {
            setCompanyName(data);
          }
        })
        .catch(() => {
          setCompanyName(null);
        });
  };

  useEffect(() => {
    setIsLandingPage(history.location.pathname === '/');
    loadCompanyName();
  }, [history.location.pathname]);

  const generateMenuForMediumScreens = () => {
    if (isAdmin) {
      return (
        <div>
          <Grid container spacing={3} justify="space-evenly">
            {!companyName && (
            <Grid item>
                <Button
                    onMouseOver={() => setOver(true)}
                    onMouseOut={() => setOver(false)}
                    onClick={initiateQuickbooksAuth}
                    className={classes.quickbooksButton}
                >
                  <img
                      src={over ? QuickbooksSvgHover : QuickbooksSvg}
                      alt="Connect to QuickBooks"
                  />
                </Button>
            </Grid>
            )}
            {!!companyName && (
                <Grid item style={{ marginTop: '.1rem' }}>
                  <span style={{ color: 'black', fontSize: '1.15rem', marginRight: '2rem' }}>
                    You are connected to {companyName}.
                  </span>
                  <Button
                      onClick={revokeQuickbooksAuth}
                  >
                    Disconnect
                  </Button>
                </Grid>
            )}
            <Grid item xs md={4} lg>
              <Button
                key="Sign Up/Login CTA"
                to="/login"
                onClick={handleLogout}
                component={RouterLink}
                icon={<ExitToApp />}>
                Logout
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    }
    if (isPublic) {
      return (
        <div style={{ width: "80%" }}>
          <Grid container spacing={3} justify="space-around" style={{ textAlign: 'center' }}>
            {isLandingPage && (
              <Grid item md={4} lg>
                <Button
                  style={{ width: '100px' }}
                  key="About Us"
                  to="#aboutUs"
                  smooth
                  component={HashLink}>
                  About Us
                </Button>
              </Grid>
            )}
            {isLandingPage && (
              <Grid item md={4} lg>
                <Button
                  style={{ width: '100px' }}
                  key="Services"
                  to="#services"
                  smooth
                  component={HashLink}>
                  Services
                </Button>
              </Grid>
            )}
            <Grid item md={4} lg>
              <Button
                style={{ width: '200px' }}
                key="Request Service"
                to="/signup-booking"
                component={RouterLink}
                icon={<Today />}>
                Request Service
              </Button>
            </Grid>
            <Grid item xs md={4} lg>
              <Button
                key="Sign In"
                to="/login"
                component={RouterLink}
                icon={<AccountCircle />}>
                Sign In
              </Button>
            </Grid>
            <Grid item xs md={4} lg>
              <ListItem className={classes.phoneNumber}>
                  <Phone className={classes.phoneIcon}/> 604 770 1416
              </ListItem>
              </Grid>
          </Grid>
        </div>
      );
    }
    if (isStaff) {
      return (
        <div>
          <Grid container spacing={3} justify="space-evenly">
            {!companyName && (
                <Grid item>
                  <Button
                      onMouseOver={() => setOver(true)}
                      onMouseOut={() => setOver(false)}
                      onClick={initiateQuickbooksAuth}
                      className={classes.quickbooksButton}
                  >
                    <img
                        src={over ? QuickbooksSvgHover : QuickbooksSvg}
                        alt="Connect to QuickBooks"
                    />
                  </Button>
                </Grid>
            )}
            {!!companyName && (
                <Grid item style={{ marginTop: '.1rem' }}>
                  <span style={{ color: 'black', fontSize: '1.15rem' }}>
                    You are connected to {companyName}.
                  </span>
                  <Button
                      onClick={revokeQuickbooksAuth}
                  >
                    Disconnect
                  </Button>
                </Grid>
            )}
            <Grid item xs md={4} lg>
              <Button
                key="Sign Up/Login CTA"
                to="/login"
                component={RouterLink}
                onClick={handleLogout}
                icon={<ExitToApp />}>
                Logout
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    }
    if (isUser) {
      return (
        <div>
          <Grid container spacing={3} justify="space-evenly">
            <Grid item md={4} lg>
              <Button
                style={{ width: '200px' }}
                key="Request Service"
                to="/signup-booking"
                component={RouterLink}
                icon={<Today />}>
                Request Service
              </Button>
            </Grid>
            <Grid item xs md={4} lg>
              <Button
                key="Sign In"
                to="/login"
                onClick={handleLogout}
                component={RouterLink}
                icon={<ExitToApp />}>
                Log out
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    }
    return null;
  };

  const generateMenuForSmallScreens = () => {
    if (isAdmin) {
      return (
        <div>
          <Button
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            className={classes.menuButton}>
            <MoreVertOutlined />
          </Button>
          <Menu
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            TransitionComponent={Fade}
            className={classes.menuItem}
            variant="menu">
            <MenuItem component={RouterLink} onClick={handleLogout} to="/login">
              Log out
            </MenuItem>
          </Menu>
        </div>
      );
    }
    if (isPublic) {
      return (
        <div>
          <Button
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            className={classes.menuButton}>
            <MoreVertOutlined />
          </Button>
          <Menu
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            TransitionComponent={Fade}
            className={classes.menuItem}
            variant="menu">
            {isLandingPage && (
              <MenuItem
                onClick={() => setAnchorEl(null)}
                component={HashLink}
                smooth
                to="#aboutUs">
                About Us
              </MenuItem>
            )}
            {isLandingPage && (
              <MenuItem
                onClick={() => setAnchorEl(null)}
                component={HashLink}
                smooth
                to="#services">
                Services
              </MenuItem>
            )}
            <MenuItem
              onClick={() => setAnchorEl(null)}
              component={RouterLink}
              to="/signup-booking">
              Request Service
            </MenuItem>
            <MenuItem
              onClick={() => setAnchorEl(null)}
              component={RouterLink}
              to="/login">
              Sign In
            </MenuItem>
            <ListItem className={classes.phoneNumber}>
              <Phone className={classes.phoneIcon}/>604 770 1416
            </ListItem>
          </Menu>
        </div>
      );
    }
    if (isStaff) {
      return (
        <div>
          <Grid container spacing={3} justify="space-evenly">
            <Grid item xs md={4} lg>
              <Button
                key="Sign Up/Login CTA"
                component={RouterLink}
                onClick={handleLogout}
                icon={<ExitToApp />}>
                Logout
              </Button>
            </Grid>
          </Grid>
        </div>
      );
    }
    if (isUser) {
      return (
        <div>
          <Button
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            className={classes.menuButton}>
            <MoreVertOutlined />
          </Button>
          <Menu
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            TransitionComponent={Fade}
            className={classes.menuItem}
            variant="menu">
            <MenuItem
              onClick={() => setAnchorEl(null)}
              component={RouterLink}
              to="/signup-booking">
              Request Service
            </MenuItem>
            <MenuItem component={RouterLink} onClick={handleLogout}>
              Log out
            </MenuItem>
          </Menu>
        </div>
      );
    }
    return null;
  };

  /* const generateFooter = () => {
    if(isPublic) {
      return (
        <div>
          <Grid container style={{ minWidth: "100vw" }}>
            <Grid className={classes.footerAlert}>
              <Alert
                  className={classes.footerAlertContent}
                  description="A note to all customers: We will be closed from August 31st to September 30th. Please refrain from booking any services within that time window. Thank you."
                  severity="info"
              />
            </Grid>
          </Grid>
        </div>
      )
    }
    return null;
  }; */

  return (
    <div>
      <Alert
          type="snackbar"
          snackBar={{
            open: openAlert,
            onClose: () => setOpenAlert(!openAlert)
          }}
          description={alertMessage}
          title="Error:"
          severity="error"
      />
      <AppBar className={classes.header} position="relative">
        <Toolbar className={classes.toolbar}>
          <RouterLink to="/">
            <img alt="Logo" src={LonsdaleLogoRed} className={classes.logo} />
          </RouterLink>

          {isMediumScreen && generateMenuForMediumScreens()}
          {!isMediumScreen && generateMenuForSmallScreens()}
        </Toolbar>
      </AppBar>
      <main>{children}</main>
      {/* <div>
        {generateFooter()}
      </div> */}
    </div>
  );
};
export default MenuBar;
