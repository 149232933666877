import React, { useState } from 'react';
import {
    Grid,
    makeStyles,
    Card,
    Typography
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// Local Imports
import Button from 'components/common/Button';
import globalUseStyles from 'utils/theme/styleHooks';
import Alert from '../../common/Alert';
import Loading from '../../common/Loading';
import QuickbooksService from "../../../services/quickbooks.service";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '50px',
        [theme.breakpoints.down('sm')]: {
            padding: '20px'
        }
    },
    button: {
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    subtitle: {
        fontSize: '14pt',
        marginTop: '30px',
        marginBottom: '20px'
    },
    card: {
        padding: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(6)
        }
    }
}));

const QuickbooksPayment = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const realmId = urlParams.get('realmId');
    const state = urlParams.get('state');

    const classes = useStyles();
    const globalClasses = globalUseStyles();
    const [loading, setLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [successAlertText, setSuccessAlertText] = useState('');
    const history = useHistory();
    const authRequest = {
        code,
        state,
        realmId
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        try {
            if (authRequest.code != null) {
                const res = await QuickbooksService.finishAuth(authRequest)
                if (res.status === 200) {
                    setOpenSuccessAlert(true);
                    setSuccessAlertText("Payment Authorization Successful")
                }
            }
            else {
                setOpenAlert(true);
                setAlertText("Failed to complete authorization")
            }
        } catch (e) {
            setOpenAlert(true)
            setAlertText(e.response.data.message)
        }
        setLoading(false)
    };

    const handleBack = (event) => {
        event.preventDefault();
        history.push('/admin')
    }

    return (
        <Grid container className={classes.root}>
            <Alert
                type="snackbar"
                snackBar={{
                    open: openAlert,
                    onClose: () => setOpenAlert(!openAlert)
                }}
                description={alertText}
                title="Error"
                severity="error"
            />
            <Alert
                type="snackbar"
                snackBar={{
                    open: openSuccessAlert,
                    onClose: () => setOpenSuccessAlert(!openSuccessAlert)
                }}
                description={successAlertText}
                severity="success"
            />
            <Grid item sm={12} md={8} lg={6} xl={4}>
                <Card className={classes.card}>
                    <div className={globalClasses.titleSize}>
                        <Typography variant="h3" style={{ marginLeft: "3rem" }}>Complete Authorization</Typography>
                    </div>
                    <Grid container justify="space-between" className={classes.button}>
                        <Grid item>
                            {!loading && (
                                <Button type="back" onClick={handleBack}>
                                    Back to Dashboard
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            {!loading && (
                                <Button type="submit" onClick={handleSubmit}>
                                    Finish Quickbooks Authorization
                                </Button>
                            )}
                            {loading && <Loading />}
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    );
};
export default QuickbooksPayment;
