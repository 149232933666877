import React, { useEffect, useState } from 'react';
import { ButtonGroup, Grid, Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add } from '@material-ui/icons';
import axios from 'axios';

// local imports
import { APP_URLS } from 'urls';
import globalUseStyles from 'utils/theme/styleHooks';
import Loading from 'components/common/Loading';
import Alert from 'components/common/Alert';
import StaffListItem from './ListItem';
import AddStaff from '../modals/AddStaffModal';
import Button from '../../common/Button';

const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  fab: {
    padding: theme.spacing(3)
  },
  containerBox: {
    flexGrow: 1,
    backgroundColor: theme.palette.tertiary.main
  }
}));

const StaffList = () => {
  const globalClasses = globalUseStyles();
  const classes = useStyles();

  const [staff, setStaff] = useState(null);
  const [archivedStaff, setArchivedStaff] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  // load requested jobs
  const loadStaff = () => {
    if (!showArchived) {
      const url = APP_URLS.staff;
      axios
      .get(url)
      .then(({ data }) => {
        setStaff({
          list: [...data]
        });
        setLoading(false);
      })
      .catch((error) => {
        setOpenAlert(true);
        setLoading(false);
        // eslint-disable-next-line no-console
        console.log(error);
      });
    } else {
      const url = `${APP_URLS.archivedStaff}`;
      axios
      .get(url)
      .then(({ data }) => {
        setArchivedStaff({
          list: [...data]
        });
        setLoading(false);
      })
      .catch((error) => {
        setOpenAlert(true);
        setLoading(false);
        // eslint-disable-next-line no-console
        console.log(error);
      });
    }
  };

  useEffect(() => {
    loadStaff();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showArchived]);

  const toggleArchived = () => {
    setLoading(true);
    setShowArchived(!showArchived);
  };

  return (
    <Grid container>
      <Grid container spacing={2} className={classes.containerBox}>
        {loading && (
          <Grid container justify="center">
            <Loading />
          </Grid>
        )}
        <Alert
          type="snackbar"
          snackBar={{
            open: openAlert,
            onClose: () => setOpenAlert(!openAlert)
          }}
          description="Something went wrong"
          title="Error"
          severity="error"
        />
        {!loading && (
          <>
            <Grid container justify="flex-end" className={classes.fab}>
            <Grid item xs={12}>
            <ButtonGroup className={classes.subGroup}>
              <Button
                className={
                  !showArchived
                    ? `${globalClasses.selectButton} ${classes.button}`
                    : `${globalClasses.clearButton} ${classes.button}`
                }
                onClick={() => toggleArchived()}>
                Active
              </Button>
              <Button
                className={
                  showArchived
                    ? `${globalClasses.selectButton} ${classes.button}`
                    : `${globalClasses.clearButton} ${classes.button}`
                }
                onClick={() => toggleArchived()}>
                Archived
              </Button>
            </ButtonGroup>
          </Grid>
              <Grid item>
                <Fab
                  variant="extended"
                  color="primary"
                  onClick={() => setOpenAdd(!openAdd)}>
                  <Add className={classes.extendedIcon} />
                  Add Staff
                </Fab>

                <AddStaff open={openAdd} setOpen={setOpenAdd} />
              </Grid>
            </Grid>
            {// eslint-disable-next-line no-nested-ternary
              showArchived ? (
                archivedStaff.list.length === 0 ? (
                  <Grid container item justify="center">
                    No records found
                  </Grid>
                  ) : (
                    archivedStaff.list.map((member) => <StaffListItem member={member} />)
                  )
              ) : (
                staff.list.length === 0 ? (
                  <Grid container item justify="center">
                    No records found
                  </Grid>
                ) : (
                  staff.list.map((member) => <StaffListItem member={member} />)
                )
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default StaffList;
