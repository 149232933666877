import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
    '&:hover': {
      borderWidth: '5px !important',
      borderRadius: '8px',
      borderColor: `${theme.palette.primary.main} !important`,
      backgroundColor: '#eee'
    }
  }
}));

const UploadComponent = (props) => {
  const classes = useStyles();
  const { setFieldValue, form } = props;

  const handleUploadForm = (acceptedFiles) => {
    setFieldValue(acceptedFiles);
  };

  const handleUpload = (acceptedFiles) => {
    setFieldValue(acceptedFiles);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: form ? handleUploadForm : handleUpload
  });

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      {}
      <Button className={classes.root}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p >Drag &amp; drop some files here, or click to select files</p>
        )}
      </Button>
    </div>
  );
};

export default UploadComponent;
