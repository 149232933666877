import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Tooltip,
  Fab,
  InputLabel,
  Select,
  MenuItem,
  FormControl, Box
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

// local imports
import CancelModal from 'components/admin/modals/Cancel/Cancel';
import globalUseStyles from 'utils/theme/styleHooks';
import { APP_URLS } from 'urls';
import JobService from 'services/job.service';
import Loading from 'components/common/Loading';
import {useHistory} from "react-router-dom";
import Labels from '../../../common/Labels';
import SendEmailModal from "../SendEmailModal/SendEmailModal";
import Alert from "../../../common/Alert";
// eslint-disable-next-line import/no-cycle
import ViewEmailsModal from "../ViewEmailsModal/ViewEmailsModal";
// eslint-disable-next-line import/no-cycle
import InvoiceDetailsModal from "../InvoiceDetailsModal";
import quickbooksService from "../../../../services/quickbooks.service";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: theme.spacing(4)
  },
  addFAB: {
    margin: theme.spacing(-2, -2, 0, 0),
    height: 25,
    width: 35
  },
  card: {
    width: '60%',
    padding: theme.spacing(4)
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  image: {
    margin: 'auto'
  },
  images: {
    height: '100%',
    width: '150px',
    margin: theme.spacing(2),
    padding: theme.spacing(4)
  },
  info: {
    fontSize: '12pt',
    textAlign: 'center'
  },
  tableRow: {
    align: 'right'
  },
  textField: {
    width: '100%',
    margin: theme.spacing(1, 0, 0, 0)
  },
  text: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  middleGrid: {
    marginBottom:'2%'
  },
  bottomGrid: {
    marginBottom:'2%'
  },
  marginRight: {
    marginRight: "15px"
  },
  paddingRight: {
    paddingRight: "30px"
  },
  marginBottom: {
    marginBottom: '80px'
  },
  marginTop: {
    marginTop: '20px'
  },
  emailButton: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.tertiary.text,
    borderColor: theme.palette.tertiary.text,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.tertiary.text,
      borderColor: theme.palette.tertiary.text,
      borderWidth: '1px'
    }
  },
  viewEmailButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.tertiary.text,
    borderColor: theme.palette.tertiary.text,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.tertiary.text,
      borderColor: theme.palette.tertiary.text,
      borderWidth: '1px'
    }
  }
}));

SendEmailModal.openSuccessSendAlert = false;
const UserDetails = ({
  jobRequest,
  statusUpdate,
  setOpen,
  reload,
  setReload
}) => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const history = useHistory();
  const [staffNote, setStaffNote] = useState(jobRequest.adminMessage);
  const [staff, setStaff] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAssigned, setIsAssigned] = useState(false);
  const {serviceLabels} = Labels;
  const [responseMessage, setResponseMessage] = useState(null);
  const [openCancelModal, setCancelModal] = useState(false);
  const [openEmailModal, setEmailModal] = useState(false);
  const [openViewEmailsModal, setViewEmailsModal] = useState(false);
  const [openViewInvoiceModal, setViewInvoiceModal] = useState(false);
  const [openSuccessSendAlert, setOpenSuccessSendAlert] = useState(false);
  const [openErrorAlert, setOpenErrorAlert] = useState(false);
  const [jobInfo, setJobInfo] = useState({
    id: jobRequest.jobId,
    userId: jobRequest.userId,
    addressId: jobRequest.addressId,
    status: jobRequest.status,
    serviceType: jobRequest.serviceType,
    customerMessage: jobRequest.customerMessage,
    staffMessage: jobRequest.staffMessage,
    staffId: jobRequest.staffId > 0 ? jobRequest.staffId : null,
    adminMessage: staffNote,
    paymentAmount: jobRequest.paymentAmount,
    invoiceNumber: jobRequest.invoiceNumber
  });
  const [invoice, setInvoice] = useState({
    invoiceNumber: null,
    id: null,
    jobId: null,
    responseMessage: null,
    terms: null,
    invoiceDate: null,
    dueDate: null,
    invoiceMessage: null,
    customerName: null,
    customerEmail: null,
    customerBillingAddress: null,
    subtotal: null,
    totalTax: null,
    totalAmount: null,
    balanceDue: null,
    lines: [],
    emailSent: null
  });

  const api = process.env.REACT_APP_SITE_API;

  // load requested jobs
  const loadStaff = () => {
    const url = APP_URLS.staff;
    axios
        .get(url)
        .then(({data}) => {
          setStaff({
            list: [...data]
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          // eslint-disable-next-line no-console
          console.log(error);
        });
  };

  const handleNoteUpdate = () => {
    const updateInfo = {
      ...jobInfo,
      staffId: jobRequest.staffId,
      adminMessage: staffNote
    };
    JobService.updateJob(updateInfo)
        .then(() => {
          setReload(!reload);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        });
  };

  const handleUpdate = () => {
    const updateInfo = {
      ...jobInfo,
      status: statusUpdate,
      adminMessage: staffNote
    };
    JobService.updateJob(updateInfo)
        .then(() => {
          setOpen(false);
          setReload(!reload);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log('Error: ', error);
        });
    setIsAssigned(true);
  };

  const handleCancel = () => {
    setCancelModal(!openCancelModal);
  };

  const handleEmail = () => {
    setEmailModal(!openEmailModal);
  };

  const handleViewEmails = () => {
    setViewEmailsModal(!openViewEmailsModal);
  };

  const handlePayment = () => {
    const params = new URLSearchParams({'jobId': jobInfo.id})
    history.push({pathname: '/admin/quickbooks/payment', search: params.toString()});
  };

  const handleViewInvoice = async () => {
    const invoiceResponse = await quickbooksService.getInvoice(jobInfo.id)
    if (invoiceResponse.data.responseMessage === "Successfully retrieved invoice") {
      // eslint-disable-next-line prefer-destructuring
      invoiceResponse.data.invoiceDate = invoiceResponse.data.invoiceDate.split('T')[0]
      // eslint-disable-next-line prefer-destructuring
      invoiceResponse.data.dueDate = invoiceResponse.data.dueDate.split('T')[0]
      invoiceResponse.data.subtotal = parseFloat(invoiceResponse.data.subtotal).toFixed(2)
      invoiceResponse.data.totalTax = parseFloat(invoiceResponse.data.totalTax).toFixed(2)
      for (const line of invoiceResponse.data.lines) {
        line.unitPrice = parseFloat(line.unitPrice).toFixed(2)
        line.totalAmount = parseFloat(line.totalAmount).toFixed(2)
      }
      setInvoice(invoiceResponse.data);
      setViewInvoiceModal(!openViewInvoiceModal);
    }
    else {
      setResponseMessage(invoiceResponse.data.responseMessage)
      setOpenErrorAlert(true)
    }
  };

  useEffect(() => {
    if (!staff) {
      loadStaff();
    }
    if (statusUpdate === 4) {
      setIsAssigned(true);
    }
  }, [isAssigned, staff, statusUpdate]);

  return (
      <div>
        <Alert
            type="snackbar"
            snackBar={{
              open: openSuccessSendAlert,
              onClose: () => setOpenSuccessSendAlert(!openSuccessSendAlert)
            }}
            description={responseMessage}
            title="Success!"
            severity="success"
        />
        <Alert
            type="snackbar"
            snackBar={{
              open: openErrorAlert,
              onClose: () => setOpenErrorAlert(!openErrorAlert)
            }}
            description={responseMessage}
            title="Error:"
            severity="error"
        />
        <Grid container>
          <Grid item xs={12} sm={6} className={classes.middleGrid}>
            <div className={globalClasses.detailsTitle}>Customer</div>
            <div className={globalClasses.detailsInfo}>
              {jobRequest.firstName} {jobRequest.lastName}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={globalClasses.detailsTitle}>Address</div>
            {jobRequest.unitNumber ? (
                <div className={globalClasses.detailsInfo}>
                  {jobRequest.unitNumber}, {jobRequest.street}, {jobRequest.city}
                </div>
            ):(
                <div className={globalClasses.detailsInfo}>
                  {jobRequest.street}, {jobRequest.city}
                </div>
            )}
          </Grid>
          <Grid  item xs={12} md={6} sm={12} className={classes.middleGrid}>
            <div className={globalClasses.detailsTitle}>Email</div>
            <div className={globalClasses.detailsInfo}>
              {jobRequest.email}
              <Button
                  onClick={() => handleEmail()}
                  className={classes.emailButton}>
                Send Email
              </Button>
              <SendEmailModal
                  open={openEmailModal}
                  setOpen={setEmailModal}
                  data={jobInfo}
                  user={jobRequest}
                  reload={reload}
                  setReload={setReload}
                  setOpenSuccessSendAlert={setOpenSuccessSendAlert}
                  responseMessage={responseMessage}
                  setResponseMessage={setResponseMessage}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div className={globalClasses.detailsTitle}>Phone</div>
            <div className={globalClasses.detailsInfo}> {jobRequest.phoneNumber}</div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.middleGrid}>
            <div className={globalClasses.detailsTitle}>Service</div>
            <div className={globalClasses.detailsInfo}>{serviceLabels[jobRequest.serviceType].value}</div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.bottomGrid}>
            <div className={globalClasses.detailsTitle}>
              Uploads From Customer ({jobRequest.userImages.length})
            </div>
            {jobRequest.userImages.length !== 0 ? (
                <div>
                  {jobRequest.userImages.map((image) => (
                      <a
                          href={`${api}${image.originalPath}`}
                          target="_blank"
                          rel="noreferrer">
                        <img
                            src={`${api}${image.originalPath}`}
                            alt={`${api}${image.originalPath}`}
                            className={globalClasses.imageHeight}
                        />
                      </a>
                  ))}
                </div>
            ): (
                <div className={`${globalClasses.detailsInfo}`}>No Uploads From Customer</div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={globalClasses.detailsTitle}>Notes From Customer</div>
            <div className={globalClasses.detailsInfo}>{jobRequest.customerMessage}</div>
          </Grid>
            <Grid item xs={12} sm={6} className={`${classes.bottomGrid} ${classes.paddingRight}`}>
              <div className={globalClasses.detailsTitle}>
                Admin Notes
              </div>
              <TextField
                  label="Add Note Here"
                  rows={4}
                  multiline
                  placeholder="This is a note from the Admin to Staff needed to start the service"
                  variant="outlined"
                  className={classes.textField}
                  value={staffNote}
                  defaultValue={jobRequest.adminMessage}
                  onChange={(e) => setStaffNote(e.target.value)}
              />
              {staffNote && (
                    <Box display="flex" justifyContent="flex-end">
                      <Tooltip title="Add">
                        <Fab
                            color="primary"
                            className={classes.addFAB}
                            onClick={() => handleNoteUpdate()}>
                          <Add />
                        </Fab>
                      </Tooltip>
                    </Box>
              )}
            </Grid>
              {(!isAssigned && jobInfo.status !== 4) && (
                  <Grid item xs={12} sm={6} className={classes.bottomGrid}>
                    <div className={globalClasses.detailsTitle}>Select Staff Member:</div>
                    <div>
                      {loading && (
                          <Loading />
                      )}
                      {!loading && (
                          <FormControl className={classes.text}>
                            <InputLabel>Assignees</InputLabel>
                            <Select className={classes.text}>
                              {staff.list.map((member) => (
                                  <MenuItem
                                      value={member}
                                      className={globalClasses.detailsInfo}
                                      key={member.id}
                                      onClick={() => {
                                        setJobInfo((prev) => ({
                                          ...prev,
                                          status: statusUpdate,
                                          staffId: member.staff.id
                                        }))
                                      }
                                      }>
                                    {member.firstName} {member.lastName}
                                  </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                      )}
                    </div>
                  </Grid>
              )}
          {isAssigned && (
              <Grid item xs={12} sm={6} className={classes.bottomGrid}>
                <div>
                  <div className={globalClasses.detailsTitle}>Assigned Staff Member:</div>
                  <div className={globalClasses.detailsInfo}>{jobRequest.staffName}</div>
                </div>
              </Grid>
              )}
          <Grid container justify="space-between">
            <Grid item>
              {(!isAssigned && jobInfo.status !== 4) && (
                  <Button
                      className={`${globalClasses.selectButton} ${globalClasses.modalButtonSize} ${classes.marginTop}`}
                      disabled={!jobInfo.staffId || jobInfo.staffId === 0 }
                      onClick={() => handleUpdate()}>
                    Assign Staff
                  </Button>
              )}
              {isAssigned && (
                  <Button
                      className={`${globalClasses.selectButton} ${globalClasses.modalButtonSize} ${classes.marginTop}`}
                      onClick={() => handleUpdate()}>
                    Complete Job
                  </Button>
              )}
              {(jobInfo.status === 4 && jobInfo.paymentAmount === 0) && (
                  <Button
                      className={`${globalClasses.selectButton} ${globalClasses.modalButtonSize} ${classes.marginTop}`}
                      onClick={() => handlePayment()}>
                    Generate Invoice
                  </Button>
              )}
              {(jobInfo.status === 4 && jobInfo.paymentAmount > 0) && (
                <>
                  <Button
                      className={`${globalClasses.selectButton} ${globalClasses.modalButtonSize} ${classes.marginTop}`}
                      onClick={() => handleViewInvoice()}>
                    View Invoice
                  </Button>
                  <InvoiceDetailsModal
                    invoice={invoice}
                    setInvoice={setInvoice}
                    open={openViewInvoiceModal}
                    setOpen={setViewInvoiceModal}
                    reload={reload}
                    setReload={setReload}
                    job={jobInfo}
                    user={jobRequest}
                  />
                </>
              )}
            </Grid>
            <Grid item>
              {jobRequest.status !== 4 &&
                  <Grid item>
                    <Button
                        className={`${globalClasses.cancelButton} ${classes.marginTop}`}
                        onClick={() => handleCancel()}>
                      Cancel Booking
                    </Button>
                    <CancelModal
                        open={openCancelModal}
                        setOpen={setCancelModal}
                        data={jobInfo}
                        reload={reload}
                        setReload={setReload}
                    />
                  </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </div>
  );
};
export default UserDetails;
