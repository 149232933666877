import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';

const BookingAddress = ({ formField: { address }, formProps }) => (
  <div>
    <Typography variant="h6" gutterBottom>
      Job Information
    </Typography>

    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          multiline
          rows={3}
          label={address.street.label}
          name={address.street.name}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          value={formProps.values.street}
          error={formProps.touched.street && Boolean(formProps.errors.street)}
          helperText={formProps.touched.street && formProps.errors.street}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={address.city.label}
          name={address.city.name}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          value={formProps.values.city}
          error={formProps.touched.city && Boolean(formProps.errors.city)}
          helperText={formProps.touched.city && formProps.errors.city}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={address.unitNumber.label}
          name={address.unitNumber.name}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          value={formProps.values.unitNumber}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={address.postalCode.label}
          name={address.postalCode.name}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          value={formProps.values.postalCode}
          error={
            formProps.touched.postalCode && Boolean(formProps.errors.postalCode)
          }
          helperText={
            formProps.touched.postalCode && formProps.errors.postalCode
          }
          fullWidth
        />
      </Grid>
    </Grid>
  </div>
);
export default BookingAddress;
