import React, {useState} from 'react';
import {MenuItem, Select, TableCell, TableRow, TextField} from '@material-ui/core';
import enums from "../../../../utils/enums";
import Button from "../../../common/Button";
import globalUseStyles from "../../../../utils/theme/styleHooks";

const taxOptions = [
    { id: 1, name: 'GST'},
    { id: 2, name: 'PST BC'},
    { id: 3, name: 'GST/PST BC'}
]


const EditableInvoiceListItem = ({request, onChange, removeLine}) => {
    const [openDetails, setOpenDetails] = useState(false);
    const globalClasses = globalUseStyles();

    const handleChange = (event) => {
        onChange({
            ...request,
            [event.target.name]: event.target.value
        })
    };

    const handleChangeName = (event) => {
        onChange({
            ...request,
            name: event.target.value.name,
            description: event.target.value.description
        });
    };

    return (
        <TableRow key={request.id} style={{ alignItems: 'center' }}>
            <TableCell component="th" scope="row" style={{ width: '1.5rem' }}>
                <TextField
                    value={request.id}
                    name='id'
                />
            </TableCell>
            <TableCell align="left" style={{ width: '8rem' }}>
                <Select
                    label="Name"
                    name="name"
                    onChange={handleChangeName}
                    value={request}
                    renderValue={(displayValue) => displayValue.name}
                    type="text"
                    fullWidth
                >
                    {enums.lineItems.map((item) => (
                        <MenuItem value={item} >{item.name}</MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell align="left">
                <TextField
                    value={request.description}
                    multiline
                    fullWidth
                    onChange={handleChange}
                    name='description'
                />
            </TableCell>
            <TableCell align="right" style={{ width: '1rem' }}>
                <TextField
                    value={request.quantity}
                    onChange={handleChange}
                    name='quantity'
                />
            </TableCell>
            <TableCell align="right" style={{ width: '5rem' }}>
                <TextField
                    value={request.unitPrice}
                    onChange={handleChange}
                    name='unitPrice'
                />
            </TableCell>
            <TableCell align="right" style={{ width: '5rem' }}>
                <TextField
                    value={request.totalAmount}
                    onChange={handleChange}
                    name='totalAmount'
                    InputProps={{readOnly: true}}
                    disabled
                />
            </TableCell>
            <TableCell align="left" style={{ width: '7.1rem' }}>
                <Select
                    label="Tax Code"
                    name="taxCode"
                    onChange={handleChange}
                    value={request.taxCode}
                    type="text"
                    fullWidth
                >
                    {taxOptions.map((item) => (
                        <MenuItem value={item.name} >{item.name}</MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell align="right" style={{ width: '5rem' }}>
                <Button
                    className={globalClasses.clearButton}
                    onClick={() => removeLine(request.id)}
                >
                    Remove
                </Button>
            </TableCell>
        </TableRow>
    );
};
export default EditableInvoiceListItem;
