import React from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core';

// Local Imports
import UpcomingJobsListItem from './ListItem';

const UpcomingJobs = ({ staff, reload, setReload }) => (
  <TableContainer component={Paper}>
    <Table aria-label="upcoming jobs table">
      <TableHead>
        <TableRow>
          <TableCell>Job #</TableCell>
          <TableCell align="right">Service</TableCell>
          <TableCell align="right">Address</TableCell>
          <TableCell align="right">Customer Note</TableCell>
          <TableCell align="right">Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {staff.jobs.map(
          (request) =>
            request.status !== 4 && (
              <UpcomingJobsListItem
                job={request}
                reload={reload}
                setReload={setReload}
              />
            )
        )}
      </TableBody>
    </Table>
  </TableContainer>
);
export default UpcomingJobs;
