import React, { useState } from 'react';
import {
  Fab,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import { Add } from '@material-ui/icons';

// Local Imports
import TabPanel from 'components/common/TabPanel';
import AddAddress from '../modals/AddAddressModal';
import ArchivedAddressList from './ArchivedAddresses';
import CurrentAddresses from './CurrentAddresses';

const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  divider: {
    marginTop: '30px'
  }
}));

const UserAddressList = ({ user, reload, setReload }) => {
  const classes = useStyles();
  const [openAdd, setOpenAdd] = useState(false);
  const [value, setValue] = useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => ({
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`
  });

  return (
    <Grid className={classes.divider}>
      <Grid item>
        <div className={classes.heading}>
          <Typography variant="h6" gutterBottom>
            Addresses
          </Typography>
          <Fab
            color="primary"
            variant="extended"
            onClick={() => setOpenAdd(!openAdd)}>
            <Add className={classes.extendedIcon} />
            Add Address
          </Fab>
        </div>
        <AddAddress
          open={openAdd}
          setOpen={setOpenAdd}
          user={user}
          reload={reload}
          setReload={setReload}
        />
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="wrapped label tabs example">
          <Tab
            value="one"
            label="Current Address(es)"
            wrapped
            {...a11yProps('one')}
          />
          <Tab value="two" label="Archived Address(es)" {...a11yProps('two')} />
        </Tabs>
        <TabPanel value={value} index="one">
          <CurrentAddresses user={user} reload={reload} setReload={setReload} />
        </TabPanel>
        <TabPanel value={value} index="two">
          <ArchivedAddressList
            user={user}
            reload={reload}
            setReload={setReload}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default UserAddressList;
