import React, { useState } from 'react';
import {
  FormControl,
  Grid,
  makeStyles,
  Card,
  FormGroup,
  Typography,
  Link,
  TextField
} from '@material-ui/core';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';

// Local Imports
import Button from 'components/common/Button';
import globalUseStyles from 'utils/theme/styleHooks';
import AuthService from '../../services/auth.service';
import Alert from '../common/Alert';
import Loading from '../common/Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '50px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px'
    }
  },
  button: {
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  subtitle: {
    fontSize: '14pt',
    marginTop: '30px',
    marginBottom: '20px'
  },
  card: {
    padding: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(6)
    }
  }
}));

const Login = () => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const history = useHistory();
  const [credentials, setCredentials] = useState({
    email: null,
    password: null
  });

  const handleChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleNavigation = (res) => {
    switch (res.roles[0]) {
      case 'Admin':
        history.push('/admin');
        break;
      case 'Staff':
        history.push(`/my-jobs`);
        break;
      case 'Customer':
        history.push(`/my-bookings`);
        break;
      default:
        break;
    }
  };

  const handleLogin = (event) => {
    event.preventDefault();
    AuthService.login(credentials.email, credentials.password)
      .then((res) => {
        setLoading(true);
        handleNavigation(res);
      })
      .catch((error) => {
        setOpenAlert(true);
        setLoading(false);
        // eslint-disable-next-line no-console
        console.log('errors', error);
      });
  };

  const schema = yup.object({
    email: yup
      .string('Enter your email')
      .email('Email must be correct')
      .required('Email can not be empty'),
    password: yup
      .string('Enter your password')
      .min(8, 'Can not less than 4 characters')
  });

  return (
    <Grid container className={classes.root}>
      <Alert
        type="snackbar"
        snackBar={{
          open: openAlert,
          onClose: () => setOpenAlert(!openAlert)
        }}
        description="Username or password are incorrect"
        title="Error"
        severity="error"
      />
      <Grid item sm={12} md={8} lg={6} xl={4}>
        <Card className={classes.card}>
          <div className={globalClasses.titleSize}>
            <Typography variant="h3">Log-In To Your Account</Typography>
          </div>
          <div className={classes.subtitle}>
            Log-in to view and manage your account.
          </div>
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            onSubmit={handleLogin}
            validationSchema={schema}>
            {({ errors, touched }) => (
              <form>
                <FormGroup>
                  <FormControl>
                    <TextField
                      label="Email"
                      name="email"
                      onChange={handleChange}
                      value={credentials.email}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      autoFocus
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      type="password"
                      label="Password"
                      name="password"
                      onChange={handleChange}
                      value={credentials.password}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </FormControl>
                  <Link
                    to="/forgot-password"
                    className={classes.button}
                    component={RouterLink}>
                    Forgot my password
                  </Link>
                </FormGroup>
                <Grid container justify="center" className={classes.button}>
                  <Grid item>
                    {!loading && (
                      <Button type="submit" onClick={handleLogin}>
                        Log In
                      </Button>
                    )}
                    {loading && <Loading />}
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  );
};
export default Login;
