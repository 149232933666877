import React from 'react';
import { Button as MUIButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  button: {
    margin: '5px',
    color: '#fc4b3c',
    borderColor: '#fc4b3c',
    '&:hover': {
      backgroundColor: '#fc4b3c',
      color: '#fff',
      borderColor: '#ff8469'
    }
  }
}));

const Button = (props) => {
  const classes = useStyles();
  const { variant, children, className, icon, ...otherProps } = props;
  const styles = className || classes.button;
  return (
    <>
      <MUIButton
        variant={variant}
        startIcon={icon}
        className={styles}
        {...otherProps}>
        {children}
      </MUIButton>
    </>
  );
};

export default Button;
