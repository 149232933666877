import React, { useState } from 'react';
import { Modal, Grid, Card, TextField, DialogActions } from '@material-ui/core';
import * as yup from 'yup';

// Local Imports
import globalUseStyles from 'utils/theme/styleHooks';
import { Formik } from 'formik';
import Button from 'components/common/Button';
import Alert from 'components/common/Alert';
import AddressService from 'services/address.service';

const EditAddressModal = ({ open, setOpen, address, reload, setReload }) => {
  const globalClasses = globalUseStyles();
  const [openAlert, setOpenAlert] = useState(false);

  const schema = yup.object({
    street: yup.string().required('is required'),
    postalCode: yup.string().required('is required'),
    city: yup.string().required('is required')
  });

  const handleUpdate = (data) => {
    const updateAddress = {
      id: address.id,
      userId: address.userId,
      street: data.street,
      city: data.city,
      unitNumber: data.unitNumber,
      postalCode: data.postalCode,
      province: address.province,
      country: address.country,
      createdAt: address.createdAt
    };
    AddressService.updateAddress(updateAddress)
      .then(() => {
        setOpenAlert(true);
        setReload(!reload);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <>
      <Alert
        description="Address edit successfully"
        severity="success"
        type="snackbar"
        snackBar={{
          open: openAlert,
          onClose: () => setOpenAlert(!openAlert)
        }}
      />
      <Modal
        className={globalClasses.modalRoot}
        open={open}
        onClose={() => setOpen(false)}>
        <Card className={globalClasses.modal}>
          <Formik
            initialValues={{
              street: address.street,
              unitNumber: address.unitNumber,
              postalCode: address.postalCode,
              city: address.city
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              setOpenAlert(!openAlert);
              // eslint-disable-next-line no-console
              setTimeout(() => {
                setOpen(false);
              }, 1000);
            }}>
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label="Address"
                      name="street"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.street}
                      error={touched.street && Boolean(errors.street)}
                      helperText={touched.street && errors.street}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="City"
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      error={touched.city && Boolean(errors.city)}
                      helperText={touched.city && errors.city}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Unit (if applicable)"
                      name="unitNumber"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.unitNumber}
                      error={touched.unitNumber && Boolean(errors.unitNumber)}
                      helperText={touched.unitNumber && errors.unitNumber}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Postal Code"
                      name="postalCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postalCode}
                      error={touched.postalCode && Boolean(errors.postalCode)}
                      helperText={touched.postalCode && errors.postalCode}
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button onClick={() => setOpen(false)} color="primary">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="secondary"
                    autoFocus
                    onClick={() => handleUpdate(values)}>
                    Confirm Edit
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Card>
      </Modal>
    </>
  );
};
export default EditAddressModal;
