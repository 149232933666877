import React, {useState} from 'react';
import {
  Button,
  Grid,
  makeStyles
} from '@material-ui/core';

// local imports
import globalUseStyles from 'utils/theme/styleHooks';
import SendEmailModal from "../SendEmailModal/SendEmailModal";
import Alert from "../../../common/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: theme.spacing(4)
  },
  addFAB: {
    margin: theme.spacing(-2, -2, 0, 0),
    height: 25,
    width: 35
  },
  card: {
    width: '60%',
    padding: theme.spacing(4)
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  image: {
    margin: 'auto'
  },
  images: {
    height: '100%',
    width: '150px',
    margin: theme.spacing(2),
    padding: theme.spacing(4)
  },
  info: {
    fontSize: '12pt',
    textAlign: 'center'
  },
  tableRow: {
    align: 'right'
  },
  textField: {
    width: '100%',
    margin: theme.spacing(1, 0, 0, 0)
  },
  text: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  middleGrid: {
    marginBottom:'2%'
  },
  bottomGrid: {
    marginBottom:'2%'
  },
  marginRight: {
    marginRight: "15px"
  },
  paddingRight: {
    paddingRight: "30px"
  },
  marginBottom: {
    marginBottom: '80px'
  },
  marginTop: {
    marginTop: '20px'
  },
  emailButton: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.tertiary.text,
    borderColor: theme.palette.tertiary.text,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.tertiary.text,
      borderColor: theme.palette.tertiary.text,
      borderWidth: '1px'
    }
  },
  viewEmailButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.tertiary.text,
    borderColor: theme.palette.tertiary.text,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.tertiary.text,
      borderColor: theme.palette.tertiary.text,
      borderWidth: '1px'
    }
  }
}));

SendEmailModal.openSuccessSendAlert = false;
const EmailDetails = ({
  emailRequest,
  setOpen,
  reload,
  setReload,
  job,
  user
}) => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const [responseMessage, setResponseMessage] = useState(null);
  const [openEmailModal, setEmailModal] = useState(false);
  const [openSuccessSendAlert, setOpenSuccessSendAlert] = useState(false);
  const [emailInfo] = useState({
    id: emailRequest.id,
    jobId: emailRequest.jobId,
    from: emailRequest.from,
    to: emailRequest.to,
    subject: emailRequest.subject,
    body: emailRequest.body,
    dateReceived: emailRequest.dateReceived
  });
  const [jobInfo] = useState({
    id: job.jobId,
    userId: job.userId,
    addressId: job.addressId,
    status: job.status,
    serviceType: job.serviceType,
    customerMessage: job.customerMessage,
    staffMessage: job.staffMessage,
    staffId: job.staffId > 0 ? job.staffId : null,
    adminMessage: job.adminMessage,
    paymentAmount: job.paymentAmount
  });
  const [userInfo] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email
  });

  const handleEmail = () => {
    setEmailModal(!openEmailModal);
  };

  return (
      <div>
        <Alert
            type="snackbar"
            snackBar={{
              open: openSuccessSendAlert,
              onClose: () => setOpenSuccessSendAlert(!openSuccessSendAlert)
            }}
            description={responseMessage}
            title="Success!"
            severity="success"
        />
        <Grid container>
          <Grid item xs={12} className={classes.middleGrid}>
            <div className={globalClasses.detailsTitle}>From:</div>
            <div className={globalClasses.detailsInfo}>
              {emailInfo.from}
            </div>
          </Grid>
          <Grid item xs={12} className={classes.middleGrid}>
            <div className={globalClasses.detailsTitle}>To:</div>
                <div className={globalClasses.detailsInfo}>
                  {emailInfo.to}
                </div>
          </Grid>
          <Grid item xs={12} className={classes.middleGrid}>
            <div className={globalClasses.detailsTitle}>Date Received:</div>
            <div className={globalClasses.detailsInfo}>
              {emailInfo.dateReceived}
            </div>
          </Grid>
          <Grid  item xs={12} className={classes.middleGrid}>
            <div className={globalClasses.detailsTitle}>Subject</div>
            <div className={globalClasses.detailsInfo}>
              {emailInfo.subject}
            </div>
          </Grid>

          <Grid item xs={12} className={classes.middleGrid}>
            <div className={globalClasses.detailsInfo}> {emailInfo.body}</div>
          </Grid>
          <Button
              onClick={() => setOpen(false)}
          >
            Close
          </Button>
        <Button
            onClick={() => handleEmail()}
            className={classes.emailButton}>
          Reply to Email
        </Button>
        <SendEmailModal
            open={openEmailModal}
            setOpen={setEmailModal}
            data={jobInfo}
            user={userInfo}
            reload={reload}
            setReload={setReload}
            setOpenSuccessSendAlert={setOpenSuccessSendAlert}
            responseMessage={responseMessage}
            setResponseMessage={setResponseMessage}
        />
        </Grid>
      </div>
  );
};
export default EmailDetails;
