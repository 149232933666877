import React, { useState, Fragment } from 'react';
import { Card, Fab, Grid, makeStyles, Typography } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

// Local Imports
import EditUser from './modals/EditUserModal';
import UserAddressList from './addresses';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  underCard: {
    margin: theme.spacing(4)
  },
  overCard: {
    padding: theme.spacing(2)
  },
  fab: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const UserAccount = ({ user, reload, setReload }) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);

  return (
    <Grid container className={classes.root}>
      <Card className={classes.underCard}>
        <EditUser
          open={isEdit}
          setOpen={setIsEdit}
          user={user}
          reload={reload}
          setReload={setReload}
        />
        <Grid item xs={12}>
          <Card className={classes.overCard}>
            <div className={classes.fab}>
              <Typography variant="h6" gutterBottom>
                User details
              </Typography>
              <Fab
                variant="extended"
                aria-label="Edit Address"
                onClick={() => setIsEdit(true)}
                color="primary">
                <Edit style={{ marginRight: '5px' }} />
                Edit
              </Fab>
            </div>

            <Grid item container direction="column" xs={12} sm={6} md={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography gutterBottom>First Name:</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography gutterBottom variant="button">
                    {user.firstName}
                  </Typography>
                </Grid>
                <Fragment key={user.lastName}>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Last Name:</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="button">
                      {user.lastName}
                    </Typography>
                  </Grid>
                </Fragment>
                <Fragment key={user.number}>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Phone Number:</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="button">
                      {user.phoneNumber}
                    </Typography>
                  </Grid>
                </Fragment>
                <Fragment key={user.email}>
                  <Grid item xs={6}>
                    <Typography gutterBottom>Email:</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography gutterBottom variant="button">
                      {user.email}
                    </Typography>
                  </Grid>
                </Fragment>
              </Grid>
            </Grid>
            <UserAddressList
              user={user}
              reload={reload}
              setReload={setReload}
            />
          </Card>
        </Grid>
      </Card>
    </Grid>
  );
};
export default UserAccount;
