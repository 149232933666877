import axios from 'axios';
import jwtDecode from 'jwt-decode';

import { APP_URLS } from '../urls';

const register = (email, password) =>
  axios.post(APP_URLS.login, {
    email,
    password
  });

const login = (email, password) =>
  axios
    .post(APP_URLS.login, {
      email,
      password
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem(
          'accessToken',
          JSON.stringify(response.data.accessToken)
        );
        localStorage.setItem('email', JSON.stringify(response.data.email));
        localStorage.setItem('id', JSON.stringify(response.data.id));
      }

      return response.data;
    });

const getCurrentToken = () => JSON.parse(localStorage.getItem('accessToken'));

const getCurrentId = () => JSON.parse(localStorage.getItem('id'));

const authenticate = () => {
  const accessToken = getCurrentToken();

  if (!accessToken) {
    return false;
  }

  const token = jwtDecode(accessToken);
  return token.exp > Date.now().valueOf() / 1000;
};

const logout = () => {
  localStorage.clear();
};

export default {
  register,
  login,
  authenticate,
  logout,
  getCurrentToken,
  getCurrentId
};
