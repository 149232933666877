import React from 'react';
import { Switch } from 'react-router-dom';

// Public Components
import LandingPage from './public/LandingPage';
import AboutUs from './public/AboutUs';
import Services from './public/Services';
import Contact from './public/Contact';
import Login from './public/Login';
import ForgotPassword from "./public/ForgotPassword";
import ResetPassword from "./public/ResetPassword";

// Admin Components
import AdminDashboard from './admin/AdminDashboard';
import QuickbooksPayment from "./admin/quickbooks/QuickbooksPayment";

// User Components
import UserDashboard from './user/UserDashboard';
import UserAccount from './user/Account';

// Staff Components
import StaffDashboard from './staff';

// Scheduling Components
import Schedule from './scheduling/Schedule';
import BookingForm from './scheduling/BookingScheduleForm';

// Routes
import StandardRoute from './routes/StandardRoute';
import ProtectedAdminRoute from './routes/ProtectedAdminRoute';
import ProtectedUserRoute from './routes/ProtectedUserRoute';
import ProtectedStaffRoute from './routes/ProtectedStaffRoute';
import QuickbooksAuth from "./admin/quickbooks/QuickbooksAuth";

const Main = () => (
  <Switch>
    <StandardRoute path="/" exact component={LandingPage} />
    <StandardRoute path="/about" component={AboutUs} />
    <StandardRoute path="/services" component={Services} />
    <StandardRoute path="/contact" component={Contact} />
    <StandardRoute path="/schedule" component={Schedule} />
    <StandardRoute path="/login" component={Login} />
    <StandardRoute path="/signup-booking" component={BookingForm} />
    <StandardRoute path="/forgot-password" component={ForgotPassword} />
    <StandardRoute path="/account/resetpassword/(.*)" component={ResetPassword} />
    <ProtectedAdminRoute path="/admin" exact component={AdminDashboard} />
    <ProtectedAdminRoute path="/admin/quickbooks/success" component={QuickbooksAuth}/>
    <ProtectedAdminRoute path="/admin/quickbooks/payment" component={QuickbooksPayment}/>
    <ProtectedUserRoute path="/my-bookings" component={UserDashboard} />
    <ProtectedUserRoute path="/user-account" component={UserAccount} />
    <ProtectedStaffRoute path="/my-jobs" component={StaffDashboard} />
  </Switch>
);

export default Main;
