import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Labels from '../common/Labels';

const ReviewBookingDetails = ({ data }) => {
  const { serviceLabels } = Labels;
  const {
    firstName,
    lastName,
    email,
    street,
    phoneNumber,
    city,
    postalCode,
    unitNumber,
    serviceType,
    customerMessage,
    images
  } = data;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {/* Booking summary */}
        Summary
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="button">Name</Typography>
        </Grid>
        <Grid item xs={6}>
          {`${firstName} ${lastName}`}
        </Grid>
        {/*  */}
        <Grid item xs={6}>
          <Typography variant="button">Email</Typography>
        </Grid>
        <Grid item xs={6}>
          {email}
        </Grid>
        {/*  */}
        <Grid item xs={6}>
          <Typography variant="button">Phone</Typography>
        </Grid>
        <Grid item xs={6}>
          {phoneNumber}
        </Grid>
        {/*  */}
        <Grid item xs={6}>
          <Typography variant="button">Address</Typography>
        </Grid>
        <Grid item xs={6} wrap="nowrap">
          {street}
        </Grid>
        {/*  */}
        <Grid item xs={6}>
          <Typography variant="button">City</Typography>
        </Grid>
        <Grid item xs={6}>
          {city}
        </Grid>
        {/*  */}
        <Grid item xs={6}>
          <Typography variant="button">Postal Code</Typography>
        </Grid>
        <Grid item xs={6}>
          {postalCode}
        </Grid>
        {/*  */}
        <Grid item xs={6}>
          <Typography variant="button">Unit#</Typography>
        </Grid>
        <Grid item xs={6}>
          {unitNumber || '---'}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="button">Service Type</Typography>
        </Grid>
        <Grid item xs={6}>
          {serviceLabels[serviceType].value || '---'}
        </Grid>
        {/*  */}
        <Grid item xs={6}>
          <Typography variant="button">Additional Information</Typography>
        </Grid>
        <Grid item xs={6}>
          {customerMessage || '---'}
        </Grid>
        {/*  */}
        <Grid item xs={6}>
          <Typography variant="button">Images</Typography>
        </Grid>
        {images ? (
          <Grid item xs={6}>
            {images.map((file) => (
              <li key={file.name}>
                {`File:${file.name} Type:${file.type} Size:${file.size} bytes`}{' '}
              </li>
            ))}
          </Grid>
        ) : (
          <Grid item xs={6}>
            ---
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ReviewBookingDetails;
