import * as yup from 'yup';
import BookingInfoModal from './BookingInfoModal';

const {
  formField: {
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    confirmPassword,
    address,
    serviceType,
    customerMessage,
      images
  }
} = BookingInfoModal;

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default [
  yup.object({
    [firstName.name]: yup
      .string('Can not be less than 3')
      .min(3)
      .required('Enter your first name'),
    [lastName.name]: yup
      .string('Can not be less than 3')
      .min(3)
      .required('Enter your last name'),
    [phoneNumber.name]: yup
      .string()
      .matches(phoneRegExp, 'Phone number is not valid')
      .required('is Required'),
    [email.name]: yup
      .string('Enter your email')
      .email('Enter a valid email')
      .required('Email is required'),
    [password.name]: yup
      .string('Enter your password')
      .min(8, 'Password should be of minimum 8 characters length')
      .required('Password is required'),
    [confirmPassword.name]: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  }),
  yup.object({
    [address.street.name]: yup.string().required('Street is required'),
    [address.city.name]: yup.string().required('City is required'),
    [address.postalCode.name]: yup.string().required('Postal is required')
  }),
  yup.object({
    [serviceType.name]: yup.string().required('Service type is required'),
    [customerMessage.name]: yup
        .string()
        .required('Additional info can not be empty'),
    [images.name]: yup.array()
  })
];
