/* eslint-disable import/prefer-default-export */
const BASE_URL = `${process.env.REACT_APP_SITE_API}/api`;

// Urls
const JOB_URL = `${BASE_URL}/Job`;
export const APP_URLS = {
  address: `${BASE_URL}/Address`,
  job: `${BASE_URL}/Job`,
  quickbooks: `${BASE_URL}/Quickbooks`,
  userAccount: `${JOB_URL}/user`,
  staffAccount: `${JOB_URL}/staff`,
  jobStatus: {
    new: `${JOB_URL}/status?status=0`,
    pending: `${JOB_URL}/status?status=1`,
    inProgress: `${JOB_URL}/status?status=2`,
    lost: `${JOB_URL}/status?status=3`,
    completed: `${JOB_URL}/status?status=4`
  },
  userImages: `${BASE_URL}/JobUserImage`,
  staffImages: `${BASE_URL}/JobStaffImage`,
  staff: `${BASE_URL}/Staff`,
  archivedStaff: `${BASE_URL}/Staff/archived`,
  user: `${BASE_URL}/User`,
  customers: `${BASE_URL}/User/customer`,
  archivedCustomers: `${BASE_URL}/User/archived`,
  login: `${BASE_URL}/Auth`,
  tokenCheck: `${BASE_URL}/Auth/token/check`,
  captcha: `${BASE_URL}/Captcha`,
  forgotPassword: `${BASE_URL}/forgot-password`,
  resetPassword: `${BASE_URL}/account/resetpassword/(.*)`,
  account: `${BASE_URL}/Account`
};
