/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import axios from 'axios';
import {Button, Modal, Grid, Card, TextField, DialogActions} from '@material-ui/core';

// Local Imports
import { APP_URLS } from 'urls';
import globalUseStyles from 'utils/theme/styleHooks';
import { Formik } from 'formik';

const EditStaff = ({ open, setOpen, member }) => {
  const globalClasses = globalUseStyles();
  const [staff] = useState({ ...member });

  const handleUpdate = (event, values) => {
    event.preventDefault();
    axios
      .put(`${APP_URLS.user}/${staff.staff.userId}`, {
        id: staff.staff.userId,
        firstName: values.firstName,
        lastName: values.lastName,
        email: staff.email,
        phoneNumber: staff.phoneNumber
      })
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log('Response: ', res);
        setOpen(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Error: ', error);
      });
    axios
      .put(`${APP_URLS.staff}/update`, {
        ...staff.staff,
        title: values.title,
        jobsAssigned: values.jobsAssigned,
        activeJobs: values.activeJobs,
        jobsCompleted: values.jobsCompleted
      })
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log('Response: ', res);
        setOpen(false);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log('Error: ', error);
      });
  };

  return (
    <Modal
      className={globalClasses.modalRoot}
      open={open}
      onClose={() => setOpen(false)}>
        <Grid container xs={6}>
      <Card className={globalClasses.modal}>
        <Formik
          initialValues={{
            firstName: staff.firstName,
            lastName: staff.lastName,
            title: staff.staff.title,
            jobsAssigned: staff.staff.jobsAssigned,
            activeJobs: staff.staff.activeJobs,
            jobsCompleted: staff.staff.jobsCompleted
          }}
          onSubmit={handleUpdate}>
          {({ handleChange, handleBlur, touched, values, errors }) => (
            <form onSubmit={(event) => handleUpdate(event, values)}>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            label="First Name"
                            name="firstName"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.firstName}
                            error={touched.firstName && Boolean(errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Last Name"
                            name="lastName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.lastName}
                            error={touched.lastName && Boolean(errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Title"
                            name="title"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.title}
                            error={touched.title && Boolean(errors.title)}
                            helperText={touched.title && errors.title}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Jobs Assigned"
                            name="jobsAssigned"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.jobsAssigned}
                            error={touched.jobsAssigned && Boolean(errors.jobsAssigned)}
                            helperText={touched.jobsAssigned && errors.jobsAssigned}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Active Jobs"
                            name="activeJobs"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.activeJobs}
                            error={touched.activeJobs && Boolean(errors.activeJobs)}
                            helperText={touched.activeJobs && errors.activeJobs}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Jobs Completed"
                            name="jobsCompleted"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.jobsCompleted}
                            error={
                                touched.jobsCompleted && Boolean(errors.jobsCompleted)
                            }
                            helperText={touched.jobsCompleted && errors.jobsCompleted}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Grid container justify="space-between">
                        <Button
                            className={globalClasses.clearButton}
                            onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button className={globalClasses.selectButton} type="submit">
                            Confirm Edit
                        </Button>
                    </Grid>
                </DialogActions>
            </form>
          )}
        </Formik>
      </Card>
        </Grid>
    </Modal>
  );
};
export default EditStaff;
