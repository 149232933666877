import React from 'react';
import { Route } from 'react-router-dom';

// Local imports
import PublicLayout from '../layout/PublicLayout';

const StandardRoute = (children) => (
  <PublicLayout>
    <Route {...children} />
  </PublicLayout>
);
export default StandardRoute;
