import axios from 'axios';
import AuthService from 'services/auth.service';
import authHeader from './auth-header';
import { APP_URLS } from '../urls';

const loadCustomers = () =>
  axios.get(APP_URLS.customers, { headers: authHeader() });

const loadArchivedCustomers = () =>
  axios.get(APP_URLS.archivedCustomers, { headers: authHeader() });

const archiveUser = (id) =>
  axios.put(`${APP_URLS.user}/archive/${id}`, { headers: authHeader() });

const restoreUser = (id) =>
  axios.put(`${APP_URLS.user}/restore/${id}`, { headers: authHeader() });

const loadUser = () => axios.get(APP_URLS.user, { headers: authHeader() });

const loadUserAccount = (id) =>
  axios.get(`${APP_URLS.userAccount}/${id}`, { headers: authHeader() });

const loadStaffAccount = (id) =>
  axios.get(`${APP_URLS.staffAccount}/${id}`, { headers: authHeader() });

const archiveStaff = (id) =>
  axios.put(`${APP_URLS.staff}/archive/${id}`, { headers: authHeader() });

const restoreStaff = (id) =>
  axios.put(`${APP_URLS.staff}/restore/${id}`, { headers: authHeader() });

const updateUser = (user) =>
  axios.put(`${APP_URLS.user}/${AuthService.getCurrentId()}`, user, {
    headers: authHeader()
  });

const updateStaff = (staff) =>
  axios.put(`${APP_URLS.staff}/update`, staff, {
    headers: authHeader()
  });

const sendEmail = (id, subject, body) =>
    axios.post(`${APP_URLS.user}/sendEmail`, {jobId: id, subject, body},{ headers: authHeader() });

const sendForgotPassword = async (email) =>
    axios.post(`${APP_URLS.account}/sendResetEmail/${email}`);

const updatePassword = async (requestBody) =>
    axios.post(`${APP_URLS.account}/updatePassword`, requestBody)

export default {
  loadCustomers,
  loadArchivedCustomers,
  archiveUser,
  restoreUser,
  loadUser,
  loadUserAccount,
  loadStaffAccount,
  updateUser,
  archiveStaff,
  restoreStaff,
  updateStaff,
  sendEmail,
  sendForgotPassword,
  updatePassword
};
