import React from 'react';
import { Grid, Typography, TextField } from '@material-ui/core';

const BookingUserDetails = ({
  formField: {
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    confirmPassword
  },
  formProps
}) => (
  <div>
    <Typography variant="h6" gutterBottom>
      User Information
    </Typography>
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          label={firstName.label}
          name={firstName.name}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          value={formProps.values.firstName}
          error={
            formProps.touched.firstName && Boolean(formProps.errors.firstName)
          }
          helperText={formProps.touched.firstName && formProps.errors.firstName}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label={lastName.label}
          name={lastName.name}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          value={formProps.values.lastName}
          error={
            formProps.touched.lastName && Boolean(formProps.errors.lastName)
          }
          helperText={formProps.touched.lastName && formProps.errors.lastName}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={email.label}
          name={email.name}
          placeholder="example@gmail.com"
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          value={formProps.values.email}
          error={formProps.touched.email && Boolean(formProps.errors.email)}
          helperText={formProps.touched.email && formProps.errors.email}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={phoneNumber.label}
          name={phoneNumber.name}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          value={formProps.values.phoneNumber}
          error={
            formProps.touched.phoneNumber &&
            Boolean(formProps.errors.phoneNumber)
          }
          helperText={
            formProps.touched.phoneNumber && formProps.errors.phoneNumber
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          type="hidden"
          // type="password"
          // label={password.label}
          name={password.name}
          // onChange={formProps.handleChange}
          // onBlur={formProps.handleBlur}
          // value={formProps.values.password}
          value="LonsdaleUser11*"
          // error={
          //   formProps.touched.password && Boolean(formProps.errors.password)
          // }
          // helperText={formProps.touched.password && formProps.errors.password}
          // fullWidth
          className="removeLine"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          type="hidden"
          // type="password"
          // label={confirmPassword.label}
          name={confirmPassword.name}
          // onChange={formProps.handleChange}
          // onBlur={formProps.handleBlur}
          // value={formProps.values.confirmPassword}
          value="LonsdaleUser11*"
          // error={
          //   formProps.touched.confirmPassword &&
          //   Boolean(formProps.errors.confirmPassword)
          // }
          // helperText={
          //   formProps.touched.confirmPassword &&
          //   formProps.errors.confirmPassword
          // }
          // fullWidth
          className="removeLine"
        />
      </Grid>
    </Grid>
  </div>
);
export default BookingUserDetails;
