import React, {useState} from 'react';
import {TableCell, TableRow} from '@material-ui/core';

// local imports
// eslint-disable-next-line import/no-cycle
import Details from '../../modals/DetailsModal';
import Labels from '../../../common/Labels';

const CompletedListItem = ({request}) => {
    const [openDetails, setOpenDetails] = useState(false);
    const {serviceLabels} = Labels;
    const nextStatus = 2;

    return (
        <TableRow key={request.id}>
            <TableCell component="th" scope="row">
                #{request.jobId}
            </TableCell>
            <TableCell align="right">
                {serviceLabels[request.serviceType].value}
            </TableCell>
            <TableCell align="right">{request.staffName}</TableCell>
            <TableCell align="right">
                {request.firstName} {request.lastName}
            </TableCell>
            <TableCell align="right">
                <Details
                    jobRequest={request}
                    open={openDetails}
                    setOpen={setOpenDetails}
                    statusUpdate={nextStatus}
                />
            </TableCell>
        </TableRow>
    );
};
export default CompletedListItem;
