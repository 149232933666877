import React from 'react';
import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';

// Local Imports
import WhoWeAre from 'assets/who_we_are.png';
import WhatWeDo from 'assets/what_we_do.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  imageBox: {
    padding: '10px 10px 0px 10px'
  },
  textBox: {
    padding: '40px 40px 0px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 0px'
    }
  },
  paragraph: {
    textAlign: 'right',
    paddingLeft: '80px',
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '20pt'
    }
  },
  paragraphBottom: {
    textAlign: 'left',
    paddingRight: '80px',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '20pt'
    }
  },
  title: {
    margin: theme.spacing(-6, 0, 0, 0)
  },
  whoWeAreImg: {
    width: '60%',
    height: '400px',
    borderRadius: '10px',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      height: '300px'
    }
  },
  WhatWeDoImg: {
    width: '60%',
    height: '400px',
    borderRadius: '10px',
    float: 'right',
    objectFit: 'cover',
    margin: '80px 0px 0px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '40px 0px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '300px'
    }
  }
}));

const AboutUs = ({ id }) => {
  const isMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.between('sm', 'lg')
  );
  const classes = useStyles();

  return (
    <div className={classes.root} id={id}>
      <Grid container justify="center">
        <Grid item xs={12} md={6} lg={4}>
          <div className={classes.textBox}>
            <Grid container justify="flex-end" className={classes.title}>
              <Grid item>
                <Typography variant={isMediumScreen ? 'h2' : 'h3'}>
                  Who We Are
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.paragraph}>
                  We offer an end-to-end client experience that includes seamless
                  communication, organization, and quality work every time.
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.imageBox}>
          <img src={WhoWeAre} alt="logo" className={classes.whoWeAreImg} />
        </Grid>
      </Grid>
      <Grid container justify="center" alignItems="flex-end">
        <Grid item xs={12} md={6} className={classes.imageBox}>
          <img className={classes.WhatWeDoImg} src={WhatWeDo} alt="logo" />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <div className={classes.textBox}>
            <Grid container justify="flex-start" className={classes.title}>
              <Grid item>
                <Typography variant={isMediumScreen ? 'h2' : 'h3'}>
                  What We Do
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.paragraphBottom}>
                  We specialize in Service - Repairs - Maintenance
                  <br/>
                  <br/>
                  Regular business hours Monday-Friday 9am-5pm
                  <br/>
                  <br/>
                  Call out fee $150 + gst
                  <br/>
                  $35 + gst per 15 minutes
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default AboutUs;
