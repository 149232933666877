import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import axios from 'axios';
import {
  Grid,
  makeStyles,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
// import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom'
import ReCaptchaV2 from 'react-google-recaptcha'

// Local imports
import { APP_URLS } from 'urls';
import Button from 'components/common/Button';
import Alert from 'components/common/Alert';
import BookingUserDetails from './BookingUserDetails';
import BookingAddress from './BookingAddress';
import AdditionalBookingDetails from './AdditionalBookingDetails';
import BookingInfoModal from './utils/BookingInfoModal';
import InitialValues from './utils/InitialValues';
import ValidationSchema from './utils/ValidationSchema';
import ReviewBookingDetails from './ReviewBookingDetails';
import TermsAndConditions from "./TermsAndConditions";
// import AuthService from '../../services/auth.service';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(950 + theme.spacing(2) * 2)]: {
      width: 950,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    }
  },
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  orientation: {
    position: 'horizontal',
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      position: 'vertical'
    }
  },
  link: {
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: theme.palette.primary.light,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}));

const steps = [
  'User Info',
  'Address Details',
  'Additional Details',
  'Terms & Conditions',
  'Review your Details'
];

const BookingScheduleForm = () => {
  const [userBookingDetails, setUserBookingDetails] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [openAlert, setOpenAlert] = useState(false);
  const [buttonDisplay, setButtonDisplay] = useState(false)
  const [termsAgreeCheck, setTermsAgreeCheck] = useState(false);
  // const history = useHistory();
  // const [user, setUser] = useState();

  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const isLastStep = activeStep === steps.length - 1;
  const isFirstStep = activeStep === 0;
  const currentValidationSchema = ValidationSchema[activeStep];
  const { formId, formField } = BookingInfoModal;

  const uploadImages = (jobId, images) => {
    // eslint-disable-next-line array-callback-return
    images.map((file, index) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('sortOrder', index);
      fetch(`${APP_URLS.userImages}/${jobId}`, {
        method: 'post',
        body: formData
      }).then((res) => res.json());
    });
  };

  const submitForm = (values, actions) => {
    const {
      street,
      postalCode,
      unitNumber,
      city,
      serviceType,
      customerMessage,
      images,
      firstName,
      lastName,
      email,
      password,
      phoneNumber
    } = values;
    const job = {
      job: {
        serviceType,
        customerMessage,
        status: 0,
        images
      },
      user: {
        firstName,
        lastName,
        email,
        password,
        phoneNumber,
        role: 3
      },
      address: {
        street,
        postalCode,
        unitNumber,
        city,
        province: 'BC',
        country: 'Canada'
      }
    };
    // setUser({ ...job.user });
    const url = APP_URLS.job;
    axios
      .post(url, {
        job: {
          ...job.job
        },
        user: {
          ...job.user
        },
        address: {
          ...job.address
        }
      })
      .then((res) => {
        if (images) {
          uploadImages(res.data.id, images);
        }
        actions.setSubmitting(false);
        setActiveStep(activeStep + 1);
      })
      .catch((error) => {
        setOpenAlert(true);
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  const handleSubmit = (values, actions) => {
    setUserBookingDetails(values);
    if (isFirstStep) {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
      // Removed email check - now it is done in the backend to allow the same user to create multiple jobs
      // axios
      // .get(`${APP_URLS.user}/availability?email=${values.email}`)
      // .then((res) => {
      //   if (res.status === 200) {
      //     setActiveStep(activeStep + 1);
      //     actions.setTouched({});
      //     actions.setSubmitting(false);
      //   }
      // })
      // .catch(() => {
      //   actions.setFieldError('email', 'This email is not available');
      //   setActiveStep(0);
      // });
    } else if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleToken = (tokenString) => {
    axios.post(APP_URLS.captcha, {token: tokenString})
    .then(() => {
      setButtonDisplay(true)
    })
    .catch((error) => {
      setOpenAlert(true);
      // eslint-disable-next-line no-console
      console.log(error)
    })
  }

  // const handleLogin = (event) => {
  //   event.preventDefault();
  //   AuthService.login(user.email, user.password)
  //     .then((res) => {
  //       if (res.roles[0] === 'Customer') {
  //         history.push(`/my-bookings`);
  //       }
  //     })
  //     .catch((error) => {
  //       // eslint-disable-next-line no-console
  //       console.log('errors', error);
  //     });
  // };

  const getStepContent = (step, formProps) => {
    switch (step) {
      case 0:
        return (
          <BookingUserDetails formField={formField} formProps={formProps} />
        );
      case 1:
        return <BookingAddress formField={formField} formProps={formProps} />;
      case 2:
        return (
          <AdditionalBookingDetails
            formField={formField}
            formProps={formProps}
            setFieldValue={formProps.setFieldValue}
          />
        );
      case 3: return <TermsAndConditions
      setTermsAgreeCheck={setTermsAgreeCheck}
      termsAgreeCheck={termsAgreeCheck}
      />;
      case 4:
        return <ReviewBookingDetails data={userBookingDetails} />;
      default:
        throw new Error('Unknown step');
    }
  };

  return (
    <div className={classes.layout}>
      <Alert
        type="snackbar"
        snackBar={{
          open: openAlert,
          onClose: () => setOpenAlert(!openAlert)
        }}
        description="Something went wrong"
        severity="error"
      />
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center">
          {/* Schedule An Appointment Today */}
          Request An Appointment
        </Typography>
        <Stepper
          activeStep={activeStep}
          className={classes.stepper}
          orientation={isSmallScreen ? 'vertical' : 'horizontal'}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <>
          {activeStep === steps.length ? (
            <>
              <Grid container justify="center">
                <Typography
                  variant="h5"
                  gutterBottom
                  align="center"
                  display="block">
                  {/* Appointment Booked */}
                  Request Submitted
                </Typography>
              </Grid>
              <Grid container justify="center">
                <Typography variant="subtitle1" align="center">
                  An operations manager will be in touch with you via the
                  provided e-mail.
                </Typography>
              </Grid>
              <Grid container justify="center">
                <Link
                  to="/"
                  color="primary"
                  className={classes.link}>Back to Home</Link>
              </Grid>
              {/* <Grid container justify="center">
                <Button onClick={handleLogin}>View My Appointments</Button>
              </Grid> */}
            </>
          ) : (
            <>
              <Formik
                initialValues={InitialValues}
                validationSchema={currentValidationSchema}
                onSubmit={handleSubmit}>
                {(props) => (
                  <Form id={formId}>
                    {getStepContent(activeStep, props)}
                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button onClick={handleBack} className={classes.button}>
                          Back
                        </Button>
                      )}
                      {isLastStep && !buttonDisplay ?
                        <ReCaptchaV2 sitekey={process.env.REACT_APP_SITE_KEY} onChange={handleToken} />
                      :
                          <Button
                              disabled={activeStep === 3 && !termsAgreeCheck}
                              variant="contained"
                              color="primary"
                              type="submit"
                              className={classes.button}>
                            {isLastStep ? 'Request Appointment' : 'Next'}
                          </Button>
                      }
                    </div>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </>
      </Paper>
    </div>
  );
};

export default BookingScheduleForm;
