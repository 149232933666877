import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

// local imports
import globalUseStyles from 'utils/theme/styleHooks';

const useStyles = makeStyles((theme) => ({
  addFAB: {
    margin: theme.spacing(-2, -2, 0, 0),
    height: 25,
    width: 35
  },
  card: {
    width: '60%',
    padding: theme.spacing(4)
  },
  title: {
    color: '#da291c',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  image: {
    margin: 'auto'
  },
  images: {
    height: '100%',
    width: '150px',
    margin: theme.spacing(2),
    padding: theme.spacing(4)
  },
  info: {
    fontSize: '12pt',
    textAlign: 'center'
  },
  tableRow: {
    align: 'right'
  },
  textField: {
    width: '100%',
    margin: theme.spacing(4, 0, 0, 0)
  },
  text: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  paddingBottom: {
    marginBottom: '150px'
  }
}));

const StaffDetails = ({ jobRequest }) => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();

  const api = process.env.REACT_APP_SITE_API;

  return (
    <Grid item xs>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className={globalClasses.detailsTitle}>Staff Notes</div>
        </Grid>
        <Grid item xs={12} className={`${globalClasses.detailsInfo} ${classes.paddingBottom}`}>
          <div>{jobRequest.staffMessage || '---'}</div>
        </Grid>
        <Grid item xs={12}>
          <div className={globalClasses.detailsTitle}>
            Staff Uploads ({jobRequest.staffImages.length})
          </div>
        </Grid>
        {jobRequest.staffImages && (
          <Grid item xs>
            {jobRequest.staffImages.map((image) => (
              <a
                href={`${api}${image.originalPath}`}
                target="_blank"
                rel="noreferrer">
                <img
                  src={`${api}${image.originalPath}`}
                  alt={`${api}${image.originalPath}`}
                  className={globalClasses.imageHeight}
                />
              </a>
            ))}
          </Grid>
        )}
        {!jobRequest.staffImages && (
          <Grid item xs={12} sm={12} md={4}>
            <div className={classes.title}>No Uploads From Staff</div>
          </Grid>
        )}
      </Grid>
      {/* <Grid container justify="space-between">
        <Grid item>
          { jobRequest.status !== 4 &&
          <Button className={globalClasses.clearButton}>Cancel Booking</Button>
          }
        </Grid>
      </Grid> */}
    </Grid>
  );
};
export default StaffDetails;
