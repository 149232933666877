import React, { useState } from 'react';
import {
    FormControl,
    Grid,
    makeStyles,
    Card,
    FormGroup,
    Typography,
    TextField
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';

// Local Imports
import Button from 'components/common/Button';
import globalUseStyles from 'utils/theme/styleHooks';
import Alert from '../../common/Alert';
import Loading from '../../common/Loading';
import QuickbooksService from "../../../services/quickbooks.service";
import enums from "../../../utils/enums";
import LineItem from "./LineItem";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '50px',
        minWidth: '95vw',
        [theme.breakpoints.down('sm')]: {
            padding: '20px'
        }
    },
    button: {
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    subtitle: {
        fontSize: '14pt',
        marginTop: '30px',
        marginBottom: '20px'
    },
    card: {
        padding: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(6)
        }
    }
}));

const QuickbooksPayment = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const jobId = urlParams.get('jobId');

    const classes = useStyles();
    const globalClasses = globalUseStyles();
    const [loading, setLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [successAlertText, setSuccessAlertText] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const history = useHistory();
    const [payment, setPayment] = useState({
        jobId,
        lineItems: [
            {
                key: Date.now(),
                name: enums.lineItem.SERVICE_CALL.name,
                type: enums.lineItem.SERVICE_CALL.type,
                description: enums.lineItem.SERVICE_CALL.description,
                price: enums.lineItem.SERVICE_CALL.price,
                displayPrice: enums.lineItem.SERVICE_CALL.price
            }
        ],
        amount: null
    });
    const [displayAmount, setDisplayAmount] = useState('')

    const handleChange = (event) => {
        const { target } = event;
        const { value } = target;
        setDisplayAmount(value);
    };

    const handleChangeLineItem = (inputLineItem) => {
        let newAmount = 0
        for (const lineItem of payment.lineItems.filter(li => li.key !== inputLineItem.key)) {
            if (!Number.isNaN(lineItem.displayPrice)) {
                newAmount += parseFloat(lineItem.displayPrice);
            }
        }
        if (!Number.isNaN(inputLineItem.displayPrice)) {
            newAmount += inputLineItem.displayPrice;
        }
        setPayment((prevState) => ({
            ...prevState,
            lineItems: prevState.lineItems.map((element) => {
                if (element.key === inputLineItem.key) return inputLineItem;
                return element;
            }),
            amount: newAmount
        }))
        setDisplayAmount(`$${newAmount.toFixed(2)}`);
    };

    const addLineItem = () => {
        const { name, type, description, price } = enums.lineItem.SERVICE_CALL;
        setPayment((prevState) => ({
            ...prevState,
            lineItems: prevState.lineItems.concat({
                key: Date.now(),
                name,
                type,
                description,
                price,
                displayPrice: price
            })
        }))
    };

    const removeLineItem = (id) => {
        setPayment((prevState) => ({
            ...prevState,
            lineItems: prevState.lineItems.filter((lineItem) => lineItem.key !== id)
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        payment.amount = parseFloat(displayAmount.substring(1))*100;
        for (const lineItem of payment.lineItems) {
            lineItem.price = lineItem.displayPrice
            lineItem.price *=100;
        }
        setLoading(true)
        try {
            if (payment.amount != null) {
                const res = await QuickbooksService.generateInvoice(payment)
                if (res.status === 200) {
                    setOpenSuccessAlert(true);
                    setSuccessAlertText(res.data)
                    setButtonDisabled(true)
                }
            }
            else {
                setOpenAlert(true);
                setAlertText("Please enter the amount owed")
            }
        } catch (e) {
            setOpenAlert(true)
            setAlertText(e.response.data.message)
        }
        setLoading(false)
    };

    const handleBack = (event) => {
        event.preventDefault();
        history.push('/admin')
    }

    const schema = yup.object({
        displayAmount: yup
            .string('Please enter at least one line item')
            .required('A minimum of one line item is required')
    });

    return (
        <Grid container className={classes.root}>
            <Alert
                type="snackbar"
                snackBar={{
                    open: openAlert,
                    onClose: () => setOpenAlert(!openAlert)
                }}
                description={alertText}
                title="Error"
                severity="error"
            />
            <Alert
                type="snackbar"
                snackBar={{
                    open: openSuccessAlert,
                    onClose: () => setOpenSuccessAlert(!openSuccessAlert)
                }}
                description={successAlertText}
                severity="success"
            />
            <Grid item sm={12} md={12} lg={12} xl={12}>
                <Card className={classes.card}>
                    <div className={globalClasses.titleSize}>
                        <Typography variant="h3">Generate Invoice</Typography>
                    </div>
                    <div className={classes.subtitle}>
                        Please fill out any line items needed for this invoice.
                    </div>
                    <Button className={globalClasses.clearButton} onClick={addLineItem}>
                        Add Line
                    </Button>
                    <Formik
                        initialValues={{
                            payment: {
                                amount: null,
                                lineItems: [{
                                    name: "",
                                    type: "",
                                    description: "",
                                    price: null,
                                    displayPrice: null
                                }
                                ]
                            },
                            displayAmount: null
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={schema}>
                        {({ errors, touched }) => (
                            <form>
                                <FormGroup>
                                    <FormControl>
                                        {payment.lineItems.map((lineItem) => (
                                            <div style={{ marginTop: ".5rem" }}>
                                                <Grid>
                                                    <LineItem
                                                        value={lineItem}
                                                        onChange={(inputLineItem => handleChangeLineItem(inputLineItem))}
                                                    />
                                                    <Button
                                                        className={globalClasses.clearButton}
                                                        onClick={() => removeLineItem(lineItem.key)}
                                                        disabled={payment.lineItems.length <= 1}
                                                    >
                                                        Remove
                                                    </Button>
                                                </Grid>
                                            </div>
                                        ))}
                                        <span style={{ marginTop: "4rem", marginLeft: "55rem" }}>Payment Amount Owed</span>
                                        <TextField
                                            style={{ marginLeft: "55rem", width: "15rem" }}
                                            name="amount"
                                            onChange={handleChange}
                                            value={displayAmount}
                                            error={touched.displayAmount && Boolean(errors.displayAmount)}
                                            helperText={touched.displayAmount && errors.displayAmount}
                                            disabled
                                        />
                                    </FormControl>
                                </FormGroup>
                                <Grid container justify="space-between" className={classes.button}>
                                    <Grid item>
                                        {!loading && (
                                            <Button type="back" onClick={handleBack}>
                                                Back to Dashboard
                                            </Button>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        {!loading && (
                                            <Button type="submit" onClick={handleSubmit} disabled={buttonDisabled}>
                                                Generate Invoice
                                            </Button>
                                        )}
                                        {loading && <Loading />}
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Card>
            </Grid>
        </Grid>
    );
};
export default QuickbooksPayment;
