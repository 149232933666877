import React from 'react';
import { number, oneOf, string } from 'prop-types';
import { CircularProgress } from '@material-ui/core';

const Loading = ({ color, size, thickness, value, variant, ...otherProps }) => (
  <CircularProgress
    color={color}
    size={size}
    value={value}
    variant={variant}
    thickness={thickness}
    {...otherProps}
  />
);
Loading.defaultProps = {
  color: 'primary',
  size: '40px',
  thickness: 3.6,
  value: 0,
  variant: 'indeterminate'
};
Loading.propTypes = {
  color: string,
  size: oneOf([number, string]),
  variant: string,
  thickness: number,
  value: number
};
export default Loading;
