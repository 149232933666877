import React, { useEffect, useState } from 'react';
import { ButtonGroup, Grid, makeStyles } from '@material-ui/core';

// Local Imports
import UserService from 'services/user.service';
import Alert from 'components/common/Alert';
import Loading from 'components/common/Loading';
import DataTable from '../../common/DataTable';
import Button from '../../common/Button';
import globalUseStyles from '../../../utils/theme/styleHooks';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.tertiary.main,
    padding: theme.spacing(8)
  }
}));

const headers = [
  'ID',
  'Name',
  'Email',
  'Phone Number',
  'Address(es)',
  'Actions'
];

const CustomerList = () => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const [customers, setCustomers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [reload, setReload] = useState(false);

  const loadCustomers = () => {
    if (!showArchived) {
      UserService.loadCustomers()
        .then(({ data }) => {
          const finalRes = data.filter((d) => d.method !== 'POST'); // to remove unrelated data
          setCustomers([...finalRes]);
          setLoading(false);
        })
        .catch((error) => {
          setOpenAlert(true);
          setLoading(false);
          // eslint-disable-next-line no-console
          console.log(error);
        });
    } else {
      UserService.loadArchivedCustomers()
        .then(({ data }) => {
          const finalRes = data.filter((d) => d.method !== 'POST'); // to remove unrelated data
          setCustomers([...finalRes]);
          setLoading(false);
        })
        .catch((error) => {
          setOpenAlert(true);
          setLoading(false);
          // eslint-disable-next-line no-console
          console.log(error);
        });
    }
  };

  useEffect(() => {
    loadCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showArchived, reload]);

  const toggleArchived = () => {
    setLoading(true);
    setShowArchived(!showArchived);
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      {loading && (
        <Grid container justify="center">
          <Loading />
        </Grid>
      )}
      <Alert
        type="snackbar"
        snackBar={{
          open: openAlert,
          onClose: () => setOpenAlert(!openAlert)
        }}
        description="Something went wrong"
        title="Error"
        severity="error"
      />
      {!loading && (
        <Grid container>
          <Grid item xs={12}>
            <ButtonGroup className={classes.subGroup}>
              <Button
                className={
                  !showArchived
                    ? `${globalClasses.selectButton} ${classes.button}`
                    : `${globalClasses.clearButton} ${classes.button}`
                }
                onClick={() => toggleArchived()}>
                Active
              </Button>
              <Button
                className={
                  showArchived
                    ? `${globalClasses.selectButton} ${classes.button}`
                    : `${globalClasses.clearButton} ${classes.button}`
                }
                onClick={() => toggleArchived()}>
                Archived
              </Button>
            </ButtonGroup>
          </Grid>
          {showArchived ? (
            <DataTable
              dataList={customers}
              headers={headers}
              listItem="archivedCustomer"
              reload={reload}
              setReload={setReload}
            />
          ) : (
            <DataTable
              dataList={customers}
              headers={headers}
              listItem="customer"
              reload={reload}
              setReload={setReload}
            />
          )}
        </Grid>
      )}
    </Grid>
  );
};
export default CustomerList;
