import BookingInfoModal from './BookingInfoModal';

const {
  formField: {
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
    confirmPassword,
    address,
    serviceType,
    customerMessage,
    images
  }
} = BookingInfoModal;

export default {
  [firstName.name]: '',
  [lastName.name]: '',
  [email.name]: '',
  [phoneNumber.name]: '',
  [password.name]: 'LonsdaleUser11*',
  [confirmPassword.name]: 'LonsdaleUser11*',
  [address.city.name]: '',
  [address.street.name]: '',
  [address.unitNumber.name]: '',
  [address.postalCode.name]: '',
  [serviceType.name]: '',
  [customerMessage.name]: '',
  [images.name]: []
};
