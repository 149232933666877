import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Today } from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Local Imports
import Button from 'components/common/Button';
import Footer from 'components/common/Footer';
import MainImage from 'assets/banner.jpeg';
import AboutUs from './AboutUs';
import Services from './Services';

const useStyles = makeStyles((theme) => ({
  bookButton: {
    marginRight: theme.spacing(4),
    color: theme.palette.tertiary.text,
    border: '1px solid white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      borderWidth: '1px'
    },
    [theme.breakpoints.down('xs')]: {
      margin: '5px'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      margin: '0px',
      width: '271px',
      height: '58px',
      fontSize: '17px'
    }
  },
  heroContent: {
    backgroundImage: `url(${MainImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '600px',
    textAlign: 'left'
  },
  heroButtons: {
    marginTop: theme.spacing(4)
  },
  overlay: {
    background:
      'linear-gradient(to right,  rgba(0, 0, 0, 0.75) 75%, rgba(0, 0, 0, 0) 100%)',
    width: 'inherit',
    color: theme.palette.tertiary.text,
    minHeight: '600px',
    padding: theme.spacing(16, 12, 12, 12),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(12, 8, 8, 8)
    }
  },
  opacity: {
    opacity: '1'
  },
  heroParagraph: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
      fontSize: '13pt'
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '18pt'
    }
  }
}));

const LandingPage = () => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <div>
      <div>
        <Grid className={classes.heroContent}>
          <div>
            <Grid container>
              <Grid item xs={12} sm={12} className={classes.overlay}>
                <Typography
                  component="h1"
                  variant={isSmallScreen ? 'h4' : 'h2'}
                  align="left"
                  gutterBottom>
                  YOUR LOCAL SPECIALIST
                </Typography>
                {isSmallScreen && (
                  <div className={classes.heroButtons}>
                    <Button
                      to="/signup-booking"
                      component={RouterLink}
                      className={classes.bookButton}
                      icon={<Today />}>
                      Request Service
                    </Button>
                  </div>
                )}
                <Typography
                  variant="H6"
                  align="left"
                  paragraph
                  className={classes.heroParagraph}>
                  By using Lonsdale Booking System you can find the best available
                  time, describe your current needs, and even upload some pictures
                  directly to us to help us be even more efficient on our end. <br />
                  We will communicate directly through our system that we primarily
                  designed for you. <br />
                </Typography>
                {!isSmallScreen && (
                  <div className={classes.heroButtons}>
                    <Button
                      to="/signup-booking"
                      component={RouterLink}
                      className={classes.bookButton}
                      icon={<Today />}>
                      Request Service
                      {/* Call 604 770 1416 */}
                    </Button>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={6} className={classes.opacity} />
            </Grid>
          </div>
        </Grid>
      </div>
      {/* About us */}
      <AboutUs id="aboutUs" />
      {/* Services */}
      <Services id="services" />
      {/* Footer */}
      <Footer />
    </div>
  );
};
export default LandingPage;
