import React from 'react';
import { Redirect, Route } from 'react-router-dom';

// Local imports
import AdminLayout from '../layout/AdminLayout';
import AuthService from '../../services/auth.service';

const ProtectedAdminRoute = (children) => (
  <>
    {AuthService.authenticate() ? (
      <AdminLayout>
        <Route {...children} />
      </AdminLayout>
    ) : (
      <Redirect to="/login" />
    )}
  </>
);
export default ProtectedAdminRoute;
