import React from 'react';
import moment from 'moment';
import {
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  TextField
} from '@material-ui/core';

// Local Imports
// import Button from 'components/common/Button';
import globalUseStyles from 'utils/theme/styleHooks';
import Calendar from './Calendar';
import enums from "../../utils/enums";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 'auto'
  },
  calendar: {
    height: '350px',
    padding: theme.spacing(3),
    width: '80%',
    margin: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
      padding: theme.spacing(0),
      marginLeft: theme.spacing(-2)
    }
  },
  list: {
    height: '350px',
    width: '80%',
    margin: 'auto',
    padding: theme.spacing(3),
    marginTop: theme.spacing(16),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(8),
      marginLeft: theme.spacing(2)
    }
  },
  formControl: {
    margin: theme.spacing(4, 1, 1, 1),
    minWidth: '300px'
  },
  serviceTitle: {
    margin: theme.spacing(4, 1, 0, 1)
  },
  confirm: {
    width: '100%',
    margin: 'auto',
    marginTop: theme.spacing(3)
  },
  title: {
    textAlign: 'center',
    margin: theme.spacing(0, 0, 4, 0)
  },
  timeSlot: {
    minWidth: '300px'
  }
}));


const Schedule = ({
  formField: { timeSlot, serviceType, dueDate },
  formProps
}) => {
  const classes = useStyles();
  const globalClasses = globalUseStyles();

  const disableWeekends = (date) => date.getDay() === 0 || date.getDay() === 6;

  return (
    <Grid className={classes.root}>
      <Grid container spacing={3} justify="center">
        <Grid item xs={12}>
          <div>
            <Calendar
              name="dueDate"
              dueDate={dueDate}
              shouldDisableDate={disableWeekends}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={`${classes.title} ${globalClasses.listTitleSize}`}>
            {/* Available Times on{' '} */}
            {moment(formProps.values.dueDate, 'ddd MMMM DD, YYYY')
              .toString()
              .substring(0, 15)}
            :
          </div>
          <Grid container justify="space-evenly">
            <Grid item>
              <TextField
                select
                label="Select a time slot: "
                className={classes.timeSlot}
                name={timeSlot.name}
                value={formProps.values.timeSlot}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                error={
                  formProps.touched.timeSlot &&
                  Boolean(formProps.errors.timeSlot)
                }
                helperText={
                  formProps.touched.timeSlot && formProps.errors.timeSlot
                }>
                {enums.timeSlots.map((slot) => (
                  <MenuItem key={slot.id} value={slot.id}>
                    {slot.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container justify="center">
            <FormControl className={classes.formControl}>
              <TextField
                select
                label=" Please select a type of service:"
                name={serviceType.name}
                value={formProps.values.serviceType}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                error={
                  formProps.touched.serviceType &&
                  Boolean(formProps.errors.serviceType)
                }
                helperText={
                  formProps.touched.serviceType && formProps.errors.serviceType
                }>
                <MenuItem value={0}>
                  Plumbing, Gas Fitting, Hydronic Heating
                </MenuItem>
                <MenuItem value={1}>Service, Repairs & Maintenance</MenuItem>
                <MenuItem value={2}>Appliances Services</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Schedule;
