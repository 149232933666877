import React, { useState } from 'react';
import {
    FormControl,
    Grid,
    makeStyles,
    Card,
    FormGroup,
    Typography,
    TextField
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';

// Local Imports
import Button from 'components/common/Button';
import globalUseStyles from 'utils/theme/styleHooks';
import Alert from '../common/Alert';
import Loading from '../common/Loading';
import UserService from "../../services/user.service";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '50px',
        [theme.breakpoints.down('sm')]: {
            padding: '20px'
        }
    },
    button: {
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    subtitle: {
        fontSize: '14pt',
        marginTop: '30px',
        marginBottom: '20px'
    },
    card: {
        padding: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(6)
        }
    }
}));

const ForgotPassword = () => {
    const classes = useStyles();
    const globalClasses = globalUseStyles();
    const [loading, setLoading] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [successAlertText, setSuccessAlertText] = useState('');
    const history = useHistory();
    const [credentials, setCredentials] = useState({
        email: null
    });

    const handleChange = (event) => {
        const { target } = event;
        const { value } = target;
        const { name } = target;
        setCredentials((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true)
        try {
            if (credentials.email != null) {
                const res = await UserService.sendForgotPassword(credentials.email)
                if (res.status === 200) {
                    setOpenSuccessAlert(true);
                    setSuccessAlertText(res.data)
                }
            }
            else {
                setOpenAlert(true);
                setAlertText("Please enter your email")
            }
        } catch (e) {
            setOpenAlert(true)
            setAlertText(e.response.data.message)
        }
        setLoading(false)
    };

    const handleBack = (event) => {
        event.preventDefault();
        history.push('/login')
    }

    const schema = yup.object({
        email: yup
            .string('Enter your email')
            .email('Email must be correct')
            .required('Email can not be empty')
    });

    return (
        <Grid container className={classes.root}>
            <Alert
                type="snackbar"
                snackBar={{
                    open: openAlert,
                    onClose: () => setOpenAlert(!openAlert)
                }}
                description={alertText}
                title="Error"
                severity="error"
            />
            <Alert
                type="snackbar"
                snackBar={{
                    open: openSuccessAlert,
                    onClose: () => setOpenSuccessAlert(!openSuccessAlert)
                }}
                description={successAlertText}
                severity="success"
            />
            <Grid item sm={12} md={8} lg={6} xl={4}>
                <Card className={classes.card}>
                    <div className={globalClasses.titleSize}>
                        <Typography variant="h3">Reset your password</Typography>
                    </div>
                    <div className={classes.subtitle}>
                        Please enter the email associated with your account below. A password reset link will be sent.
                    </div>
                    <Formik
                        initialValues={{
                            email: ''
                        }}
                        onSubmit={handleSubmit}
                        validationSchema={schema}>
                        {({ errors, touched }) => (
                            <form>
                                <FormGroup>
                                    <FormControl>
                                        <TextField
                                            label="Email"
                                            name="email"
                                            onChange={handleChange}
                                            value={credentials.email}
                                            error={touched.email && Boolean(errors.email)}
                                            helperText={touched.email && errors.email}
                                            autoFocus
                                        />
                                    </FormControl>
                                </FormGroup>
                                <Grid container justify="space-between" className={classes.button}>
                                    <Grid item>
                                        {!loading && (
                                            <Button type="back" onClick={handleBack}>
                                                Back to Login
                                            </Button>
                                        )}
                                        {loading && <Loading />}
                                    </Grid>
                                    <Grid item>
                                        {!loading && (
                                            <Button type="submit" onClick={handleSubmit}>
                                                Send Password Reset Email
                                            </Button>
                                        )}
                                        {loading && <Loading />}
                                    </Grid>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Card>
            </Grid>
        </Grid>
    );
};
export default ForgotPassword;
