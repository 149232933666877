import React, { useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import Button from '../../../common/Button';

// local imports
import Labels from '../../../common/Labels';
import JobsDetails from '../../modals/JobsDetails';
import JobsUpdate from '../../modals/UpdateJobModal';
import globalUseStyles from '../../../../utils/theme/styleHooks';

const UpcomingJobsListItem = ({ job, reload, setReload }) => {
  const globalClasses = globalUseStyles();
  const [openDetails, setOpenDetails] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const { serviceLabels } = Labels;
  const [reqData, setReqData] = useState({});

  const handleDetailModal = (req) => {
    setReqData(req);
    setOpenDetails(!openDetails);
  };

  const handleUpdateModal = (req) => {
    setReqData(req);
    setOpenUpdate(!openUpdate);
  };

  return (
    <>
      <TableRow key={job.id}>
        <TableCell component="th" scope="row">
          {job.jobId}
        </TableCell>
        <TableCell align="right">
          {serviceLabels[job.serviceType].value}
        </TableCell>
        <TableCell align="right">
          {job.street}, {job.city}
        </TableCell>
        <TableCell align="right">{job.customerMessage}</TableCell>
        <TableCell align="right">
          <Button onClick={() => handleDetailModal(job)}>Details</Button>
          <Button
            className={globalClasses.selectButton}
            onClick={() => handleUpdateModal(job)}>
            Update
          </Button>
        </TableCell>
      </TableRow>
      {openDetails && (
        <JobsDetails
          open={openDetails}
          setOpen={setOpenDetails}
          job={reqData}
          setJob={setReqData}
          reload={reload}
          setReload={setReload}
        />
      )}
      {openUpdate && (
        <JobsUpdate
          open={openUpdate}
          setOpen={setOpenUpdate}
          jobRequest={reqData}
          reload={reload}
          setReload={setReload}
        />
      )}
    </>
  );
};
export default UpcomingJobsListItem;
