/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

// Local Imports
import UserLayout from '../layout/UserLayout';
import AuthService from '../../services/auth.service';

const ProtectedUserRoute = (children) => (
  <>
    {AuthService.authenticate() ? (
      <UserLayout>
        <Route {...children} />
      </UserLayout>
    ) : (
      <Redirect to="/login" />
    )}
  </>
);
export default ProtectedUserRoute;
