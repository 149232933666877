import React, { useState } from 'react';
import { Card, Grid, TableCell, TableRow } from '@material-ui/core';

// local imports
import Button from '../../common/Button';
import globalUseStyles from '../../../utils/theme/styleHooks';
import RestoreCustomerModal from '../modals/RestoreCustomerModal';

const ArchivedCustomerListItem = ({ request, reload, setReload }) => {
  const globalClasses = globalUseStyles();
  const [open, setOpen] = useState(false);

  return (
    <TableRow key={request.userId}>
      <TableCell component="th" scope="row">
        #{request.userId}
      </TableCell>
      <TableCell align="right">
        {request.firstName} {request.lastName}
      </TableCell>
      <TableCell align="right">{request.email}</TableCell>
      <TableCell align="right">{request.phoneNumber}</TableCell>
      <TableCell align="right">
        {request.addresses && (
          <Grid container justify="flex-end">
            {request.addresses.map((address) => (
              <Card className={globalClasses.customerAddress}>
                {address.street}, {address.city}
              </Card>
            ))}
          </Grid>
        )}
      </TableCell>
      <TableCell align="right">
        <Button onClick={() => setOpen(!open)}>Restore</Button>
      </TableCell>
      {open && (
        <RestoreCustomerModal
          openModal={open}
          setOpenModal={setOpen}
          user={request}
          reload={reload}
          setReload={setReload}
        />
      )}
    </TableRow>
  );
};
export default ArchivedCustomerListItem;
