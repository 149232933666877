import { makeStyles } from '@material-ui/core';

const globalUseStyles = makeStyles((theme) => ({
  // layout
  header: {
    backgroundColor: theme.palette.primary.main,
    position: 'relative'
  },
  modalHeader: {
    fontSize: theme.typography.pxToRem(18),
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3)
  },
  logo: {
    height: 75
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  menuItem: {
    marginRight: theme.spacing(1)
  },
  // Buttons
  selectButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.tertiary.text,
    borderColor: theme.palette.tertiary.text,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.tertiary.text,
      borderColor: theme.palette.tertiary.text,
      borderWidth: '1px'
    },
    '&:disabled' :  {
      backgroundColor: theme.palette.tertiary.mid
    }
  },
  clearButton: {
    backgroundColor: theme.palette.tertiary.text,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#fc4b3c',
      color: '#fff',
      borderColor: '#ff8469',
      borderWidth: '1px'
    }
  },
  cancelButton: {
    fontSize: '15pt',
    backgroundColor: theme.palette.tertiary.text,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: '#fc4b3c',
      color: '#fff',
      borderColor: '#ff8469',
      borderWidth: '1px'
    }
  },
  listButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.tertiary.text
  },
  bookingButton: {
    width: '400px',
    margin: 'auto',
    textAlign: 'center'
  },
  imageAdd: {
    height: '10px',
    width: '10px',
    margin: theme.spacing(0.5, 0, 0, 0),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.tertiary.text,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.text,
      borderColor: theme.palette.tertiary.text,
      borderWidth: '1px'
    }
  },
  imageDelete: {
    height: '10px',
    width: '10px',
    margin: theme.spacing(-28, 0, 0, 9),
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    borderColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  // admin dashboard                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  `
  searchBar: {
    margin: theme.spacing(4),
    marginLeft: '-80px',
    width: '100%',
    backgroundColor: theme.palette.tertiary.text,
    borderRadius: '5px'
  },
  bookingNumber: {
    textAlign: 'right',
    marginTop: '-10px',
    color: theme.palette.primary.main
  },
  list: {
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8)
  },
  card: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    minHeight: '60px',
    '&:hover': {
      backgroundColor: theme.palette.tertiary.dark,
      borderColor: theme.palette.secondary.light,
      borderWidth: '1px'
    }
  },
  jobCardStatus: {
    padding: theme.spacing(1),
    width: 'auto',
    textAlign: 'center'
  },
  // admin dashboard
  adminRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.tertiary.mid
  },
  customerAddress: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.tertiary.text,
    padding: '5px',
    borderRadius: '25px',
    width: '35%',
    minWidth: '150px',
    textAlign: 'center',
    fontSize: '8pt'
  },
  // user dashboard
  listCard: {
    width: '90%',
    backgroundColor: theme.palette.secondary.main,
    margin: theme.spacing(3, 0, 0, 0)
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2)
  },
  fab: {
    margin: theme.spacing(1, 0, 0, 0),
    height: 25,
    width: 35
  },
  dashboardDropdown: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  buttonGroup: {
    margin: theme.spacing(0, 0, 0, -1)
  },
  // details modal
  detailsRoot: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(16, 0, 0, 0)
    }
  },
  detailsCard: {
    width: '60%',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    }
  },
  detailsStatus: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    maxWidth: '100px',
    textAlign: 'center'
  },
  detailsTitle: {
    color: theme.palette.primary.main,
    fontSize: '14pt'
  },
  detailsInfo: {
    fontSize: '15pt'
  },
  detailsImages: {
    height: '100%',
    margin: theme.spacing(2),
    padding: theme.spacing(4)
  },
  // add/edit modal
  modal: {
    backgroundColor: theme.palette.tertiary.text,
    padding: theme.spacing(2),
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    maxWidth: '800px',
    maxHeight: '830px'
  },
  modalRoot: {
    flexGrow: 1,
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxHeight: '100%',
    maxWidth: '100%'
  },
  modalSmall: {
    backgroundColor: theme.palette.tertiary.text,
    padding: theme.spacing(5),
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    minWidth: '500px',
    maxHeight: '800px'
  },
  modalMax: {
    backgroundColor: theme.palette.tertiary.text,
    padding: theme.spacing(5),
    paddingTop: theme.spacing(2),
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    },
    width: '1000px',
    height: '800px'
  },
  // utils
  titleColor: {
    color: theme.palette.primary.main
  },
  titleSize: {
    fontSize: '24pt'
  },
  listTitleSize: {
    fontSize: '18pt'
  },
  modalButtonSize: {
    fontSize: '15pt'
  },
  imageAlign: {
    align: 'center'
  },
  imageHeight: {
    width: '30%',
    marginRight: '10px'
  },
  textField: {
    width: '100%',
    margin: theme.spacing(0,5,3,5)
  },
  jobsTitle: {
    margin: theme.spacing(0.75, 2, 0.5, 2)
  },
  spacingTop: {
    margin: theme.spacing(2, 0, 0, 0),
    textAlign: 'center'
  },
  addressDropdown: {
    marginBottom: '25px'
  }
}));

export default globalUseStyles;
