export default {
  formId: 'bookingForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'First Name'
    },
    lastName: {
      name: 'lastName',
      label: 'Last Name'
    },
    email: {
      name: 'email',
      label: 'Email'
    },
    password: {
      name: 'password',
      label: 'Password'
    },
    confirmPassword: {
      name: 'confirmPassword',
      label: 'Confirm Password'
    },
    phoneNumber: {
      name: 'phoneNumber',
      label: 'Phone'
    },
    address: {
      street: { name: 'street', label: 'Address' },
      city: {
        name: 'city',
        label: 'City'
      },
      unitNumber: {
        name: 'unitNumber',
        label: 'Unit (if applicable)'
      },
      postalCode: {
        name: 'postalCode',
        label: 'Postal Code'
      }
    },
    serviceType: {
      name: 'serviceType',
      label: 'Please select a type of service:'
    },
    customerMessage: {
      name: 'customerMessage'
    },
    images: {
      name: 'images',
      label: 'Photos'
    }
  }
};
