import React, { Fragment } from 'react';
import {
  Grid,
  DialogTitle,
  Dialog,
  DialogContent,
  Typography,
  DialogActions
} from '@material-ui/core';

// Local Imports
import Button from 'components/common/Button';

const JobsDetails = ({ data, open, setOpen, id }) => {
  const jobRequest = data || {};
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={`${id}-dialog-title`}
      open={open}>
      <DialogTitle id={`${id}-dialog-title`}>Job Details</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Fragment key="1">
            <Grid item xs={6}>
              <Typography gutterBottom>Address:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="button">
                {jobRequest.street}, {jobRequest.city}
              </Typography>
            </Grid>
          </Fragment>
          <Fragment key="3">
            <Grid item xs={6}>
              <Typography gutterBottom>My Notes:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="button">
                {jobRequest.customerMessage}
              </Typography>
            </Grid>
          </Fragment>
          <Fragment key="4">
            <Grid item xs={12}>
              <Typography gutterBottom>My Uploads:</Typography>
            </Grid>
            {jobRequest.images ? (
              <Grid container>
                {jobRequest.images.map((image) => (
                  <Grid item xs={12} md={3}>
                    {image.source}
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item xs={12}>
                ---
              </Grid>
            )}
          </Fragment>
          <Fragment key="5">
            <Grid item xs={6}>
              <Typography gutterBottom>Notes From Service Provider:</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="button">
                {jobRequest.adminMessage || '---'}
              </Typography>
            </Grid>
          </Fragment>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default JobsDetails;
