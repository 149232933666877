import React, { useEffect, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';

// Local Imports
import UserAccount from './Account';
import Jobs from './jobs';
import UserService from '../../services/user.service';
import AuthService from '../../services/auth.service';
import Loading from '../common/Loading';
import Alert from '../common/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.tertiary.main,
    height: '100%'
  },
  dropdownColumns: {
    marginTop: theme.spacing(4)
  }
}));

const UserDashboard = () => {
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);
  const [reload, setReload] = useState(false);

  const loadData = () => {
    UserService.loadUserAccount(AuthService.getCurrentId())
      .then((res) => {
        setUser({
          ...res.data
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setOpenAlert(true);
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  useEffect(() => {
    loadData();
  }, [reload]);

  return (
    <div className={classes.root}>
      {loading && (
        <Grid container justify="center">
          <Loading />
        </Grid>
      )}
      <Alert
        type="snackbar"
        snackBar={{
          open: openAlert,
          onClose: () => setOpenAlert(!openAlert)
        }}
        description="No results/jobs found"
        title="Error"
        severity="error"
      />
      {!loading && (
        <Grid container spacing={4}>
          <Grid ite xs={12} lg={5}>
            {user && (
              <UserAccount user={user} reload={reload} setReload={setReload} />
            )}
          </Grid>
          <Grid item xs={12} lg={7} className={classes.dropdownColumns}>
            {user && <Jobs user={user} reload={reload} setReload={setReload} />}
          </Grid>
        </Grid>
      )}
    </div>
  );
};
export default UserDashboard;
