import React, { useState } from 'react';
import { Button, Modal, Grid, Tab, Tabs, Box } from '@material-ui/core';
import { TabContext } from '@material-ui/lab';
import {Close} from "@material-ui/icons";

// local imports
import globalUseStyles from 'utils/theme/styleHooks';
import TabPanel from '../../../common/TabPanel';
// eslint-disable-next-line import/no-cycle
import UserModal from './UserModal';
import StaffDetails from './StaffModal';


const Details = ({
  jobRequest,
  statusUpdate,
  open,
  setOpen,
  reload,
  setReload
}) => {
  const globalClasses = globalUseStyles();
  const [value, setValue] = useState('one');

  return (
    <Grid item xs>
      <Button
        onClick={() => setOpen(!open)}
        className={globalClasses.clearButton}>
        View Job
      </Button>
      <Modal
        className={`scrollableModalContainer ${globalClasses.modalRoot}`}
        open={open}
        onClose={() => setOpen(!open)}>
        <TabContext value={value}>
          <Box className={`scrollableModal ${globalClasses.modalMax}`}>
            <Tabs
              value={value}
              onChange={() => setValue(value)}
              aria-label="jobs"
              variant="fullWidth">
              <Tab className={globalClasses.listTitleSize} value="one" label="Client" onClick={() => setValue('one')} />
              {jobRequest.staffId && (
                <Tab
                  value="two"
                  label="Staff"
                  className={globalClasses.listTitleSize}
                  onClick={() => setValue('two')}
                />
              )}
              <Button onClick={() => setOpen(false)} style={{float: 'right', width: '10px'}}><Close/></Button>
            </Tabs>
            <TabPanel value={value} index="one">
              <UserModal
                jobRequest={jobRequest}
                statusUpdate={statusUpdate}
                reload={reload}
                setReload={setReload}
                setOpen={setOpen}
              />
            </TabPanel>
            {jobRequest.staffId >= 0 && (
              <TabPanel value={value} index="two">
                <StaffDetails
                  jobRequest={jobRequest}
                  statusUpdate={statusUpdate}
                />
              </TabPanel>
            )}
          </Box>
        </TabContext>
      </Modal>
    </Grid>
  );
};
export default Details;
