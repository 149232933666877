import React, { useState } from 'react';
import { Modal, Grid, Card, DialogActions, TextField } from '@material-ui/core';
// import axios from 'axios';

// Local Imports
import globalUseStyles from 'utils/theme/styleHooks';
import Button from 'components/common/Button';
import Alert from 'components/common/Alert';
import AuthService from 'services/auth.service';
import UserService from 'services/user.service';
// import { APP_URLS } from '../../../../urls';

const EditUser = ({ open, setOpen, user, reload, setReload }) => {
  const globalClasses = globalUseStyles();
  const [openAlert, setOpenAlert] = useState(false);
  const [currentUser, setCurrentUser] = useState({ ...user });

  const handleChange = (event) => {
    setCurrentUser({ ...currentUser, [event.target.name]: event.target.value });
  };

  const handleUpdate = () => {
    const updateUser = {
      id: AuthService.getCurrentId(),
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
      phoneNumber: currentUser.phoneNumber
    };
    UserService.updateUser(updateUser)
      .then(() => {
        setOpen(false);
        setReload(!reload);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  return (
    <>
      <Alert
        description="User edited successfully"
        severity="success"
        type="snackbar"
        snackBar={{
          open: openAlert,
          onClose: () => setOpenAlert(!openAlert)
        }}
      />
      <Modal
        className={globalClasses.modalRoot}
        open={open}
        onClose={() => setOpen(false)}>
        <Card className={globalClasses.modal}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                label="First Name"
                name="firstName"
                onChange={handleChange}
                value={currentUser.firstName}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Last Name"
                name="lastName"
                onChange={handleChange}
                value={currentUser.lastName}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone"
                name="phoneNumber"
                onChange={handleChange}
                value={currentUser.phoneNumber}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                name="email"
                onChange={handleChange}
                value={currentUser.email}
                fullWidth
              />
            </Grid>
          </Grid>

          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              color="primary"
              autoFocus
              type="submit"
              onClick={handleUpdate}>
              Done
            </Button>
          </DialogActions>
        </Card>
      </Modal>
    </>
  );
};
export default EditUser;
