import React from 'react';
import {Card, CardContent, Typography, Checkbox, Box, FormControlLabel} from '@material-ui/core';

function TermsAndConditions({setTermsAgreeCheck, termsAgreeCheck}) {
    return (
        <Card>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Labor Rates Per Visit
                </Typography>
                <Typography variant="h7" gutterBottom>
                    Labour Rates Per Technician. <br/>
                    Regular business hours Monday-Friday 9am-5pm. <br/>
                    Call out fee $150 + gst. <br/>
                    $35 + gst per 15 mins. <br/>
                    Payment on completion of work. <br/><br/>
                    Billing will start when we pull up to the property and
                    end when we load our tools back into our service truck and write up our job report for billing.
                </Typography>
                <p> </p>
                <Typography variant="h6" gutterBottom>
                    Terms and Conditions
                </Typography>
                <Typography variant="h7" gutterBottom>
                    All labour and materials is guaranteed to be as specified. All work to be completed in a
                    professional
                    manner according to standard practices. Should Lonsdale Plumbers run into any unforeseen
                    circumstances while work takes place there will be an additional charge to this estimate for extra
                    labour
                    hours and materials. This agreement contains the entire contract between the parties hereto and no
                    other
                    understanding whether verbal or otherwise shall be valid unless recorded in writing and signed by
                    both
                    parties. Please note any additions to this estimate which are not explicitly outlined are extra to
                    the listed
                    price; this includes but not limited to: drywall insulation/concrete/asphalt repairs, valves not
                    holding
                    our plugged when opening our closing, pin hole leaks on existing system when draining down our re
                    pressurizing system, leaks on any existing drainage system if new branches need to be plumbed in our
                    drain cleaning due to movement, corrosion etc. Leaks on any existing fixtures our pressure issues
                    etc. Work
                    outside regular business hours, engineering, permits, additional balancing/purging following
                    installation,
                    additional work or changes required as per city inspection. Lonsdale Plumbers Ltd requires a 50%
                    deposit
                    prior to work commencing. Lonsdale Plumbers Ltd then reserves the right to take an additional 40%
                    as the job progresses and the remaining 10% once the job is completed. Lonsdale Plumbers Ltd is not
                    responsible for any damage or delays due to causes beyond our control. the estimate is valid for
                    date
                    stated on estimate and does not include tax unless otherwise indicated. By signing this estimate you
                    are
                    approving and accepting all terms and conditions mentioned herein.
                </Typography>
                <p> </p>
                <Box>
                    <FormControlLabel
                        control={<Checkbox checked={termsAgreeCheck} onChange={() => {
                            setTermsAgreeCheck(!termsAgreeCheck);
                        }}/>}
                        label={<Typography variant="h10">I Agree to the Terms & Conditions</Typography>}/>
                </Box>
            </CardContent>
        </Card>
    )
}

export default TermsAndConditions