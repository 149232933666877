import React, { useState } from 'react';
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from '@material-ui/core';

// Local Imports
import Button from 'components/common/Button';
import JobsDetails from '../modals/JobsDetailsModal';
import Labels from '../../common/Labels';

const PreviousJobs = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [reqData, setReqData] = useState({});
  const { serviceLabels } = Labels;

  const handleDetailModal = (req) => {
    setReqData(req);
    setOpen(!open);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="previous jobs table">
        <TableHead>
          <TableRow>
            <TableCell>Job #</TableCell>
            <TableCell align="right">Service</TableCell>
            <TableCell align="right">Date Scheduled</TableCell>
            <TableCell align="right">Address</TableCell>
            <TableCell align="right">My Note</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {user.jobs.map(
            (request) =>
              request.status === 4 && (
                <>
                  <TableRow key={request.jobId}>
                    <TableCell component="th" scope="row">
                      {request.jobId}
                    </TableCell>
                    <TableCell align="right">
                      {serviceLabels[request.serviceType].value}
                    </TableCell>
                    <TableCell align="right">
                      {request.street}, {request.city}
                    </TableCell>
                    <TableCell align="right">
                      {request.customerMessage}
                    </TableCell>
                    <TableCell align="right">
                      <Button onClick={() => handleDetailModal(request)}>
                        Details
                      </Button>
                    </TableCell>
                  </TableRow>
                </>
              )
          )}
          {open && <JobsDetails open={open} setOpen={setOpen} data={reqData} />}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default PreviousJobs;
