import axios from 'axios';
import authHeader from './auth-header';
import { APP_URLS } from '../urls';

const loadRequests = () =>
  axios.get(APP_URLS.jobStatus.new, { headers: authHeader() });

const loadReviewing = () =>
  axios.get(APP_URLS.jobStatus.pending, { headers: authHeader() });

const loadAssigned = () =>
  axios.get(APP_URLS.jobStatus.inProgress, { headers: authHeader() });

const loadCompleted = () =>
  axios.get(APP_URLS.jobStatus.completed, { headers: authHeader() });

const getJobById = (jobId) =>
    axios.get(`${APP_URLS.job}/${jobId}`, { headers: authHeader() });

const updateJob = (jobInfo) =>
  axios.put(
    `${APP_URLS.job}/update`,
    { ...jobInfo },
    { headers: authHeader() }
  );

const loadEmails = (jobId) =>
    axios.get(`${APP_URLS.job}/emails/${jobId}`, { headers: authHeader() });

export default {
  loadRequests,
  loadReviewing,
  loadAssigned,
  loadCompleted,
  getJobById,
  updateJob,
  loadEmails
};
