import axios from "axios";
import {APP_URLS} from "../urls";
import authHeader from "./auth-header";

const initiatePaymentAuthorization = async () =>
    axios.get(`${APP_URLS.quickbooks}/authorization`, { headers: authHeader() })

const generateInvoice = (paymentInfo) =>
    axios.put(
        `${APP_URLS.quickbooks}/payment`,
        { ...paymentInfo },
        { headers: authHeader() }
    );

const finishAuth = (authRequest) =>
    axios.post(
        `${APP_URLS.quickbooks}/token`,
        { ...authRequest },
        { headers: authHeader() }
    );

const getInvoice = async (jobId) =>
    axios.get(
        `${APP_URLS.quickbooks}/invoice`,
        {params: { jobId },
        headers: authHeader() }
    );

const updateInvoice = (invoiceToUpdate) =>
    axios.put(
    `${APP_URLS.quickbooks}/update`,
        { ...invoiceToUpdate },
        { headers: authHeader() }
    );

const sendInvoiceEmail = (invoiceNumber) =>
    axios.put(
        `${APP_URLS.quickbooks}/email`,
        { ...invoiceNumber },
        { headers: authHeader() }
    );

const revokeAuth = async () =>
    axios.get(`${APP_URLS.quickbooks}/revoke`, { headers: authHeader() })

const loadCompanyName = async () =>
    axios.get(
        `${APP_URLS.quickbooks}/companyName`,
        { headers: authHeader() }
    );


export default {
    initiatePaymentAuthorization,
    generateInvoice,
    finishAuth,
    getInvoice,
    updateInvoice,
    sendInvoiceEmail,
    revokeAuth,
    loadCompanyName
};