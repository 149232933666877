import React from 'react';
import { Button, Modal, Grid, Box } from '@material-ui/core';
import {Close} from "@material-ui/icons";

// local imports
import globalUseStyles from 'utils/theme/styleHooks';
// eslint-disable-next-line import/no-cycle
import EmailModal from './EmailModal';


const Details = ({
  emailRequest,
  open,
  setOpen,
  reload,
  setReload,
  job,
  user
}) => {
  const globalClasses = globalUseStyles();

  return (
    <Grid item xs>
      <Button
        onClick={() => setOpen(!open)}
        className={globalClasses.clearButton}>
        View Email
      </Button>
      <Modal
        className={`scrollableModalContainer ${globalClasses.modalRoot}`}
        open={open}
        onClose={() => setOpen(!open)}>
          <Box className={`scrollableModal ${globalClasses.modalMax}`}>
            <Button onClick={() => setOpen(false)} style={{float: 'right', width: '10px'}}><Close/></Button>
            <EmailModal
              emailRequest={emailRequest}
              reload={reload}
              setReload={setReload}
              setOpen={setOpen}
              job={job}
              user={user}
            />
          </Box>
      </Modal>
    </Grid>
  );
};
export default Details;
