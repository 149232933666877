/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

// Local imports
import StaffLayout from '../layout/StaffLayout';
import AuthService from '../../services/auth.service';

const ProtectedStaffRoute = (children) => (
  <>
    {AuthService.authenticate() ? (
      <StaffLayout>
        <Route {...children} />
      </StaffLayout>
    ) : (
      <Redirect to="/login" />
    )}
  </>
);
export default ProtectedStaffRoute;
