import React, {useState} from 'react';
import {makeStyles, TableCell, TableRow} from '@material-ui/core';

// local imports
// eslint-disable-next-line import/no-cycle
import Details from '../EmailDetailsModal';

const useStyles = makeStyles(() => ({
    cellSpan: {
        display: 'inline-block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
}));

const EmailListItem = ({request, job, user}) => {
    const classes = useStyles();
    const [openDetails, setOpenDetails] = useState(false);

    return (
        <TableRow key={request.id}>
            <TableCell component="th" scope="row">
                <span className={classes.cellSpan}>
                    {request.from}
                </span>
            </TableCell>
            <TableCell align="right">
                <span className={classes.cellSpan}>
                {request.to}
                </span>
            </TableCell>
            <TableCell align="right">
                <span className={classes.cellSpan}>
                    {request.subject}
                </span>
            </TableCell>
            <TableCell align="right">
                <span className={classes.cellSpan}>
                {request.body}
                </span>
            </TableCell>
            <TableCell align="right">
                <span className={classes.cellSpan}>
                {request.dateReceived}
                </span>
            </TableCell>
            <TableCell align="right">
                <Details
                    emailRequest={request}
                    open={openDetails}
                    setOpen={setOpenDetails}
                    job={job}
                    user={user}
                />
            </TableCell>
        </TableRow>
    );
};
export default EmailListItem;
