import React from 'react';
import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';

// Local Imports
import { HotTub } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  media: {
    fontSize: 190,
    [theme.breakpoints.down('xs')]: {
      fontSize: 150
    }
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(10, 12, 14, 12),
    backgroundColor: theme.palette.tertiary.main
  },
  service: {
    margin: theme.spacing(0, 0, 0, 0)
  },
  title: {
    textAlign: 'center'
  },
  spacing: {
    padding: theme.spacing(0, 10, 0, 10)
  }
}));

const services = [
  {
    id: 1,
    name: 'Plumbing',
    image: <HotTub style={{ fontSize: '72pt' }} />
  },
  {
    id: 2,
    name: 'Gas',
    image: <HotTub style={{ fontSize: '72pt' }} />
  },
  {
    id: 3,
    name: 'Hydronic Heating',
    image: <HotTub style={{ fontSize: '72pt' }} />
  }
];

const Services = ({ id }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isMediumScreen = useMediaQuery((theme) =>
    theme.breakpoints.between('sm', 'lg')
  );
  const classes = useStyles();
  const serviceClass = classes.service;
  const serviceProps = isSmallScreen
    ? {}
    : {
        container: 'container',
        justify: 'space-around',
        className: classes.service
      };
  return (
    <Grid container  justify="center" className={classes.root} id={id}>
      <Grid container justify="center">
        <Typography  variant={isMediumScreen ? 'h2' : 'h3'} align="center">
          Services
        </Typography>
      </Grid>
      <Grid {...serviceProps}>
        {services.map((service) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={service.id}
            className={isSmallScreen && serviceClass}>
            <Grid container justify="center">
              <div className={classes.media}>{service.image}</div>
            </Grid>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className={classes.title}>
              {service.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
export default Services;
